import { FC, useMemo } from "react"
import { useTranslationContext } from "../providers/translationProvider"
import { Typography } from "antd"
import { COLOR_BG_GREEN, COLOR_BG_ORANGE, colorHexToRgba } from "./utils"

export const Translated: FC<{
  keyEn: string | null
}> = ({ keyEn }) => {
  const { translated, isAdminMode, translationExists, addTranslation } = useTranslationContext()

  const memoTranslatedKey = useMemo(() => translated(keyEn), [keyEn, translated])

  if (isAdminMode) {
    return (
      <Typography.Text
        editable={{
          onChange: (value) => {
            // Update translation
            addTranslation(keyEn, value)
          },
        }}
        style={{
          backgroundColor: translationExists(keyEn)
            ? colorHexToRgba(COLOR_BG_GREEN, 0.1)
            : colorHexToRgba(COLOR_BG_ORANGE, 0.1),
          borderRadius: 5,
          color: "inherit",
          fontFamily: "inherit",
          fontSize: "inherit",
          fontWeight: "inherit",
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {memoTranslatedKey}
      </Typography.Text>
    )
  } else {
    return <span>{memoTranslatedKey}</span>
  }
}
