import { Avatar, List, Popover, Switch } from "antd"
import { FC, useMemo } from "react"
import { UserAvatar } from "./userWidget"
import { QuestionCircleOutlined, QuestionOutlined, WhatsAppOutlined } from "@ant-design/icons"
import { Translated } from "../../utils/translated"
import { colorHexToRgba, COLOR_BG_LIGHT_BLUE, COLOR_BG_DARK_BLUE_TEXT } from "../../utils/utils"
import { FlexCol, FlexRow } from "./common"
import { Label } from "./input"
import Link from "antd/es/typography/Link"

export const HelpWidget: FC = () => {
  const memoHelpList = useMemo(() => {
    return (
      <FlexCol>
        <List size="small">
          <List.Item key="help-widget-list-item-user-manual">
            <List.Item.Meta
              avatar={<QuestionCircleOutlined />}
              description={
                <Link href="https://www.lugdosphere.eu/ressources/aide/" target="_blank">
                  <Translated keyEn="Help Center" />
                </Link>
              }
            />
          </List.Item>
          <List.Item key="help-widget-list-item-community">
            <List.Item.Meta
              avatar={<WhatsAppOutlined />}
              description={
                <Link href="https://chat.whatsapp.com/BQimuoIeGV51eTE51UxuM1" target="_blank">
                  <Translated keyEn="Community" />
                </Link>
              }
            />
          </List.Item>
        </List>
      </FlexCol>
    )
  }, [])

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      overlayStyle={{
        minWidth: 200,
      }}
      content={memoHelpList}
    >
      <div
        style={{
          cursor: "pointer",
        }}
      >
        <Avatar
          icon={<QuestionCircleOutlined />}
          style={{
            color: COLOR_BG_DARK_BLUE_TEXT,
            backgroundColor: colorHexToRgba(COLOR_BG_LIGHT_BLUE, 0.2),
          }}
        />
      </div>
    </Popover>
  )
}
