import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { SummarySitesTable } from "./Home/SitesSummaryTable"
import { SiteInitForm } from "./Home/SiteInitForm"
import {
  Button,
  Col,
  Empty,
  FloatButton,
  Modal,
  Radio,
  Row,
  Spin,
  Tabs,
  Tooltip,
  message,
} from "antd"
import { Translated } from "../utils/translated"
import { faLocationDot, faSatellite } from "@fortawesome/free-solid-svg-icons"
import { FlexCol, FlexRow } from "../components/commons-ts/common"
import { NominatimZoomType, Site, StorageEntityType } from "../generated/proto-ts/main"
import { useUsercommContextBLE } from "../usercomm/local/ble/usercommProviderBLE"
import {
  CENTRAL_CONTAINER_GAP,
  COLOR_BG_BLUE,
  COLOR_BG_GRAY,
  COLOR_BG_GREEN,
  COLOR_BG_LIGHT_BLUE,
  COLOR_BG_RED,
  pbUUIDToUuid,
  useMediaQuery,
  uuidToPbUUID,
} from "../utils/utils"
import GaugeChart from "react-gauge-chart"
import { Paper } from "../components/commons-ts/paper"
import { Link, useLocation } from "react-router-dom"
import { InfoCircleTwoTone, PlusOutlined, SwapOutlined } from "@ant-design/icons"
import { SummaryImpactsTable } from "./Home/ImpactsSummaryTable"
import { NominatimReverseGeocodeResponse, callNominatimReverseGeocode } from "../utils/nominatim"
import {
  GoogleMapsMarker,
  GoogleMapsReactSignedIn,
  formatLCoordinateDegMinSec,
} from "../components/commons-ts/googleMapsApi/googleMaps"
import { DataCloudSyncWidget } from "../components/commons-ts/dataCloudSyncWidget"
import {
  useUsercommDeleteSiteBimodal,
  useUsercommSitesBimodal,
  useUsercommSyntheticImpactWithEmbeddedReferencesBimodal,
} from "../usercomm/common/usercommAsyncRequestBimodal"
import { useSyncronizationContext } from "../providers/syncronizationProvider"
import { SyntheticImpactWithEmbeddedReferences } from "../usercomm/common/usercommAsyncRequestGeneric"
import { useCloudUsercommStatsWS } from "../usercomm/cloud/cloudUsercommAsyncRequestWS"
import { useUsercommStatsBLE } from "../usercomm/local/ble/usercommAsyncRequestBLE"
import { ImpactsSummaryMap } from "./Home/ImpactsSummaryMap"
import { UserAvatar } from "../components/commons-ts/userWidget"
import { BruteUserRankingWidget } from "../components/commons-ts/bruteUserRankingWidget"

const LIMIT_GMAX = 250
const LIMIT_HIC = 1100

const StatElement: FC<{
  name: ReactElement
  value: string | number | null
  success?: boolean
  danger?: boolean
}> = ({ name, value, success, danger }) => {
  const memoValueColor = useMemo(() => {
    if (danger) {
      return COLOR_BG_RED
    }
    if (success) {
      return COLOR_BG_GREEN
    }
    return COLOR_BG_BLUE
  }, [success, danger])

  return (
    <FlexCol
      style={{
        justifyContent: "center",
        alignItems: "center",
        gap: 5,
        textAlign: "center",
      }}
    >
      <span
        style={{
          fontSize: "1.1rem",
          fontWeight: "bold",
          letterSpacing: "0.1rem",
          textTransform: "uppercase",
          color: COLOR_BG_GRAY,
        }}
      >
        {name}
      </span>
      <span
        style={{
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: memoValueColor,
        }}
      >
        {value === null ? "N/A" : value}
      </span>
    </FlexCol>
  )
}

const StatGauge: FC<{
  name: ReactElement
  value: number | null
  limit: number
}> = ({ name, value, limit }) => {
  if (value === null) {
    return null
  }
  return (
    <FlexCol
      style={{
        gap: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span
        style={{
          fontSize: "1.1rem",
          fontWeight: "bold",
          letterSpacing: "0.1rem",
          textTransform: "uppercase",
          color: COLOR_BG_GRAY,
        }}
      >
        {name}
      </span>
      <GaugeChart
        id={`gauge-chart-${name}`}
        style={{
          width: 200,
        }}
        colors={[COLOR_BG_GREEN, COLOR_BG_RED]}
        percent={value / limit}
        formatTextValue={() => value.toFixed(0)}
        textColor={COLOR_BG_GRAY}
        nrOfLevels={4}
        arcWidth={0.1}
        arcPadding={0.03}
      />
    </FlexCol>
  )
}

const ReverseGeocodeWidget: FC = () => {
  const { stationSensors } = useUsercommContextBLE()
  const [lon, setLon] = useState<number | null>(null)
  const [lat, setLat] = useState<number | null>(null)
  const [reverseGeocode, setReverseGeocode] = useState<NominatimReverseGeocodeResponse | null>(null)

  useEffect(() => {
    if (stationSensors === null) {
      return
    }
    if (stationSensors.gnss_lon !== undefined && stationSensors.gnss_lon !== 0) {
      setLon(stationSensors.gnss_lon)
    } else {
      setLon(null)
    }
    if (stationSensors.gnss_lat !== undefined && stationSensors.gnss_lat !== 0) {
      setLat(stationSensors.gnss_lat)
    } else {
      setLat(null)
    }
  }, [stationSensors])

  useEffect(() => {
    if (lat === null || lon === null) {
      return
    }

    if (
      reverseGeocode !== null &&
      reverseGeocode.boundingbox !== undefined &&
      reverseGeocode.boundingbox.length === 4
    ) {
      let [bbLatFrom, bbLatTo, bbLonFrom, bbLonTo] = reverseGeocode.boundingbox
      if (
        lat >= parseFloat(bbLatFrom) &&
        lat <= parseFloat(bbLatTo) &&
        lon >= parseFloat(bbLonFrom) &&
        lon <= parseFloat(bbLonTo)
      ) {
        // console.log(
        //     `ReverseGeocodeWidget: lat/lon (${lat}/${lon}) is already in bounding box`,
        //     reverseGeocode,
        // )
        return
      }
    }

    callNominatimReverseGeocode(lat, lon, NominatimZoomType.BUILDING).then((revGeocodeResp) => {
      console.log(`ReverseGeocodeWidget: got reverseGeocode for lat/lon (${lat}/${lon})`)
      setReverseGeocode(revGeocodeResp)
    })
  }, [lat, lon])

  const memoReverseGeocodeAddressLinkElement = useMemo(() => {
    if (lat === null || lon === null) {
      return null
    }
    let addrStr = ""
    if (reverseGeocode !== null) {
      addrStr = reverseGeocode.display_name
    } else {
      let latStrSN = `${formatLCoordinateDegMinSec(lat)} ${lat < 0 ? "S" : "N"}`
      let lonStrEW = `${formatLCoordinateDegMinSec(lon)} ${lon < 0 ? "W" : "E"}`
      addrStr = `${latStrSN}, ${lonStrEW}`
    }
    return (
      <a
        href={`https://maps.google.com/maps?q=${lat},${lon}`}
        target="_blank"
        rel="noreferrer"
        style={{
          fontSize: "1.1rem",
          fontWeight: "bold",
          color: COLOR_BG_BLUE,
        }}
      >
        {addrStr}
      </a>
    )
  }, [lat, lon, reverseGeocode])

  if (lat === null || lon === null) {
    return (
      <Empty
        image={
          <FontAwesomeIcon
            icon={faSatellite}
            style={{
              fontSize: "2rem",
              color: COLOR_BG_LIGHT_BLUE,
            }}
          />
        }
        description={
          <Tooltip overlay="Try to find a clear sky spot!" placement="bottom">
            <Translated keyEn="No GPS signal" />
          </Tooltip>
        }
      />
    )
  }

  return (
    <FlexRow>
      <FontAwesomeIcon
        icon={faLocationDot}
        style={{
          fontSize: "2rem",
          color: COLOR_BG_LIGHT_BLUE,
        }}
      />
      <FlexCol style={{ gap: 10 }}>
        <span
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            letterSpacing: "0.1rem",
            textTransform: "uppercase",
            color: COLOR_BG_GRAY,
          }}
        >
          Live Location
        </span>
        {memoReverseGeocodeAddressLinkElement}
        <div
          style={{
            height: "200px",
            width: "100%",
            minWidth: "200px",
          }}
        >
          <GoogleMapsReactSignedIn
            center={{
              lat,
              lng: lon,
            }}
            defaultZoom={15}
          >
            <GoogleMapsMarker lat={lat} lng={lon} />
          </GoogleMapsReactSignedIn>
        </div>
        <Link to="#map">
          <Button size="large" block type="primary">
            Map
          </Button>
        </Link>
      </FlexCol>
    </FlexRow>
  )
}

const StatsBoard: FC<{
  summaryTableFilterType: ESummaryTableFilterType
}> = ({ summaryTableFilterType }) => {
  // Device info (Cloud)
  const { currentRemoteDevice, currentUser, bruteEnterpriseUsers } = useSyncronizationContext()

  const [remoteStats, getRemoteStats] = useCloudUsercommStatsWS()
  const [localStats, getLocalStats] = useUsercommStatsBLE()
  const { bleIsConnected, stationConfig } = useUsercommContextBLE()

  // Statistics
  const [sitesNb, setSitesNb] = useState<number | null>(null)
  const [impactsNb, setImpactsNb] = useState<number | null>(null)
  const [maxHIC, setMaxHIC] = useState<number | null>(null)
  const [maxHICImpactUUID, setMaxHICImpactUUID] = useState<string | null>(null)
  const [maxGmax, setMaxGmax] = useState<number | null>(null)
  const [maxGmaxImpactUUID, setMaxGmaxImpactUUID] = useState<string | null>(null)
  const [timesAboveGmaxLimit, setTimesAboveGmaxLimit] = useState<number | null>(null)
  const isMobile = useMediaQuery()

  useEffect(() => {
    if (bleIsConnected) {
      getLocalStats(undefined)
    }
  }, [bleIsConnected])

  useEffect(() => {
    if (summaryTableFilterType === ESummaryTableFilterType.User && currentUser !== null) {
      getRemoteStats(uuidToPbUUID(currentUser.UUID))
    } else if (
      summaryTableFilterType === ESummaryTableFilterType.Enterprise &&
      currentUser !== null
    ) {
      getRemoteStats(uuidToPbUUID(currentUser.Enterprise.UUID))
    } else if (summaryTableFilterType === ESummaryTableFilterType.Device) {
      return
    }
  }, [currentUser, summaryTableFilterType])

  const memoEffectiveStats = useMemo(() => {
    console.log(
      `StatsWidget: localStats/remoteStats`,
      localStats?.toObject(),
      remoteStats?.toObject(),
    )
    if (localStats !== null && remoteStats === null) {
      return localStats
    } else if (localStats === null && remoteStats !== null) {
      return remoteStats
    } else {
      if (summaryTableFilterType === ESummaryTableFilterType.Device) {
        return localStats
      } else {
        return remoteStats
      }
    }
  }, [remoteStats, localStats, summaryTableFilterType])

  // Parse Stats
  useEffect(() => {
    if (memoEffectiveStats === null) {
      return
    }
    console.log("StatsWidget: effective stats", memoEffectiveStats.toObject())
    for (let count of memoEffectiveStats.counts) {
      switch (count.entity_type) {
        case StorageEntityType.SITE:
          setSitesNb(count.count)
          break
        case StorageEntityType.IMPACT:
          setImpactsNb(count.count)
          break
      }
    }
    setMaxHIC(memoEffectiveStats.max_hic)
    if (memoEffectiveStats.has_max_hic_impact_uuid) {
      setMaxHICImpactUUID(pbUUIDToUuid(memoEffectiveStats.max_hic_impact_uuid))
    }
    setMaxGmax(memoEffectiveStats.max_gmax)
    if (memoEffectiveStats.has_max_gmax_impact_uuid) {
      setMaxGmaxImpactUUID(pbUUIDToUuid(memoEffectiveStats.max_gmax_impact_uuid))
    }
    setTimesAboveGmaxLimit(memoEffectiveStats.count_gmax_above_limit)
  }, [memoEffectiveStats])

  const memoMetrologyInversePercent = useMemo(() => {
    if (stationConfig === null) {
      return null
    }
    let d_full = stationConfig.next_metrology_date - stationConfig.last_metrology_date
    if (d_full <= 0) {
      return 0
    }
    let d_left = stationConfig.next_metrology_date - Date.now()
    return (100 * d_left) / d_full
  }, [stationConfig])

  const memoMetrologyDaysLeft = useMemo(() => {
    if (stationConfig === null) {
      return null
    }
    let d_left = stationConfig.next_metrology_date - Date.now()
    return Math.ceil(d_left / (1000 * 60 * 60 * 24))
  }, [stationConfig])

  const memoHICStatGauge = useMemo(() => {
    return (
      <FlexCol
        style={{
          gap: 5,
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <StatGauge name={<Translated keyEn="MAX HIC" />} value={maxHIC} limit={LIMIT_HIC} />
        <Link to={`/impacts/${maxHICImpactUUID}`}>
          <Button block type="default">
            <Translated keyEn="Go to Impact" />
          </Button>
        </Link>
      </FlexCol>
    )
  }, [maxHIC, maxHICImpactUUID])

  const memoGmaxStatGauge = useMemo(() => {
    let maxGmaxStr = "N/A"
    let color = COLOR_BG_BLUE
    if (maxGmax !== null) {
      maxGmaxStr = maxGmax.toFixed(0) + "g"
      if (maxGmax > LIMIT_GMAX) {
        color = COLOR_BG_RED
      } else if (maxGmax < 150) {
        color = COLOR_BG_GREEN
      }
    }
    return (
      <FlexCol
        style={{
          gap: 5,
          height: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <StatGauge name={<Translated keyEn="MAX G" />} value={maxGmax} limit={LIMIT_GMAX} />
        <span
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: color,
          }}
        >
          {maxGmaxStr}
        </span>
        <Link to={`/impacts/${maxGmaxImpactUUID}`}>
          <Button block type="default">
            <Translated keyEn="Go to Impact" />
          </Button>
        </Link>
      </FlexCol>
    )
  }, [maxGmax, maxGmaxImpactUUID])

  const memoMetrologyProgress = useMemo(() => {
    if (memoMetrologyInversePercent === null) {
      return null
    }
    return (
      <FlexCol
        style={{
          height: "100%",
          gap: 5,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            letterSpacing: "0.1rem",
            textTransform: "uppercase",
            color: COLOR_BG_GRAY,
          }}
        >
          Metrology
        </span>
        <GaugeChart
          id="gauge-chart-metrology"
          style={{
            width: 200,
          }}
          percent={memoMetrologyInversePercent / 100}
          textColor={COLOR_BG_GRAY}
          colors={[COLOR_BG_LIGHT_BLUE, COLOR_BG_BLUE]}
          formatTextValue={() => `${memoMetrologyDaysLeft}d`}
          nrOfLevels={4}
          arcWidth={0.1}
          arcPadding={0.03}
        />
        {stationConfig !== null && stationConfig.last_metrology_date > 0 && (
          <div>
            <div>
              Last:{" "}
              <strong>{new Date(stationConfig.last_metrology_date).toLocaleDateString()}</strong>
            </div>
            <div>
              Next: in <strong>{memoMetrologyDaysLeft}</strong> days
            </div>
          </div>
        )}
        <Button style={{ marginTop: 10 }} block type="primary">
          Contact SAV
        </Button>
      </FlexCol>
    )
  }, [memoMetrologyInversePercent, memoMetrologyDaysLeft])

  const memoDeviceInfoPane = useMemo(() => {
    let deviceInfoEl = (
      <FlexCol
        style={{
          gap: 0,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SwapOutlined
          style={{
            color: COLOR_BG_GRAY,
            fontSize: "3rem",
          }}
        />
        <span
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            letterSpacing: "0.1rem",
            textTransform: "uppercase",
            color: COLOR_BG_GRAY,
          }}
        >
          <Translated keyEn="N/C" />
        </span>
      </FlexCol>
    )

    if (bleIsConnected && currentRemoteDevice !== null) {
      deviceInfoEl = (
        <FlexCol style={{ gap: 0, alignItems: "end" }}>
          <span>
            Product: <b>{currentRemoteDevice.DeviceType?.Name ?? "N/A"}</b>{" "}
            <Tooltip
              overlay={currentRemoteDevice.DeviceType?.Description}
              overlayInnerStyle={{ textAlign: "center" }}
            >
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
          <span>
            S/N: <b>{currentRemoteDevice.Name}</b>{" "}
            <Tooltip overlay={currentRemoteDevice.SerialNumber}>
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
          <span>
            Enterprise: <b>{currentRemoteDevice.Enterprise?.Name ?? "N/A"}</b>
          </span>
        </FlexCol>
      )
    }

    return (
      <FlexRow
        style={{
          justifyContent: "space-between",
          height: 230,
        }}
      >
        <img
          src="petit-lug-on-largueur.jpg"
          style={{
            // TODO: check: no effect?
            // marginLeft: -10,
            // marginBottom: -10,
            height: "100%",
          }}
        />
        <FlexCol
          style={{
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
              letterSpacing: "0.1rem",
              textTransform: "uppercase",
              color: COLOR_BG_GRAY,
            }}
          >
            <Translated keyEn="Device" />
          </span>
          {deviceInfoEl}
          <Link to="/settings/device#history">
            <Button block type="primary" disabled={currentRemoteDevice === null || !bleIsConnected}>
              <Translated keyEn="Device History" />
            </Button>
          </Link>
        </FlexCol>
      </FlexRow>
    )
  }, [bleIsConnected, currentRemoteDevice])

  const memoUserInfoPane = useMemo(() => {
    if (currentUser === null) {
      return null
    }

    return (
      <FlexCol>
        <FlexRow
          style={{
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <FlexCol style={{ gap: 5 }}>
            <span
              style={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                letterSpacing: "0.1rem",
                textTransform: "uppercase",
                color: COLOR_BG_GRAY,
              }}
            >
              <Translated keyEn="User" />
            </span>
            {/* Full Name */}
            <div>
              <b style={{ color: COLOR_BG_GREEN }}>
                {currentUser.FirstName} {currentUser.LastName}
              </b>
              {" | "}
              <b
                style={{
                  color: COLOR_BG_BLUE,
                  fontSize: "1.1rem",
                }}
              >
                {currentUser.Enterprise.Name}
              </b>
            </div>
            {/* Brute Ranking */}
            <BruteUserRankingWidget user={currentUser} />
            <Link to="/settings/general#account">
              <Button block type="primary">
                <Translated keyEn="Account" />
              </Button>
            </Link>
          </FlexCol>
          <div
            style={{
              overflow: "hidden",
            }}
          >
            <img
              src="petit-lug-football-bw.jpg"
              style={{
                height: "145px",
                objectFit: "cover",
                marginRight: "-28px",
                marginBottom: "-10px",
                marginTop: "-5px",
              }}
            />
          </div>
        </FlexRow>
      </FlexCol>
    )
  }, [currentUser, bruteEnterpriseUsers])

  if (memoEffectiveStats === null) {
    return (
      <FlexCol
        style={{
          gap: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </FlexCol>
    )
  }

  return (
    <>
      <Row align="stretch" justify="end">
        <Col flex="auto">
          <Paper>{memoDeviceInfoPane}</Paper>
        </Col>
        {memoMetrologyProgress !== null && (
          <Col flex="auto">
            <Paper style={{ height: "calc(100% - 8px)" }}>{memoMetrologyProgress}</Paper>
          </Col>
        )}
        <Col flex="auto">
          <Paper style={{ height: "calc(100% - 8px)" }}>{memoGmaxStatGauge}</Paper>
        </Col>
        <Col flex="auto">
          <Paper style={{ height: "calc(100% - 8px)" }}>{memoUserInfoPane}</Paper>
        </Col>
        {!isMobile && (
          <Col>
            <Paper style={{ height: "calc(100% - 8px)" }}>
              <FlexCol
                style={{
                  gap: 5,
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <StatElement name={<Translated keyEn="Total Impacts" />} value={impactsNb} />
                <Link to="#impacts">
                  <Button style={{ marginTop: 10 }} block type="primary">
                    <Translated keyEn="Impacts" />
                  </Button>
                </Link>
              </FlexCol>
            </Paper>
          </Col>
        )}

        {!isMobile && (
          <Col>
            <Paper style={{ height: "calc(100% - 8px)" }}>
              <FlexCol
                style={{
                  gap: 5,
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <StatElement name={<Translated keyEn="Total Sites" />} value={sitesNb} />
                <Link to="#sites">
                  <Button style={{ marginTop: 10 }} block type="primary">
                    <Translated keyEn="Sites" />
                  </Button>
                </Link>
              </FlexCol>
            </Paper>
          </Col>
        )}
        {!isMobile && (
          <Col>
            <Paper style={{ height: "calc(100% - 8px)" }}>
              <FlexCol
                style={{
                  gap: 5,
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <StatElement
                  name={<Translated keyEn="Gmax > 250" />}
                  value={timesAboveGmaxLimit}
                  success={timesAboveGmaxLimit === 0}
                  danger={timesAboveGmaxLimit !== 0}
                />
                <Link to="#impacts">
                  <Button
                    style={{ marginTop: 10 }}
                    block
                    type="primary"
                    disabled={timesAboveGmaxLimit === 0}
                  >
                    <Translated keyEn="Show" />
                  </Button>
                </Link>
              </FlexCol>
            </Paper>
          </Col>
        )}
        {!isMobile && (
          <Col>
            <Paper style={{ height: "calc(100% - 8px)" }}>
              <ReverseGeocodeWidget />
            </Paper>
          </Col>
        )}
      </Row>
    </>
  )
}

enum ESummaryTableType {
  Sites = "sites",
  Impacts = "impacts",
  Map = "map",
}

enum ESummaryTableFilterType {
  Device = "device",
  User = "user",
  Enterprise = "enterprise",
}

export const HomePage: FC = () => {
  const [antdMessageCtx, antdMessageCtxHolder] = message.useMessage()
  const [sites, getSites] = useUsercommSitesBimodal()
  const [syntheticImpacts, getSyntheticImpacts] =
    useUsercommSyntheticImpactWithEmbeddedReferencesBimodal()
  const [deletedSiteAck, deleteSite] = useUsercommDeleteSiteBimodal()

  const [summaryTableType, setSummaryTableType] = useState<ESummaryTableType>(
    ESummaryTableType.Sites,
  )
  const [summaryTableFilterType, setSummaryTableFilterType] = useState<ESummaryTableFilterType>(
    ESummaryTableFilterType.Enterprise,
  )

  const [clonedSite, setClonedSite] = useState<Site | null>(null)
  const [siteInitFormModalIsOpen, setSiteInitFormModalIsOpen] = useState<boolean>(false)

  const { enterpriseUsers, currentUser } = useSyncronizationContext()

  const { stationConfig } = useUsercommContextBLE()
  const location = useLocation()

  useEffect(() => {
    if (location.hash === "") {
      return
    }
    switch (location.hash) {
      case "#sites":
        setSummaryTableType(ESummaryTableType.Sites)
        break
      case "#impacts":
        setSummaryTableType(ESummaryTableType.Impacts)
        break
    }
  }, [location.hash])

  useEffect(() => {
    getSites()
    setTimeout(() => {
      getSyntheticImpacts()
    }, 1000)
  }, [])

  useEffect(() => {
    if (syntheticImpacts === null) {
      return
    }
    // console.log("HomePage: impacts", syntheticImpacts)
  }, [syntheticImpacts])

  const onDeleteSiteCB = useCallback((site: Site) => {
    console.log("HomePage: onDeleteSiteSoft", site)
    deleteSite(site.uuid)
  }, [])

  useEffect(() => {
    if (deletedSiteAck === null) {
      return
    }
    antdMessageCtx.info("Site deleted!")
    getSites()
  }, [deletedSiteAck])

  const _siteIsFilteredOutCB = useCallback(
    (site: Site) => {
      if (
        summaryTableFilterType === ESummaryTableFilterType.Device &&
        stationConfig !== null &&
        site.device_sn !== stationConfig.readonly_station_device_sn
      ) {
        return true
      }
      if (
        summaryTableFilterType === ESummaryTableFilterType.User &&
        currentUser !== null &&
        pbUUIDToUuid(site.user_uuid) !== currentUser.UUID
      ) {
        return true
      }
      if (
        summaryTableFilterType === ESummaryTableFilterType.Enterprise &&
        enterpriseUsers !== null
      ) {
        for (let user of enterpriseUsers) {
          if (user.UUID === pbUUIDToUuid(site.user_uuid)) {
            return false
          }
        }
        return true
      }
      return false
    },
    [stationConfig, currentUser, enterpriseUsers, summaryTableFilterType],
  )

  const memoFilteredSites = useMemo(() => {
    if (sites === null) {
      return null
    }
    let filteredSites: Site[] = []
    for (let site of sites) {
      if (_siteIsFilteredOutCB(site)) {
        continue
      }
      filteredSites.push(site)
    }
    return filteredSites
  }, [sites, summaryTableFilterType])

  const memoFilteredSyntheticImpacts = useMemo(() => {
    if (syntheticImpacts === null) {
      return null
    }
    let filteredSyntheticImpacts: SyntheticImpactWithEmbeddedReferences[] = []
    for (let impact of syntheticImpacts) {
      if (impact.site === null) {
        // always show unassigned impacts
        filteredSyntheticImpacts.push(impact)
        continue
      }
      if (_siteIsFilteredOutCB(impact.site)) {
        continue
      }
      filteredSyntheticImpacts.push(impact)
    }
    return filteredSyntheticImpacts
  }, [syntheticImpacts, summaryTableFilterType])

  const memoSummaryTableSites = useMemo(() => {
    return (
      <SummarySitesTable
        sites={memoFilteredSites}
        setClonedSite={(_site: Site | null) => {
          setClonedSite(_site)
          setSiteInitFormModalIsOpen(true)
        }}
        onDeleteSite={onDeleteSiteCB}
      />
    )
  }, [memoFilteredSites])

  const memoSummaryTableImpacts = useMemo(() => {
    return <SummaryImpactsTable impacts={memoFilteredSyntheticImpacts} />
  }, [memoFilteredSyntheticImpacts])

  const memoSummaryImpactsMap = useMemo(() => {
    return <ImpactsSummaryMap impacts={memoFilteredSyntheticImpacts} />
  }, [memoFilteredSyntheticImpacts])

  return (
    <div>
      {antdMessageCtxHolder}
      <Modal
        open={siteInitFormModalIsOpen}
        width={"90vw"}
        title="New mission"
        onCancel={() => {
          setSiteInitFormModalIsOpen(false)
        }}
        onOk={() => {
          setSiteInitFormModalIsOpen(false)
        }}
        footer={null}
        centered
      >
        <SiteInitForm clonedSite={clonedSite} onToggle={setSiteInitFormModalIsOpen} />
      </Modal>
      <FlexCol
        style={{
          width: "100%",
          maxWidth: "60rem",
          margin: "auto",
          gap: CENTRAL_CONTAINER_GAP,
          marginBottom: "2rem",
        }}
      >
        <FlexRow
          style={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Radio.Group
            buttonStyle="solid"
            size="large"
            value={summaryTableFilterType}
            onChange={(e) => {
              setSummaryTableFilterType(e.target.value)
            }}
          >
            <Radio.Button value={ESummaryTableFilterType.Device}>
              <Translated keyEn="Device" />
            </Radio.Button>
            <Radio.Button value={ESummaryTableFilterType.User}>
              <Translated keyEn="User" />
            </Radio.Button>
            <Radio.Button value={ESummaryTableFilterType.Enterprise}>
              <Translated keyEn="Enterprise" />
            </Radio.Button>
          </Radio.Group>
        </FlexRow>
        <StatsBoard summaryTableFilterType={summaryTableFilterType} />
        <Tabs
          activeKey={summaryTableType}
          onChange={(key) => {
            history.pushState(null, "", `#${key}`)
            setSummaryTableType(key as ESummaryTableType)
          }}
          centered
          style={{
            width: "100%",
          }}
          items={[
            {
              key: ESummaryTableType.Sites,
              label: <Translated keyEn="Sites" />,
              children: memoSummaryTableSites,
            },
            {
              key: ESummaryTableType.Impacts,
              label: <Translated keyEn="Impacts" />,
              children: memoSummaryTableImpacts,
            },
            {
              key: ESummaryTableType.Map,
              label: <Translated keyEn="Map" />,
              children: memoSummaryImpactsMap,
              // destroyInactiveTabPane: true,
            },
          ]}
        />
        <DataCloudSyncWidget />
      </FlexCol>
      <FloatButton
        type="primary"
        style={{
          width: 60,
          height: 60,
          right: "5vw",
          bottom: 60,
        }}
        onClick={() => {
          setSiteInitFormModalIsOpen(true)
          setClonedSite(null)
        }}
        description={
          <Tooltip title={<Translated keyEn="New mission" />}>
            <PlusOutlined style={{ fontSize: "2rem" }} />
          </Tooltip>
        }
      >
        <Translated keyEn="NEW" />
      </FloatButton>
    </div>
  )
}
