import { WarningFilled, DoubleRightOutlined } from "@ant-design/icons"
import { Tooltip, Tag, Button, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { EResultTagAdequacy } from "../../../components/commons-ts/common"
import { UUID, Zone } from "../../../generated/proto-ts/main"

import { Translated } from "../../../utils/translated"
import { pbUUIDToUuid, COLOR_BG_ROW_DELETED } from "../../../utils/utils"
import {
  getCFH_Global,
  getZoneResultPF_CFH,
  getGmaxPF_Adq,
  getHicPF_Adq,
  getZoneResultPF_Adq,
} from "../../../calculus/calculus_PF"
import { TableImageThumbnail } from "../../../components/commons-ts/tableImageThumbnail"
import { useUsercommEquipmentChildrenRecursiveBimodal } from "../../../usercomm/common/usercommAsyncRequestBimodal"
import { IZoneResult } from "../../../calculus/types"
import { MeanWithStd } from "../../../types"
import { IStatEnvelope, StatEnvelope } from "../../../utils/statEnvelope"
import { useSyncronizationContext } from "../../../providers/syncronizationProvider"

export interface IZoneProcessed_CFH {
  zone: Zone
  nb_impacts: number
  cfh: MeanWithStd | null
  result: IZoneResult | null
}

export interface IZoneProcessed_ADQ {
  zone: Zone
  nb_impacts: number
  heightEnv: IStatEnvelope
  gmaxEnv: IStatEnvelope
  hicEnv: IStatEnvelope
  result: IZoneResult | null
}

export const EquipmentZonesTablePF_CFH: FC<{
  equipmentUUID: UUID | null
  zones: Zone[] | null
}> = ({ equipmentUUID, zones }) => {
  const [zoneImpactsMap, getEntitiesRecursiveRequest] =
    useUsercommEquipmentChildrenRecursiveBimodal()
  const [zonesProcessed, setZonesProcessed] = useState<IZoneProcessed_CFH[] | null>(null)
  const { isAdmin } = useSyncronizationContext()

  useEffect(() => {
    getEntitiesRecursiveRequest(equipmentUUID)
  }, [equipmentUUID])

  useEffect(() => {
    if (zones === null || zoneImpactsMap === null) {
      return
    }
    let _zonesProcessed: IZoneProcessed_CFH[] = []
    for (let zone of zones) {
      let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
      let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
      let nb_impacts = 0
      let cfh: MeanWithStd | null = null
      let result: IZoneResult | null = null
      if (zoneImpacts !== undefined) {
        for (let impact of zoneImpacts) {
          if (impact.deleted_at === 0) {
            nb_impacts++
          }
        }
        cfh = getCFH_Global(zoneImpacts)
        result = getZoneResultPF_CFH(zone, zoneImpacts)
      }
      _zonesProcessed.push({
        zone: zone,
        nb_impacts,
        cfh,
        result,
      })
    }
    setZonesProcessed(_zonesProcessed)
  }, [zones, zoneImpactsMap])

  const columns = useMemo((): ColumnsType<IZoneProcessed_CFH> => {
    return [
      {
        title: "📷", // Photo
        render: (_, zone) => {
          let pictureUUID = zone.zone.pictures[0]
          if (pictureUUID === undefined) {
            return <div style={{ width: "3rem" }} />
          }
          return (
            <Link to={`/zones/${pbUUIDToUuid(zone.zone.uuid)}`}>
              <TableImageThumbnail pictureUUID={pictureUUID} />
            </Link>
          )
        },
      },
      {
        key: "zone",
        title: <Translated keyEn="Zone" />,
        ellipsis: true,
        render: (_, zone) => {
          return (
            <Link
              to={`/zones/${pbUUIDToUuid(zone.zone.uuid)}`}
              style={{
                textDecoration: "none",
              }}
            >
              {zone.zone.zone_name}
            </Link>
          )
        },
        sorter: (a, b) => {
          return a.zone.zone_name.localeCompare(b.zone.zone_name)
        },
      },
      {
        title: (
          <Tooltip title={<Translated keyEn="Number of impacts" />}>
            <span>
              <Translated keyEn="#" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          return <span>{zone.nb_impacts}</span>
        },
        sorter: (a, b) => {
          return a.nb_impacts - b.nb_impacts
        },
      },
      {
        key: "ffh",
        title: (
          <Tooltip overlay={<Translated keyEn="Authorized Free Fall Height, m" />}>
            <span>
              <Translated keyEn="FFH" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          let valueMeters = zone.zone.zone_ffh_max / 100
          return <span>{valueMeters.toFixed(2)}</span>
        },
        sorter: (a, b) => {
          return a.zone.zone_ffh_max - b.zone.zone_ffh_max
        },
      },
      {
        key: "CFH",
        title: (
          <Tooltip overlay={<Translated keyEn="Critical Fall Height, m" />}>
            <span>
              <Translated keyEn="CFH" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          if (zone.cfh === null) {
            return <span>N/A</span>
          }
          return (
            <span>
              {zone.cfh.mean.toFixed(2)} {zone.cfh.count && `[${zone.cfh.count}]`}
              {zone.cfh.count && zone.cfh.count < 3 && (
                <Tooltip
                  overlay={
                    <Translated keyEn="Not enough impacts (<3) to compute a reliable mean and standard deviation" />
                  }
                >
                  <WarningFilled
                    style={{
                      color: "orange",
                    }}
                  />
                </Tooltip>
              )}
            </span>
          )
        },
        sorter: (a, b) => {
          if (a.cfh === null || b.cfh === null) {
            return 0
          }
          return a.cfh.mean - b.cfh.mean
        },
        sortOrder: "descend",
      },
      {
        key: "result",
        title: <Translated keyEn="Result" />,
        render: (_, zone) => {
          if (zone.result === null) {
            return <Tag color="default">N/A</Tag>
          }
          return <EResultTagAdequacy result={zone.result.result} />
        },
        sorter: (a, b) => {
          if (a.result === null || b.result === null) {
            return 0
          }
          return a.result.result - b.result.result
        },
      },
      {
        title: "",
        width: "2rem",
        render: (_, zone) => {
          return (
            <Link to={`/zones/${pbUUIDToUuid(zone.zone.uuid)}`}>
              <Button type="link" size="small">
                <DoubleRightOutlined />
              </Button>
            </Link>
          )
        },
      },
    ]
  }, [zonesProcessed])

  return (
    <Table
      dataSource={zonesProcessed ?? []}
      loading={zones === null || zoneImpactsMap === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      onRow={(record) => {
        if (record.zone.deleted_at === 0) {
          return {}
        }
        if (isAdmin) {
          return {
            style: {
              backgroundColor: COLOR_BG_ROW_DELETED,
            },
          }
        } else {
          return {
            style: {
              display: "none",
            },
          }
        }
      }}
      footer={() => (
        <i>
          <Translated keyEn="Table of zones for EN 1177 - PLAYGROUND FLOORS (Method 1: Critical Fall Height)" />
        </i>
      )}
      showSorterTooltip={false}
    />
  )
}

export const EquipmentZonesTablePF_CFH_Print: FC<{
  equipmentUUID: UUID | null
  zones: Zone[] | null
}> = ({ equipmentUUID, zones }) => {
  const [zoneImpactsMap, getEntitiesRecursiveRequest] =
    useUsercommEquipmentChildrenRecursiveBimodal()

  const [zonesProcessed, setZonesProcessed] = useState<IZoneProcessed_CFH[] | null>(null)

  useEffect(() => {
    getEntitiesRecursiveRequest(equipmentUUID)
  }, [equipmentUUID])

  useEffect(() => {
    if (zones === null || zoneImpactsMap === null) {
      return
    }
    let _zonesProcessed: IZoneProcessed_CFH[] = []
    for (let zone of zones) {
      if (zone.deleted_at > 0) {
        continue
      }
      let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
      let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
      let nb_impacts = 0
      let cfh: MeanWithStd | null = null
      let result: IZoneResult | null = null
      if (zoneImpacts !== undefined) {
        for (let impact of zoneImpacts) {
          if (impact.deleted_at === 0) {
            nb_impacts++
          }
          cfh = getCFH_Global(zoneImpacts)
          result = getZoneResultPF_CFH(zone, zoneImpacts)
        }
      }
      _zonesProcessed.push({
        zone: zone,
        nb_impacts,
        cfh,
        result,
      })
    }
    setZonesProcessed(_zonesProcessed)
  }, [zones, zoneImpactsMap])

  const columns = useMemo((): ColumnsType<IZoneProcessed_CFH> => {
    return [
      {
        title: "", // Photo
        render: (_, zone) => {
          let pictureUUID = zone.zone.pictures[0]
          if (pictureUUID === undefined) {
            return <div style={{ width: "3rem" }} />
          }
          return <TableImageThumbnail pictureUUID={pictureUUID} alt={zone.zone.zone_name} />
        },
      },
      {
        key: "zone",
        title: "Zone",
        ellipsis: true,
        render: (_, zone) => {
          return zone.zone.zone_name
        },
      },
      //   {
      //     title: <Translated keyEn="# impacts" />,
      //     ellipsis: true,
      //     render: (_, zone) => {
      //       return zone.nb_impacts
      //     },
      //   },
      {
        key: "ffh",
        title: <Translated keyEn="FFH, m" />,
        render: (_, zone) => {
          let valueMeters = zone.zone.zone_ffh_max / 100
          return <span>{valueMeters.toFixed(2)}</span>
        },
      },
      {
        key: "CFH",
        title: <Translated keyEn="CFH, m" />,
        render: (_, zone) => {
          if (zone.cfh === null) {
            return <span>N/A</span>
          }
          return (
            <span>
              {zone.cfh.mean.toFixed(2)} {zone.cfh.count && `[${zone.cfh.count}]`}
            </span>
          )
        },
      },
      {
        key: "result",
        title: <Translated keyEn="Result" />,
        render: (_, zone) => {
          if (zone.result === null) {
            return <Tag color="default">N/A</Tag>
          }
          return <EResultTagAdequacy result={zone.result.result} />
        },
      },
    ]
  }, [zonesProcessed, zoneImpactsMap])

  return (
    <Table
      className="printed-table"
      dataSource={zonesProcessed ?? []}
      loading={zones === null || zoneImpactsMap === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      bordered
      showSorterTooltip={false}
      style={{
        width: "100%",
      }}
    />
  )
}

export const EquipmentZonesTablePF_ADQ: FC<{
  equipmentUUID: UUID | null
  zones: Zone[] | null
}> = ({ equipmentUUID, zones }) => {
  const [zoneImpactsMap, getEntitiesRecursiveRequest] =
    useUsercommEquipmentChildrenRecursiveBimodal()
  const [zonesProcessed, setZonesProcessed] = useState<IZoneProcessed_ADQ[] | null>(null)
  const { isAdmin } = useSyncronizationContext()

  useEffect(() => {
    getEntitiesRecursiveRequest(equipmentUUID)
  }, [equipmentUUID])

  useEffect(() => {
    if (zones === null || zoneImpactsMap === null) {
      return
    }
    let _zonesProcessed: IZoneProcessed_ADQ[] = []
    for (let zone of zones) {
      let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
      let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
      let nb_impacts = 0
      let heightEnv = new StatEnvelope()
      let gmaxEnv = new StatEnvelope()
      let hicEnv = new StatEnvelope()
      let result: IZoneResult | null = null
      if (zoneImpacts !== undefined) {
        for (let impact of zoneImpacts) {
          if (impact.deleted_at > 0) {
            continue
          }
          nb_impacts++
          heightEnv.addValue(impact.impact_ffh)
          gmaxEnv.addValue(impact.impact_gmax)
          hicEnv.addValue(impact.impact_hic)
        }
        result = getZoneResultPF_Adq(zoneImpacts)
      }
      _zonesProcessed.push({
        zone: zone,
        nb_impacts,
        heightEnv,
        gmaxEnv,
        hicEnv,
        result,
      })
    }
    setZonesProcessed(_zonesProcessed)
  }, [zones, zoneImpactsMap])

  const columns = useMemo((): ColumnsType<IZoneProcessed_ADQ> => {
    return [
      {
        title: "📷", // Photo
        render: (_, zone) => {
          let pictureUUID = zone.zone.pictures[0]
          if (pictureUUID === undefined) {
            return <div style={{ width: "3rem" }} />
          }
          return (
            <Link to={`/zones/${pbUUIDToUuid(zone.zone.uuid)}`}>
              <TableImageThumbnail pictureUUID={pictureUUID} alt={zone.zone.zone_name} />
            </Link>
          )
        },
      },
      {
        key: "zone",
        title: <Translated keyEn="Zone" />,
        ellipsis: true,
        render: (_, zone) => {
          return (
            <Link
              to={`/zones/${pbUUIDToUuid(zone.zone.uuid)}`}
              style={{
                textDecoration: "none",
              }}
            >
              {zone.zone.zone_name}
            </Link>
          )
        },
        sorter: (a, b) => {
          return a.zone.zone_name.localeCompare(b.zone.zone_name)
        },
      },
      {
        title: (
          <Tooltip title={<Translated keyEn="Nb of impacts" />}>
            <span>
              <Translated keyEn="#" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          return <span>{zone.nb_impacts}</span>
        },
        sorter: (a, b) => {
          return a.nb_impacts - b.nb_impacts
        },
      },
      {
        key: "ffh",
        title: (
          <Tooltip overlay={<Translated keyEn="Authorized Free Fall Height, m" />}>
            <span>
              <Translated keyEn="FFH" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          let valueMeters = zone.zone.zone_ffh_max / 100
          return <span>{valueMeters.toFixed(2)}</span>
        },
        sorter: (a, b) => {
          return a.zone.zone_ffh_max - b.zone.zone_ffh_max
        },
      },
      {
        key: "measuredFFH",
        title: (
          <Tooltip overlay={<Translated keyEn="Measured Free Fall Height, m" />}>
            <span>
              <Translated keyEn="Meas.FFH" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          return <span>{zone.heightEnv.format(1)}</span>
        },
      },
      {
        key: "gmax",
        title: (
          <Tooltip overlay={<Translated keyEn="Max impact acceleration, g" />}>
            <span>
              <Translated keyEn="Gmax" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          return <span>{zone.gmaxEnv.format(0)}</span>
        },
      },
      {
        key: "hic",
        title: (
          <Tooltip overlay={<Translated keyEn="Head Injury Criterion" />}>
            <span>
              <Translated keyEn="HIC" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          return <span>{zone.hicEnv.format(0)}</span>
        },
      },
      {
        key: "result",
        title: (
          <Tooltip overlay={<Translated keyEn="Result" />}>
            <span>
              <Translated keyEn="Result" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          if (zone.result === null) {
            return <Tag color="default">N/A</Tag>
          }
          return <EResultTagAdequacy result={zone.result.result} />
        },
      },
      {
        title: "",
        width: "2rem",
        render: (_, zone) => {
          return (
            <Link to={`/zones/${pbUUIDToUuid(zone.zone.uuid)}`}>
              <Button type="link" size="small">
                <DoubleRightOutlined />
              </Button>
            </Link>
          )
        },
      },
    ]
  }, [zones, zoneImpactsMap])

  return (
    <Table
      dataSource={zonesProcessed ?? []}
      loading={zones === null || zoneImpactsMap === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      showSorterTooltip={false}
      onRow={(record) => {
        if (record.zone.deleted_at === 0) {
          return {}
        }
        if (isAdmin) {
          return {
            style: {
              backgroundColor: COLOR_BG_ROW_DELETED,
            },
          }
        } else {
          return {
            style: {
              display: "none",
            },
          }
        }
      }}
      footer={() => (
        <i>
          <Translated keyEn="Table of zones for EN 1177 - PLAYGROUND FLOORS (Method 2: Adequacy)" />
        </i>
      )}
    />
  )
}

export const EquipmentZonesTablePF_ADQ_Print: FC<{
  equipmentUUID: UUID | null
  zones: Zone[] | null
}> = ({ equipmentUUID, zones }) => {
  const [zoneImpactsMap, getEntitiesRecursiveRequest] =
    useUsercommEquipmentChildrenRecursiveBimodal()
  const [zonesProcessed, setZonesProcessed] = useState<IZoneProcessed_ADQ[] | null>(null)

  useEffect(() => {
    getEntitiesRecursiveRequest(equipmentUUID)
  }, [equipmentUUID])

  useEffect(() => {
    if (zones === null || zoneImpactsMap === null) {
      return
    }
    let _zonesProcessed: IZoneProcessed_ADQ[] = []
    for (let zone of zones) {
      if (zone.deleted_at > 0) {
        continue
      }
      let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
      let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
      let nb_impacts = 0
      let heightEnv = new StatEnvelope()
      let gmaxEnv = new StatEnvelope()
      let hicEnv = new StatEnvelope()
      let result: IZoneResult | null = null
      if (zoneImpacts !== undefined) {
        for (let impact of zoneImpacts) {
          if (impact.deleted_at > 0) {
            continue
          }
          nb_impacts++
          heightEnv.addValue(impact.impact_ffh)
          gmaxEnv.addValue(impact.impact_gmax)
          hicEnv.addValue(impact.impact_hic)
        }
        result = getZoneResultPF_Adq(zoneImpacts)
      }
      _zonesProcessed.push({
        zone: zone,
        nb_impacts,
        heightEnv,
        gmaxEnv,
        hicEnv,
        result,
      })
    }
    setZonesProcessed(_zonesProcessed)
  }, [zones, zoneImpactsMap])

  const columns = useMemo((): ColumnsType<IZoneProcessed_ADQ> => {
    return [
      {
        title: "", // Photo
        render: (_, zone) => {
          let pictureUUID = zone.zone.pictures[0]
          if (pictureUUID === undefined) {
            return <div style={{ width: "3rem" }} />
          }
          return <TableImageThumbnail pictureUUID={pictureUUID} alt={zone.zone.zone_name} />
        },
      },
      {
        key: "zone",
        title: <Translated keyEn="Zone" />,
        ellipsis: true,
        render: (_, zone) => {
          return (
            <Link
              to={`/zones/${pbUUIDToUuid(zone.zone.uuid)}`}
              style={{
                textDecoration: "none",
              }}
            >
              {zone.zone.zone_name}
            </Link>
          )
        },
        sorter: (a, b) => {
          return a.zone.zone_name.localeCompare(b.zone.zone_name)
        },
      },
      //   {
      //     title: <Translated keyEn="# impacts" />,
      //     render: (_, zone) => {
      //       return <span>{zone.nb_impacts}</span>
      //     },
      //     sorter: (a, b) => {
      //       return a.nb_impacts - b.nb_impacts
      //     },
      //   },
      {
        key: "ffh",
        title: (
          <Tooltip overlay={<Translated keyEn="Authorized Free Fall Height, m" />}>
            <span>
              <Translated keyEn="FFH" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          let valueMeters = zone.zone.zone_ffh_max / 100
          return <span>{valueMeters.toFixed(2)}</span>
        },
        sorter: (a, b) => {
          return a.zone.zone_ffh_max - b.zone.zone_ffh_max
        },
      },
      {
        key: "measuredFFH",
        title: (
          <Tooltip overlay={<Translated keyEn="Measured Free Fall Height, m" />}>
            <span>
              <Translated keyEn="Meas.FFH" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          return <span>{zone.heightEnv.format(1)}</span>
        },
      },
      {
        key: "gmax",
        title: (
          <Tooltip overlay={<Translated keyEn="Max impact acceleration, g" />}>
            <span>
              <Translated keyEn="Gmax" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          return <span>{zone.gmaxEnv.format(0)}</span>
        },
      },
      {
        key: "hic",
        title: (
          <Tooltip overlay={<Translated keyEn="Head Injury Criterion" />}>
            <span>
              <Translated keyEn="HIC" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          return <span>{zone.hicEnv.format(0)}</span>
        },
      },
      {
        key: "result",
        title: (
          <Tooltip overlay={<Translated keyEn="Result" />}>
            <span>
              <Translated keyEn="Result" />
            </span>
          </Tooltip>
        ),
        render: (_, zone) => {
          if (zone.result === null) {
            return <Tag color="default">N/A</Tag>
          }
          return <EResultTagAdequacy result={zone.result.result} />
        },
      },
    ]
  }, [zones, zoneImpactsMap])

  return (
    <Table
      className="printed-table"
      dataSource={zonesProcessed ?? []}
      loading={zones === null || zoneImpactsMap === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      showSorterTooltip={false}
      style={{
        width: "100%",
      }}
    />
  )
}
