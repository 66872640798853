import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Alert, Button, Col, Radio, Row, Tooltip, Typography, message } from "antd"
import { FlexCol, FlexRow, UnderlinedSectionTitle } from "../../components/commons-ts/common"
import { Translated } from "../../utils/translated"
import { Label, LabeledInput } from "../../components/commons-ts/input"
import dayjs from "dayjs"
import { ReloadOutlined } from "@ant-design/icons"
import "react-json-view-lite/dist/index.css"
import { HICConfig } from "../../generated/proto-ts/main"
import { useUsercommContextBLE } from "../../usercomm/local/ble/usercommProviderBLE"
import { CENTRAL_CONTAINER_GAP } from "../../utils/utils"
import { useSyncronizationContext } from "../../providers/syncronizationProvider"

const HEAD_HIC_BASE_SAMPLE_RATE = 160e3

enum HeadHICNormTypeMode {
  EN_1177 = "EN 1177", // Playground
  EN_12503 = "EN 12503/12562", // Sports mats
  EN_14960 = "EN 14960", // Airbag
  EN_23659 = "EN 23659", // Trampoline
}

export const HeadHICConfigSettings: FC = () => {
  const [antdMessageCtx, antdMessageCtxHolder] = message.useMessage()
  const {
    bleIsConnected,
    hicConfig,
    hicState,

    hicConfigSetAckConsumable,
    consumeHICConfigSetAck,

    emitGetHICConfig,
    emitSetHICConfig,
  } = useUsercommContextBLE()

  const { isAdmin } = useSyncronizationContext()

  // HIC Config
  const [serialNumber, setSerialNumber] = useState<string | null>(null)
  const [lastUpdate, setLastUpdate] = useState<dayjs.Dayjs | null>(null)
  const [correct5VStr, setCorrect5VStr] = useState<string | null>(null)
  const [triggerThresholdStr, setTriggerThresholdStr] = useState<string | null>(null)
  const [nbChuteStr, setNbChuteStr] = useState<string | null>(null)
  const [maxGStr, setMaxGStr] = useState<string | null>(null)
  const [dividerBatteryPointStr, setDividerBatteryPointStr] = useState<string | null>(null)
  const [fallOffsetStr, setFallOffsetStr] = useState<string | null>(null)

  const [xSensitivityStr, setXSensitivityStr] = useState<string | null>(null)
  const [xOffsetStr, setXOffsetStr] = useState<string | null>(null)
  const [xSerialStr, setXSerialStr] = useState<string | null>(null)

  const [ySensitivityStr, setYSensitivityStr] = useState<string | null>(null)
  const [yOffsetStr, setYOffsetStr] = useState<string | null>(null)
  const [ySerialStr, setYSerialStr] = useState<string | null>(null)

  const [zSensitivityStr, setZSensitivityStr] = useState<string | null>(null)
  const [zOffsetStr, setZOffsetStr] = useState<string | null>(null)
  const [zSerialStr, setZSerialStr] = useState<string | null>(null)

  const [decimationFactorStr, setDecimationFactorStr] = useState<string | null>(null)
  const [sampleSizeStr, setSampleSizeStr] = useState<string | null>(null)

  const [headHicNormTypeMode, setHeadHicNormTypeMode] = useState<HeadHICNormTypeMode | null>(null)

  const memoHicIsConnected = useMemo(() => {
    if (hicState === null) {
      return false
    }
    return hicState.is_connected
  }, [bleIsConnected, hicState?.is_connected])

  useEffect(() => {
    console.log("hicConfigSetAckConsumable", hicConfigSetAckConsumable)
    if (hicConfigSetAckConsumable !== null) {
      antdMessageCtx.success("HIC configuration saved!")
      consumeHICConfigSetAck()
    }
  }, [hicConfigSetAckConsumable])

  useEffect(() => {
    if (memoHicIsConnected) {
      emitGetHICConfig()
    }
  }, [memoHicIsConnected])

  useEffect(() => {
    if (hicConfig === null) {
      return
    }
    if (hicConfig.serial_number) {
      setSerialNumber(hicConfig.serial_number)
    }
    if (hicConfig.last_control_date) {
      // e.g. 20240116
      setLastUpdate(dayjs(hicConfig.last_control_date, "YYYYMMDD"))
    }
    if (hicConfig.sensor_x_sensitivity) {
      setXSensitivityStr(hicConfig.sensor_x_sensitivity)
    }
    if (hicConfig.sensor_x_offset) {
      setXOffsetStr(hicConfig.sensor_x_offset)
    }
    if (hicConfig.sensor_x_serial_number) {
      setXSerialStr(hicConfig.sensor_x_serial_number)
    }
    if (hicConfig.sensor_y_sensitivity) {
      setYSensitivityStr(hicConfig.sensor_y_sensitivity)
    }
    if (hicConfig.sensor_y_offset) {
      setYOffsetStr(hicConfig.sensor_y_offset)
    }
    if (hicConfig.sensor_y_serial_number) {
      setYSerialStr(hicConfig.sensor_y_serial_number)
    }
    if (hicConfig.sensor_z_sensitivity) {
      setZSensitivityStr(hicConfig.sensor_z_sensitivity)
    }
    if (hicConfig.sensor_z_offset) {
      setZOffsetStr(hicConfig.sensor_z_offset)
    }
    if (hicConfig.sensor_z_serial_number) {
      setZSerialStr(hicConfig.sensor_z_serial_number)
    }
    if (hicConfig.real_5v_corrected) {
      setCorrect5VStr(hicConfig.real_5v_corrected.toString())
    }
    if (hicConfig.trigger_point_in_g) {
      setTriggerThresholdStr(hicConfig.trigger_point_in_g)
    }
    if (hicConfig.all_time_max_g) {
      setMaxGStr(hicConfig.all_time_max_g.toString())
    }
    if (hicConfig.battery_coef) {
      setDividerBatteryPointStr(hicConfig.battery_coef.toString())
    }
    if (hicConfig.fall_offset) {
      setFallOffsetStr(hicConfig.fall_offset.toString())
    }
    if (hicConfig.fall_count) {
      setNbChuteStr(hicConfig.fall_count.toString())
    }
    if (hicConfig.decimation_factor) {
      setDecimationFactorStr(hicConfig.decimation_factor.toString())
    }
    if (hicConfig.sample_size) {
      setSampleSizeStr(hicConfig.sample_size.toString())
    }
  }, [hicConfig])

  const onSave = useCallback(() => {
    let newConfig: typeof hicConfig = new HICConfig({})
    if (serialNumber !== null) {
      newConfig.serial_number = serialNumber
    }
    if (lastUpdate !== null && lastUpdate.isValid()) {
      newConfig.last_control_date = lastUpdate.format("YYYYMMDD")
    }
    if (xSensitivityStr !== null && xSensitivityStr !== "") {
      newConfig.sensor_x_sensitivity = xSensitivityStr
    }
    if (xOffsetStr !== null && xOffsetStr !== "") {
      newConfig.sensor_x_offset = xOffsetStr
    }
    if (xSerialStr !== null && xSerialStr !== "") {
      newConfig.sensor_x_serial_number = xSerialStr
    }
    if (ySensitivityStr !== null && ySensitivityStr !== "") {
      newConfig.sensor_y_sensitivity = ySensitivityStr
    }
    if (yOffsetStr !== null && yOffsetStr !== "") {
      newConfig.sensor_y_offset = yOffsetStr
    }
    if (ySerialStr !== null && ySerialStr !== "") {
      newConfig.sensor_y_serial_number = ySerialStr
    }
    if (zSensitivityStr !== null && zSensitivityStr !== "") {
      newConfig.sensor_z_sensitivity = zSensitivityStr
    }
    if (zOffsetStr !== null && zOffsetStr !== "") {
      newConfig.sensor_z_offset = zOffsetStr
    }
    if (zSerialStr !== null && zSerialStr !== "") {
      newConfig.sensor_z_serial_number = zSerialStr
    }
    if (correct5VStr !== null && correct5VStr !== "") {
      newConfig.real_5v_corrected = correct5VStr
    }
    if (triggerThresholdStr !== null && triggerThresholdStr !== "") {
      newConfig.trigger_point_in_g = triggerThresholdStr
    }
    if (maxGStr !== null && maxGStr !== "") {
      newConfig.all_time_max_g = maxGStr
    }
    if (dividerBatteryPointStr !== null && dividerBatteryPointStr !== "") {
      newConfig.battery_coef = dividerBatteryPointStr
    }
    if (fallOffsetStr !== null && fallOffsetStr !== "") {
      newConfig.fall_offset = fallOffsetStr
    }
    // Read-only
    // if (nbChuteStr !== null && nbChuteStr !== "") {
    //     newConfig.fall_count = nbChuteStr
    // }
    if (decimationFactorStr !== null && decimationFactorStr !== "") {
      newConfig.decimation_factor = decimationFactorStr
    }
    if (sampleSizeStr !== null && sampleSizeStr !== "") {
      newConfig.sample_size = sampleSizeStr
    }
    emitSetHICConfig(newConfig)
  }, [
    serialNumber,
    correct5VStr,
    dividerBatteryPointStr,
    fallOffsetStr,
    serialNumber,
    maxGStr,
    nbChuteStr,
    triggerThresholdStr,
    xSensitivityStr,
    xOffsetStr,
    xSerialStr,
    ySensitivityStr,
    yOffsetStr,
    ySerialStr,
    zSensitivityStr,
    zOffsetStr,
    zSerialStr,
    decimationFactorStr,
    sampleSizeStr,
  ])

  const memoEffectiveSampleRateElement = useMemo(() => {
    if (decimationFactorStr === null || decimationFactorStr === "") {
      return null
    }
    let decimationFactorInt = parseInt(decimationFactorStr)
    if (isNaN(decimationFactorInt) || !isFinite(decimationFactorInt)) {
      return null
    }
    let effectiveSampleRate = HEAD_HIC_BASE_SAMPLE_RATE / decimationFactorInt
    if (effectiveSampleRate >= 2e3) {
      effectiveSampleRate /= 1e3
      return <>{effectiveSampleRate.toFixed(2)} kHz</>
    }
    return <>{effectiveSampleRate.toFixed(0)} Hz</>
  }, [decimationFactorStr])

  const memoEffectiveSampleTimeElement = useMemo(() => {
    if (sampleSizeStr === null || sampleSizeStr === "") {
      return null
    }
    let sampleSizeInt = parseInt(sampleSizeStr)
    if (isNaN(sampleSizeInt) || !isFinite(sampleSizeInt)) {
      return null
    }
    if (decimationFactorStr === null || decimationFactorStr === "") {
      return null
    }
    let decimationFactorInt = parseInt(decimationFactorStr)
    if (isNaN(decimationFactorInt) || !isFinite(decimationFactorInt)) {
      return null
    }
    let effectiveSampleTimeSec = sampleSizeInt / (HEAD_HIC_BASE_SAMPLE_RATE / decimationFactorInt)
    if (effectiveSampleTimeSec >= 1) {
      return <>{effectiveSampleTimeSec.toFixed(2)} s</>
    }
    effectiveSampleTimeSec *= 1e3
    return <>{effectiveSampleTimeSec.toFixed(0)} ms</>
  }, [decimationFactorStr, sampleSizeStr])

  const memoEffectiveNoizeLevelElement = useMemo(() => {
    if (decimationFactorStr === null || decimationFactorStr === "") {
      return null
    }
    let decimationFactorInt = parseInt(decimationFactorStr)
    if (isNaN(decimationFactorInt) || !isFinite(decimationFactorInt)) {
      return null
    }
    let baseNoizeLevel = 3 // +-3g
    let noizeReductionFactor = Math.sqrt(decimationFactorInt) * Math.sqrt(3) // 3 sensors in a tetraedral configuration
    let effectiveNoizeLevel = baseNoizeLevel / noizeReductionFactor
    return <>±{effectiveNoizeLevel.toFixed(2)}g</>
  }, [decimationFactorStr])

  useEffect(() => {
    if (headHicNormTypeMode === null) {
      return
    }
    switch (headHicNormTypeMode) {
      case HeadHICNormTypeMode.EN_1177:
        setDecimationFactorStr("8") // 20 kHz
        setSampleSizeStr("1500") // 75 ms
        setTriggerThresholdStr("10") // 10g
        break
      case HeadHICNormTypeMode.EN_12503:
        setDecimationFactorStr("16") // 10 kHz
        setSampleSizeStr("1500") // 150 ms
        setTriggerThresholdStr("5") // 5g
        break
      case HeadHICNormTypeMode.EN_14960:
        setDecimationFactorStr("16") // 10 kHz
        setSampleSizeStr("3000") // 300 ms
        setTriggerThresholdStr("5") // 5g
        break
      case HeadHICNormTypeMode.EN_23659:
        setDecimationFactorStr("80") // 2 kHz
        setSampleSizeStr("2000") // 1.00 s
        setTriggerThresholdStr("1.5") // 1.5g
        break
    }
  }, [headHicNormTypeMode])

  return (
    <>
      {antdMessageCtxHolder}
      <FlexCol
        style={{
          width: "100%",
          // maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
          margin: "auto",
          gap: CENTRAL_CONTAINER_GAP,
          marginBottom: "2rem",
        }}
      >
        {/* Header */}
        <FlexCol style={{ gap: 0 }}>
          <Typography.Text
            style={{
              fontSize: "2rem",
            }}
          >
            <Translated keyEn="Head HIC" />
          </Typography.Text>
        </FlexCol>
        {/* HIC Config */}
        <div>
          <FlexRow style={{ justifyContent: "space-between" }}>
            <UnderlinedSectionTitle>
              <Translated keyEn="HIC Configuration" />
            </UnderlinedSectionTitle>
            <div>
              <Button size="large" onClick={() => emitGetHICConfig()} icon={<ReloadOutlined />}>
                <span
                  style={{
                    textTransform: "uppercase",
                    // fontWeight: "bold",
                  }}
                >
                  <Translated keyEn="Reload" />
                </span>
              </Button>
            </div>
          </FlexRow>
          <Row gutter={[10, 10]} style={{ marginTop: 5 }}>
            {!memoHicIsConnected && (
              <Col xs={24}>
                <Alert
                  message={
                    <Translated keyEn="Head HIC is not connected to Station (Largueur). Please check that it is powered!" />
                  }
                  type="warning"
                  showIcon
                />
              </Col>
            )}
            <Row />
          </Row>
        </div>
        {/* Measurement */}
        <div>
          <UnderlinedSectionTitle>
            <Translated keyEn="Measurement" />
          </UnderlinedSectionTitle>
          <Row gutter={[10, 10]}>
            <Col xs={24}>
              <FlexCol
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Translated keyEn="Measurement Preset" />
                <Radio.Group
                  size="large"
                  optionType="button"
                  value={headHicNormTypeMode}
                  onChange={(e) => setHeadHicNormTypeMode(e.target.value)}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Radio.Button value={HeadHICNormTypeMode.EN_1177}>
                    <Tooltip overlay={<Translated keyEn="Playground" />}>
                      {HeadHICNormTypeMode.EN_1177}
                    </Tooltip>
                  </Radio.Button>
                  <Radio.Button value={HeadHICNormTypeMode.EN_12503}>
                    <Tooltip overlay={<Translated keyEn="Sports mats" />}>
                      {HeadHICNormTypeMode.EN_12503}
                    </Tooltip>
                  </Radio.Button>
                  <Radio.Button value={HeadHICNormTypeMode.EN_23659}>
                    <Tooltip overlay={<Translated keyEn="Trampoline" />}>
                      {HeadHICNormTypeMode.EN_23659}
                    </Tooltip>
                  </Radio.Button>
                  <Radio.Button value={HeadHICNormTypeMode.EN_14960}>
                    <Tooltip overlay={<Translated keyEn="Airbag" />}>
                      {HeadHICNormTypeMode.EN_14960}
                    </Tooltip>
                  </Radio.Button>
                </Radio.Group>
              </FlexCol>
            </Col>
            <Col xs={24} md={8}>
              <LabeledInput
                translatableLabel={"Decimation factor, #"}
                value={decimationFactorStr}
                setValue={setDecimationFactorStr}
                addonAfter={<b>{memoEffectiveSampleRateElement}</b>}
              />
            </Col>
            <Col xs={24} md={8}>
              <LabeledInput
                translatableLabel={"Sample size, #"}
                value={sampleSizeStr}
                setValue={setSampleSizeStr}
                addonAfter={<b>{memoEffectiveSampleTimeElement}</b>}
              />
            </Col>
            <Col xs={24} md={8}>
              <LabeledInput
                translatableLabel={"Threshold, g"}
                value={triggerThresholdStr}
                setValue={setTriggerThresholdStr}
                addonAfter={memoEffectiveNoizeLevelElement}
              />
            </Col>
          </Row>
        </div>
        {isAdmin && (
          <>
            {/* S/N */}
            <div>
              <Row>
                <Col xs={24}>
                  <LabeledInput
                    translatableLabel={"Head HIC S/N"}
                    value={serialNumber}
                    setValue={setSerialNumber}
                  />
                </Col>
              </Row>
            </div>
            {/* X */}
            <div>
              <UnderlinedSectionTitle>
                <Translated keyEn="Accelerometer X" />
              </UnderlinedSectionTitle>
              <Row gutter={[10, 10]}>
                <Col xs={24}>
                  <LabeledInput
                    translatableLabel={"S/N"}
                    value={xSerialStr}
                    setValue={setXSerialStr}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LabeledInput
                    translatableLabel={"Sensitivity, mv/g"}
                    value={xSensitivityStr}
                    setValue={setXSensitivityStr}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LabeledInput
                    translatableLabel={"Offset (as calibrated)"}
                    value={xOffsetStr}
                    setValue={setXOffsetStr}
                    disabled // offsets are read-only (calibrated on startup)
                  />
                </Col>
              </Row>
            </div>
            {/* Y */}
            <div>
              <UnderlinedSectionTitle>
                <Translated keyEn="Accelerometer Y" />
              </UnderlinedSectionTitle>
              <Row gutter={[10, 10]}>
                <Col xs={24}>
                  <LabeledInput
                    translatableLabel={"S/N"}
                    value={ySerialStr}
                    setValue={setYSerialStr}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LabeledInput
                    translatableLabel={"Sensitivity, mv/g"}
                    value={ySensitivityStr}
                    setValue={setYSensitivityStr}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LabeledInput
                    translatableLabel={"Offset (as calibrated)"}
                    value={yOffsetStr}
                    setValue={setYOffsetStr}
                    disabled // offsets are read-only (calibrated on startup)
                  />
                </Col>
              </Row>
            </div>
            {/* Z */}
            <div>
              <UnderlinedSectionTitle>
                <Translated keyEn="Accelerometer Z" />
              </UnderlinedSectionTitle>
              <Row gutter={[10, 10]}>
                <Col xs={24}>
                  <LabeledInput
                    translatableLabel={"S/N"}
                    value={zSerialStr}
                    setValue={setZSerialStr}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LabeledInput
                    translatableLabel={"Sensitivity, mv/g"}
                    value={zSensitivityStr}
                    setValue={setZSensitivityStr}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LabeledInput
                    translatableLabel={"Offset (as calibrated)"}
                    value={zOffsetStr}
                    setValue={setZOffsetStr}
                    disabled // offsets are read-only (calibrated on startup)
                  />
                </Col>
              </Row>
            </div>
            {/* Misc */}
            <div>
              <UnderlinedSectionTitle>
                <Translated keyEn="Misc" />
              </UnderlinedSectionTitle>
              <Row gutter={[10, 10]}>
                <Col xs={24} md={12}>
                  <LabeledInput
                    translatableLabel={"Reference voltage, V"}
                    value={correct5VStr}
                    setValue={setCorrect5VStr}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LabeledInput
                    translatableLabel={"Battery reference voltage, V"}
                    value={dividerBatteryPointStr}
                    setValue={setDividerBatteryPointStr}
                    disabled // read-only (factory calibrated)
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LabeledInput
                    translatableLabel={"Measurement counter, #"}
                    value={nbChuteStr}
                    setValue={setNbChuteStr}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LabeledInput
                    translatableLabel={"Maximum measured G, g"}
                    value={maxGStr}
                    setValue={setMaxGStr}
                  />
                </Col>
              </Row>
            </div>
          </>
        )}
        {/* Save */}
        <div>
          <Button block size="large" type="primary" onClick={onSave}>
            <span
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              <Translated keyEn="Save" />
            </span>
          </Button>
        </div>
      </FlexCol>
    </>
  )
}
