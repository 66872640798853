import { ReactElement } from "react"

export interface IStatEnvelope {
  min: number
  max: number
  n: number

  mean: number
  std: number

  format(nDigits: number, nDigitsStd?: number): ReactElement
}

export class StatEnvelope implements IStatEnvelope {
  private values: number[] = []
  min: number = Number.MAX_VALUE
  max: number = -Number.MAX_VALUE
  private sum: number = 0
  n: number = 0

  constructor() {}

  addValue(value: number): void {
    this.values.push(value)
    this.min = Math.min(this.min, value)
    this.max = Math.max(this.max, value)
    this.sum += value
    this.n += 1
  }

  get mean(): number {
    return this.sum / this.n
  }

  get std(): number {
    let std = 0
    let _mean = this.mean
    for (let value of this.values) {
      std += Math.pow(value - _mean, 2)
    }
    return Math.sqrt(std / this.values.length)
  }

  format(nDigits: number, nDigitsStd?: number): ReactElement {
    let _nDigitsStd = nDigitsStd ?? nDigits

    if (this.n === 0) {
      return <>N/A</>
    }

    if (this.n === 1) {
      return <>{this.mean.toFixed(nDigits)}</>
    }

    let _std = 2 * this.std
    return (
      <>
        {this.mean.toFixed(nDigits)}±{_std.toFixed(_nDigitsStd)}
        <sup>[{this.n}]</sup>
      </>
    )
  }
}
