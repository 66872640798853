import { FC, useEffect, useState } from "react"
import { Image, Upload, UploadFile } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { Translated } from "../../utils/translated"

import ImageBlobReduce from "image-blob-reduce"
import Pica from "pica" // for image in-browser resizing before uploading
const pica = Pica({ features: ["js", "wasm", "cib"] })

export const UploadUserImage: FC<{
  pictureUUID: string | null
  setPictureUUID: (picture: string | null) => void
}> = ({ pictureUUID, setPictureUUID }) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState("")
  const [fileList, setFileList] = useState<UploadFile[]>([])
  useEffect(() => {
    if (pictureUUID === null) {
      setFileList([])
      return
    }
    console.log(`UploadIUserImage: setting file`, pictureUUID)
    setFileList([
      {
        uid: pictureUUID,
        name: pictureUUID,
        status: "done",
        url: `/api/uploads/${pictureUUID}`,
      },
    ])
  }, [pictureUUID])

  const onPreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        <Translated keyEn="Upload" />
      </div>
    </button>
  )

  return (
    <>
      <Upload
        accept="image/*"
        capture="environment"
        action="/api/uploads/"
        listType="picture-circle"
        fileList={fileList}
        onPreview={onPreview}
        className="upload-user-image"
        beforeUpload={async (file) => {
          try {
            let resizedBlob = await new ImageBlobReduce({
              pica,
            }).toBlob(file, {
              max: 1024, // 768
            })
            return resizedBlob
          } catch (e: any) {
            console.error(`Failed to resize image ${file.name}: ${e.message}`)
            return file
          }
        }}
        onChange={({ fileList: newFilelist, file }) => {
          if (file.status === "done") {
            let responseUUID = file.response as string
            file.uid = responseUUID
            file.url = `/api/uploads/${responseUUID}`
            setPictureUUID(responseUUID)
          } else {
            console.log(`UploadImageV2: newFileList`, newFilelist)
            setFileList([...newFilelist])
          }
        }}
        onRemove={(file) => {
          fetch(`/api/uploads/${file.uid}`, {
            method: "DELETE",
          }).then((resp) => {
            if (!resp.ok) {
              console.warn(
                `Failed to delete file ${file.uid} on the server; will remove from the state anyway..`,
              )
            }
            setPictureUUID(null)
          })
        }}
        style={{ width: 300, height: 300 }}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          wrapperClassName="upload-user-image-preview"
          src={previewImage}
          width={"100%"}
          height={"100%"}
        />
      )}
    </>
  )
}
