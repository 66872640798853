import { Alert, GetProp, Modal, Upload, UploadFile, UploadProps } from "antd"
import { CSSProperties, FC, useEffect, useState } from "react"
import { UUID } from "../../generated/proto-ts/main"
import { InfoCircleFilled, PlusOutlined } from "@ant-design/icons"
import { COLOR_BG_BLUE, pbUUIDToUuid, uuidToPbUUID } from "../../utils/utils"
import ImageBlobReduce from "image-blob-reduce"
import Pica from "pica" // for image in-browser resizing before uploading
// DnD-kit for sortable list
import type { DragEndEvent } from "@dnd-kit/core"
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { FlexCol, FlexRow } from "./common"
import { Translated } from "../../utils/translated"

const pica = Pica({ features: ["js", "wasm", "cib"] })

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  file: UploadFile<any>
}

const DraggableUploadListItem = ({ originNode, file }: DraggableUploadListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: file.uid,
  })

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move",
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? "is-dragging" : ""}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === "error" && isDragging ? originNode.props.children : originNode}
    </div>
  )
}

export const UploadImageListV2: FC<{
  pictures: UUID[]
  setPictures: React.Dispatch<React.SetStateAction<UUID[]>>
}> = ({ pictures, setPictures }) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState("")
  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    // console.log(
    //     `UploadImagesListV2: setting files with pictures`,
    //     pictures.map((e) => pbUUIDToUuid(e)),
    // )
    setFileList(
      pictures.map((pictureUUID) => {
        let uuidStr = pbUUIDToUuid(pictureUUID)
        return {
          uid: uuidStr,
          name: uuidStr,
          status: "done",
          url: `/api/uploads/${uuidStr}`,
        }
      }),
    )
  }, [pictures])

  const onPreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        <Translated keyEn="Upload" />
      </div>
    </button>
  )

  const maxCount = 5

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  })

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id)
        const overIndex = prev.findIndex((i) => i.uid === over?.id)
        return arrayMove(prev, activeIndex, overIndex)
      })
      setPictures((prev) => {
        const activeIndex = prev.findIndex((i) => pbUUIDToUuid(i) === active.id)
        const overIndex = prev.findIndex((i) => pbUUIDToUuid(i) === over?.id)
        return arrayMove(prev, activeIndex, overIndex)
      })
    }
  }

  return (
    <>
      <FlexCol>
        <Modal
          style={{
            height: "80vh",
          }}
          width={"80vw"}
          open={previewOpen}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
          centered
        >
          <FlexRow
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img alt="example" style={{ maxHeight: "80vh", width: "auto" }} src={previewImage} />
          </FlexRow>
        </Modal>
        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
          <SortableContext
            items={fileList.map((i) => i.uid)}
            strategy={verticalListSortingStrategy}
          >
            <Upload
              accept="image/*,.pdf" // IMPORTANT: .pdf is a hack to make mobile devices offer camera capture AND file picker dialog
              capture="environment"
              action="/api/uploads/"
              listType="picture-card"
              multiple
              maxCount={maxCount}
              fileList={fileList}
              onPreview={onPreview}
              // rootClassName="upload-image-list-v2"
              className="upload-image-list-v2"
              itemRender={(originNode, file, fileList) => {
                let idx = fileList.findIndex((f) => f.uid === file.uid)
                let style: CSSProperties = {
                  width: "100%",
                  height: "100%",
                }
                if (idx === 0) {
                  style = {
                    ...style,
                    border: "2px solid #0065ff",
                    borderRadius: "12px",
                  }
                }
                return (
                  <div style={style}>
                    <DraggableUploadListItem originNode={originNode} file={file} />
                  </div>
                )
              }}
              beforeUpload={async (file) => {
                try {
                  let resizedBlob = await new ImageBlobReduce({
                    pica,
                  }).toBlob(file, {
                    max: 1024, // 768
                  })
                  return resizedBlob
                } catch (e: any) {
                  console.error(`Failed to resize image ${file.name}: ${e.message}`)
                  return file
                }
              }}
              onChange={({ fileList: newFilelist, file }) => {
                if (file.status === "done") {
                  let responseUUID = file.response as string
                  let pbUUID = uuidToPbUUID(responseUUID)
                  file.uid = responseUUID
                  file.url = `/api/uploads/${responseUUID}`
                  setPictures([...pictures, pbUUID])
                } else {
                  console.log(`UploadImageV2: newFileList`, newFilelist)
                  setFileList([...newFilelist])
                }
              }}
              onRemove={(file) => {
                fetch(`/api/uploads/${file.uid}`, {
                  method: "DELETE",
                }).then((resp) => {
                  if (!resp.ok) {
                    console.warn(
                      `Failed to delete file ${file.uid} on the server; will remove from the state anyway..`,
                    )
                  }
                  let _pictures: UUID[] = []
                  for (let picture of pictures) {
                    let pictureUUID = pbUUIDToUuid(picture)
                    if (pictureUUID !== file.uid) {
                      _pictures.push(picture)
                    }
                  }
                  setPictures(_pictures)
                })
              }}
            >
              {pictures.length >= maxCount ? null : uploadButton}
            </Upload>
          </SortableContext>
        </DndContext>
        <Alert
          style={{
            marginBottom: 5,
          }}
          closable
          type="info"
          showIcon
          message={
            <>
              <b
                style={{
                  color: COLOR_BG_BLUE,
                }}
              >
                N.B.:{" "}
              </b>
              <Translated keyEn="only the" />{" "}
              <b
                style={{
                  color: COLOR_BG_BLUE,
                }}
              >
                <Translated keyEn="first" />
              </b>{" "}
              <Translated keyEn="picture will be added to the report. You can" />{" "}
              <b
                style={{
                  color: COLOR_BG_BLUE,
                }}
              >
                <Translated keyEn="drag-n-drop" />
              </b>{" "}
              <Translated keyEn="pictures to rearrange them as you prefer." />
            </>
          }
        />
      </FlexCol>
    </>
  )
}
