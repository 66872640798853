import { FC, ReactElement, useEffect, useMemo, useState } from "react"
import { Avatar, Button, Popover, Switch, Tag, Tooltip } from "antd"
import { AccountBookOutlined, UserOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { deleteLocalStorageCloudApiUser, getLocalStorageCloudApiUser } from "../../utils/cloudApiV2"
import { FlexCol, FlexRow } from "./common"
import { COLOR_BG_BLUE, COLOR_BG_GRAY, COLOR_BG_GREEN } from "../../utils/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { ICloudUser } from "../../types"
import { Translated } from "../../utils/translated"
import { AvatarSize } from "antd/es/avatar/AvatarContext"
import { useSyncronizationContext } from "../../providers/syncronizationProvider"

const RoundedTag: FC<{
  children: React.ReactNode
  color?: string
  style?: React.CSSProperties
}> = ({ children, color, style }) => {
  return (
    <Tag
      color={color}
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 20,
        ...style,
      }}
    >
      {children}
    </Tag>
  )
}

export const UserAvatar: FC<{
  user: ICloudUser | null
}> = ({ user }) => {
  let avatarSize: AvatarSize | undefined = "large"
  let tooltipTitle: string | undefined = undefined
  let avatarElement: ReactElement | null = null
  if (user === null) {
    // Empty user (e.g. logged out)
    avatarElement = (
      <Avatar
        size={avatarSize}
        style={{
          backgroundColor: COLOR_BG_GRAY,
        }}
        icon={<UserOutlined />}
      />
    )
  } else {
    tooltipTitle = `${user.FirstName} ${user.LastName}`
    if (user.PictureUUID) {
      avatarElement = <Avatar size={avatarSize} src={`/api/uploads/${user.PictureUUID}`} />
    } else {
      avatarElement = (
        <Avatar
          size={avatarSize}
          style={{
            backgroundColor: COLOR_BG_GREEN,
          }}
          icon={<UserOutlined />}
        />
      )
    }
  }
  return <Tooltip title={tooltipTitle}>{avatarElement}</Tooltip>
}

export const UserWidget = () => {
  const { currentUser, externallySetCurrentUser } = useSyncronizationContext()

  const memoUserPopoverContent = useMemo(() => {
    if (currentUser === null) {
      return null
    }
    return (
      <FlexCol
        style={{
          gap: 3,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FlexCol
          style={{
            alignItems: "stretch",
            gap: 5,
          }}
        >
          <FlexCol
            style={{
              gap: 5,
              alignItems: "start",
            }}
          >
            <b>
              {currentUser.LastName} {currentUser.FirstName}
            </b>
            {currentUser.Enterprise.Name && (
              <RoundedTag color="blue">
                <FlexRow
                  style={{
                    fontSize: "1.1rem",
                    gap: 3,
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faBuilding} color={COLOR_BG_BLUE} />
                  {currentUser.Enterprise.Name}
                </FlexRow>
              </RoundedTag>
            )}
            {currentUser.Username && (
              <RoundedTag color="green">
                <FlexRow
                  style={{
                    fontSize: "1.1rem",
                    gap: 3,
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faUserCircle} color={COLOR_BG_GREEN} />
                  {currentUser.Username}
                </FlexRow>
              </RoundedTag>
            )}
          </FlexCol>
          <FlexRow>
            <Link to="/settings/general#account">
              <Button block>
                <Translated keyEn="Account" />
              </Button>
            </Link>
            <Button
              danger
              block
              type="default"
              href="/api/logout"
              onClick={() => {
                deleteLocalStorageCloudApiUser()
                externallySetCurrentUser(null)
              }}
            >
              <Translated keyEn="Sign out" />
            </Button>
          </FlexRow>
        </FlexCol>
      </FlexCol>
    )
  }, [currentUser])

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      overlayStyle={{
        minWidth: 300,
        minHeight: 300,
      }}
      overlayInnerStyle={{
        padding: "1.2rem",
      }}
      open={currentUser !== null ? undefined : false}
      content={memoUserPopoverContent}
    >
      <div
        style={{
          cursor: "pointer",
        }}
      >
        <UserAvatar user={currentUser} />
      </div>
    </Popover>
  )
}
