import { faFlask } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, Avatar } from "antd"
import { FC, ReactElement } from "react"
import { NormType, MethodTypeEN1177, Site } from "../../generated/proto-ts/main"
import { Translated } from "../../utils/translated"
import { FlexRow } from "./common"

export const NormTypeAvatar: FC<{ site: Site }> = ({ site }) => {
  let normElement: ReactElement | null = null
  switch (site.norm_type) {
    case NormType.EN_1177:
      normElement = (
        <Tooltip overlay={<Translated keyEn="Playground floor" />}>
          <Avatar
            style={{
              color: "#cf1322", // red-7
              backgroundColor: "#ffccc7", // red-2
            }}
          >
            <Translated keyEn="PG" />
          </Avatar>
        </Tooltip>
      )
      break
    case NormType.EN_12503:
      normElement = (
        <Tooltip overlay={<Translated keyEn="Sports mat" />}>
          <Avatar
            style={{
              color: "#389e0d", // green-7
              backgroundColor: "#d9f7be", // green-2
            }}
          >
            <Translated keyEn="SM" />
          </Avatar>
        </Tooltip>
      )
      break
    case NormType.EN_14960:
      normElement = (
        <Tooltip overlay={<Translated keyEn="Airbags" />}>
          <Avatar
            style={{
              color: "#389e0d", // green-7
              backgroundColor: "#d9f7be", // green-2
            }}
          >
            <Translated keyEn="AIR" />
          </Avatar>
        </Tooltip>
      )
      break
    case NormType.EN_ISO_23659:
      normElement = (
        <Tooltip overlay={<Translated keyEn="Trampolines" />}>
          <Avatar
            style={{
              color: "#389e0d", // green-7
              backgroundColor: "#d9f7be", // green-2
            }}
          >
            <Translated keyEn="TL" />
          </Avatar>
        </Tooltip>
      )
      break
  }
  let methodElement: ReactElement | null = null
  switch (site.method_type_en_1177) {
    case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
      methodElement = (
        <Tooltip overlay={<Translated keyEn="Critical fall height" />}>
          <Avatar
            style={{
              color: "#cf1322", // magenta-7
              backgroundColor: "#ffd6e7", // magenta-2
            }}
          >
            <Translated keyEn="CFH" />
          </Avatar>
        </Tooltip>
      )
      break
    case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
      methodElement = (
        <Tooltip overlay={<Translated keyEn="Adequacy" />}>
          <Avatar
            style={{
              color: "#9254de", // purple-5
              backgroundColor: "#efdbff", // purple-2
            }}
          >
            <Translated keyEn="ADQ" />
          </Avatar>
        </Tooltip>
      )
      break
  }
  if (site.is_lab_test) {
    methodElement = (
      <FlexRow
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {methodElement}{" "}
        <FontAwesomeIcon
          icon={faFlask}
          style={{
            color: "#f759ab", // magenta-5
          }}
        />
      </FlexRow>
    )
  }
  return (
    <FlexRow
      style={{
        gap: 5,
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      {normElement}
      {methodElement}
    </FlexRow>
  )
}
