import { FC } from "react"
import { Translated } from "../../utils/translated"
import { FlexCol } from "./common"
import dayjs from "dayjs"
import { Divider } from "antd"

export const EntityTimestamps: FC<{
  entity: {
    created_at: number
    updated_at: number
    deleted_at: number
  } | null
}> = ({ entity }) => {
  if (entity === null) {
    return null
  }
  let fmt = "YYYY-MM-DD HH:mm:ss"
  return (
    <FlexCol
      style={{
        width: "fit-content",
      }}
    >
      <Divider />
      <i>
        <Translated keyEn="Created at" />: {dayjs(entity.created_at).format(fmt)}
      </i>
      <i>
        <Translated keyEn="Last update" />: {dayjs(entity.updated_at).format(fmt)}
      </i>
      {entity.deleted_at !== 0 && (
        <i>
          <Translated keyEn="Deleted at" />: {dayjs(entity.deleted_at).format(fmt)}
        </i>
      )}
    </FlexCol>
  )
}
