import {
  CheckCircleFilled,
  DeleteOutlined,
  EyeOutlined,
  InfoCircleFilled,
  LockOutlined,
  ReloadOutlined,
  UnlockOutlined,
} from "@ant-design/icons"
import {
  Typography,
  Button,
  Row,
  Col,
  Select,
  Input,
  Tag,
  Table,
  Switch,
  message,
  Tooltip,
  Popconfirm,
} from "antd"
import { FC, ReactElement, useEffect, useMemo, useState } from "react"
import { FlexCol, UnderlinedSectionTitle, FlexRow } from "../../components/commons-ts/common"
import { Translated } from "../../utils/translated"
import {
  useUsercommNetworkGetPrimaryIPAddressesBLE,
  useUsercommWPAGetNetworksBLE,
  useUsercommWPAGetStatusBLE,
  useUsercommWPAReassociateBLE,
  useUsercommWPAScanResultsBLE,
  useUsercommWPASetNetworksBLE,
} from "../../usercomm/local/ble/usercommAsyncRequestBLE"
import { BaseOptionType } from "antd/es/select"
import {
  WPAGetNetworksResponse,
  WPANetworkConfig,
  WPAScanResult,
  WPASetNetworksRequest,
} from "../../generated/proto-ts/main"

import { JsonView, allExpanded, collapseAllNested, defaultStyles } from "react-json-view-lite"
import { ColumnsType } from "antd/es/table"

// Drag-n-Drop Kit
import type { DragEndEvent } from "@dnd-kit/core"
import { DndContext, MouseSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTowerBroadcast, faWifi } from "@fortawesome/free-solid-svg-icons"
import {
  CENTRAL_CONTAINER_GAP,
  COLOR_BG_GRAY,
  COLOR_BG_GREEN,
  COLOR_BG_RED,
} from "../../utils/utils"
import { Label } from "../../components/commons-ts/input"

interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string
}

const TableRow = (props: TableRowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props["data-row-key"],
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move",
    touchAction: "none",
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  }

  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />
}

const TypographyTextHidden: FC<{
  value: string
  onChange: (value: string) => void
}> = ({ value, onChange }) => {
  let [isEditing, setIsEditing] = useState(false)

  const hiddenValue = useMemo(() => {
    if (isEditing) {
      return value
    }
    return value.replace(/./g, "*")
  }, [value, isEditing])

  return (
    <Typography.Text
      inputMode="text"
      editable={{
        onChange,
        onStart: () => {
          setIsEditing(true)
        },
        onEnd: () => {
          setIsEditing(false)
        },
        onCancel: () => {
          setIsEditing(false)
        },
      }}
    >
      {hiddenValue}
    </Typography.Text>
  )
}

const WPAWknownNetworksTable: FC<{
  scanResults: WPAScanResult[] | null
  wpaNetworks: WPANetworkConfig[] | null
  setWpaNetworks: (wpaNetworks: WPANetworkConfig[] | null) => void
}> = ({ scanResults, wpaNetworks, setWpaNetworks }) => {
  const [dataSource, setDataSource] = useState<WPANetworkConfig[]>([])

  useEffect(() => {
    if (wpaNetworks === null) {
      setDataSource([])
      return
    }
    wpaNetworks.sort((a, b) => {
      if (a.priority === b.priority) {
        return a.id - b.id
      } else {
        return b.priority - a.priority
      }
    })
    setDataSource(wpaNetworks)
  }, [wpaNetworks])

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
        delay: 250,
        tolerance: 5,
      },
    }),
  )

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.ssid === active.id)
        const overIndex = prev.findIndex((i) => i.ssid === over?.id)
        let reorderedArray = [...arrayMove(prev, activeIndex, overIndex)]
        for (let i = 0; i < reorderedArray.length; i++) {
          reorderedArray[i].priority = 10 * (reorderedArray.length - i)
        }
        console.log(
          `WIFI: kwnown networks table: dnd onDragEnd`,
          reorderedArray.map((n) => n.toObject()),
        )
        setWpaNetworks(reorderedArray)
        return reorderedArray
      })
    }
  }

  const columns = useMemo((): ColumnsType<WPANetworkConfig> => {
    return [
      {
        title: "SSID",
        dataIndex: "ssid",
        key: "ssid",
        render: (ssid) => {
          return <b>{ssid}</b>
        },
      },
      {
        title: "Security",
        dataIndex: "key_mgmt",
        key: "key_mgmt",
        render: (key_mgmt, record) => {
          return (
            <Switch
              checked={key_mgmt !== "NONE"}
              checkedChildren={<LockOutlined />}
              unCheckedChildren={<UnlockOutlined />}
              onChange={(checked) => {
                let updatedWpaNetworks: WPANetworkConfig[] = []
                if (wpaNetworks === null) {
                  return
                }
                for (let net of wpaNetworks) {
                  let updatedNetwork = net.clone()
                  if (updatedNetwork.ssid === record.ssid) {
                    updatedNetwork.key_mgmt = checked ? "WPA-PSK" : "NONE"
                  }
                  updatedWpaNetworks.push(updatedNetwork)
                }
                console.log(
                  `WIFI: kwnown networks table: setting updatedWpaNetworks (key_mgmt)`,
                  updatedWpaNetworks.map((n) => n.toObject()),
                )
                setWpaNetworks(updatedWpaNetworks)
              }}
            />
          )
        },
      },
      {
        title: "PSK",
        dataIndex: "psk",
        key: "psk",
        render: (psk, record) => {
          if (record.key_mgmt === "NONE") {
            return null
          }
          return (
            <TypographyTextHidden
              value={psk}
              onChange={(value) => {
                let updatedWpaNetworks: WPANetworkConfig[] = []
                if (wpaNetworks === null) {
                  return
                }
                for (let net of wpaNetworks) {
                  let updatedNetwork = net.clone()
                  if (updatedNetwork.ssid === record.ssid) {
                    updatedNetwork.psk = value
                  }
                  updatedWpaNetworks.push(updatedNetwork)
                }
                console.log(
                  `WIFI: kwnown networks table: setting updatedWpaNetworks (psk)`,
                  updatedWpaNetworks.map((n) => n.toObject()),
                )
                setWpaNetworks(updatedWpaNetworks)
              }}
            />
          )
        },
      },
      {
        title: "Flags",
        dataIndex: "flags",
        key: "flags",
        render: (flags, record) => {
          if (flags === null) {
            return null
          }
          let flagsElements: ReactElement[] = []
          // Current
          if (flags.includes("[CURRENT]")) {
            flagsElements.push(
              <Tooltip key="currently_connected_flag" overlay="Currently connected">
                <Tag color="green">
                  <CheckCircleFilled />
                </Tag>
              </Tooltip>,
            )
          }

          // Priority
          let maxPriority = 0
          for (let net of dataSource) {
            if (net.priority > maxPriority) {
              maxPriority = net.priority
            }
          }
          if (record.priority === maxPriority) {
            flagsElements.push(
              <Tooltip key="highest_priority_flag" overlay="Highest priority">
                <Tag color="blue">
                  <InfoCircleFilled />
                </Tag>
              </Tooltip>,
            )
          }

          // In range
          if (scanResults !== null) {
            let scanResult = scanResults.find((sr) => sr.ssid === record.ssid)
            if (scanResult !== undefined) {
              flagsElements.push(
                <Tooltip key="in_range_flag" overlay="In range">
                  <Tag color="green">
                    <FontAwesomeIcon icon={faTowerBroadcast} />
                  </Tag>
                </Tooltip>,
              )
            }
          }
          return flagsElements
        },
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
      },
      {
        title: "Action",
        key: "action",
        render: (_, record) => {
          return (
            <Popconfirm
              title="Are you sure to delete this network?"
              onConfirm={() => {
                if (wpaNetworks === null) {
                  return
                }
                setWpaNetworks(wpaNetworks.filter((net) => net.ssid !== record.ssid))
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )
        },
      },
    ]
  }, [dataSource, scanResults])

  return (
    <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={dataSource.map((i) => i.ssid)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          loading={wpaNetworks === null}
          rowKey={(record) => record.ssid}
          pagination={false}
          components={{
            body: {
              row: TableRow,
            },
          }}
          scroll={{ x: true }}
          style={{ width: "100%" }}
        />
      </SortableContext>
    </DndContext>
  )
}

export const WifiSettingsNative: FC = () => {
  const [antdMessageCtx, antdMessageCtxHolder] = message.useMessage()
  const [wpaStatus, getWpaStatus] = useUsercommWPAGetStatusBLE()
  const [wpaScanResults, getWpaScanResults] = useUsercommWPAScanResultsBLE()

  const [wpaNetworks, getWpaNetworks] = useUsercommWPAGetNetworksBLE()
  const [wpaNetworksInternalState, setWpaNetworksInternalState] = useState<
    WPANetworkConfig[] | null
  >(null)

  const [wpaSetNetworksAck, setWpaSetNetworks] = useUsercommWPASetNetworksBLE()
  const [wpaReassociateAck, wpaReasscoiate] = useUsercommWPAReassociateBLE()

  const [networkPrimaryIPAddresses, getNetworkPrimaryIPAddresses] =
    useUsercommNetworkGetPrimaryIPAddressesBLE()

  const [isScanning, setIsScanning] = useState(false)

  // inputs
  const [inputPsk, setInputPsk] = useState<string | null>(null)
  const [selectedSsid, setSelectedSsid] = useState<string | null>(null)

  useEffect(() => {
    // Status
    getWpaStatus()
    // Known networks
    getWpaNetworks()
    // Network primary IP Addresses
    getNetworkPrimaryIPAddresses()

    // Last: scan stations (taked 3 seconds)
    setIsScanning(true)
    getWpaScanResults()
  }, [])

  useEffect(() => {
    if (wpaStatus === null) {
      return
    }
    console.log("WIFI: wpaStatus", wpaStatus.toObject())
  }, [wpaStatus])

  useEffect(() => {
    if (wpaScanResults === null) {
      return
    }
    setIsScanning(false)
    console.log("WIFI: wpaScanResults", wpaScanResults.toObject())
  }, [wpaScanResults])

  useEffect(() => {
    if (networkPrimaryIPAddresses === null) {
      return
    }
    console.log("WIFI: networkPrimaryIPAddresses", networkPrimaryIPAddresses.toObject())
  }, [networkPrimaryIPAddresses])

  useEffect(() => {
    if (wpaSetNetworksAck === null) {
      return
    }
    console.log(`WIFI: wpaSetNetworksAck`, wpaSetNetworksAck.toObject())
    antdMessageCtx.success("Successfully saved network configurations!")
    let t = setTimeout(() => {
      getWpaNetworks()
    }, 500)
    return () => clearTimeout(t)
  }, [wpaSetNetworksAck])

  useEffect(() => {
    if (wpaReassociateAck === null) {
      return
    }
    console.log(`WIFI: wpaReassociateAck`, wpaReassociateAck.toObject())
    antdMessageCtx.info("About to reconnect to the network!")
    let t1 = setTimeout(() => {
      getWpaNetworks()
      getWpaStatus()
      getNetworkPrimaryIPAddresses()
    }, 1000)
    let t2 = setTimeout(() => {
      getWpaStatus()
      getNetworkPrimaryIPAddresses()
    }, 5000)
    let t3 = setTimeout(() => {
      getWpaStatus()
      getNetworkPrimaryIPAddresses()
    }, 10000)
    return () => {
      clearTimeout(t1)
      clearTimeout(t2)
      clearTimeout(t3)
    }
  }, [wpaReassociateAck])

  useEffect(() => {
    if (wpaNetworks === null) {
      setWpaNetworksInternalState(null)
      return
    }
    console.log("WIFI: wpaNetworks", wpaNetworks.toObject())
    setWpaNetworksInternalState(wpaNetworks.networks.map((n) => n.clone()))
  }, [wpaNetworks])

  const memoCurrentlyConectedSsid = useMemo(() => {
    if (wpaStatus === null) {
      return null
    }
    return wpaStatus.ssid
  }, [wpaStatus])

  const memoStatusElement = useMemo(() => {
    if (wpaStatus === null) {
      return <Tag color="orange">N/A</Tag>
    }
    let color = "orange"
    let text = "Unknown"
    switch (wpaStatus.wpa_state) {
      case "COMPLETED":
        color = "green"
        text = "Connected"
        break
      case "DISCONNECTED":
        color = "red"
        text = "Disconnected"
        break
      case "INACTIVE":
        color = "red"
        text = "Inactive"
        break
      case "INTERFACE_DISABLED":
        color = "red"
        text = "Interface disabled"
        break
      case "SCANNING":
        color = "red"
        text = "Scanning"
        break
      case "ASSOCIATING":
        color = "orange"
        text = "Associating"
        break
    }
    return <Tag color={color}>{text}</Tag>
  }, [wpaStatus])

  const memoNetworkOptions = useMemo(() => {
    if (wpaScanResults === null) {
      return []
    }

    let scanResultsMap: Record<string, WPAScanResult[]> = {}
    for (let scanResult of wpaScanResults.scan_results) {
      let ssid = scanResult.ssid
      if (scanResultsMap[ssid] === undefined) {
        scanResultsMap[ssid] = []
      }
      scanResultsMap[ssid].push(scanResult)
    }

    let options: BaseOptionType[] = []
    let preOptions: {
      ssid: string
      strengthPercentage: number
      isOpen: boolean

      frequencyElement: ReactElement
      securityElement: ReactElement
      strengthElement: ReactElement
    }[] = []
    if (scanResultsMap === null) {
      return options
    }
    for (let [ssid, scanResults] of Object.entries(scanResultsMap)) {
      if (ssid.trim() === "") {
        continue
      }
      if (scanResults.length === 0) {
        continue
      }
      // Sort networks by frequency (5GHz first)
      scanResults.sort((a, b) => b.frequency - a.frequency)
      let network = scanResults[0]

      let frequencyElement = <Tag color="blue">2.4GHz</Tag>
      if (network.frequency > 5e3) {
        frequencyElement = <Tag color="green">5.5GHz</Tag>
      }

      let securityElement = (
        <Tag color="blue">
          <UnlockOutlined />
        </Tag>
      )
      if (network.flags.includes("WPA")) {
        securityElement = (
          <Tag color="green">
            <LockOutlined />
          </Tag>
        )
      }

      let inverseStrength = -network.signal_level
      let strengthPercentage = (100 * (inverseStrength - 30)) / (90 - 30)
      if (strengthPercentage < 0) {
        strengthPercentage = 100
      }
      let strengthColor = "green"
      if (strengthPercentage < 50) {
        strengthColor = "orange"
      }
      if (strengthPercentage < 25) {
        strengthColor = "red"
      }

      let strengthElement = <Tag color={strengthColor}>{strengthPercentage.toFixed(0)}%</Tag>

      preOptions.push({
        ssid,
        strengthPercentage,
        isOpen: !network.flags.includes("WPA"),
        frequencyElement,
        securityElement,
        strengthElement,
      })
    }

    preOptions.sort((a, b) => b.strengthPercentage - a.strengthPercentage)

    for (let preOption of preOptions) {
      let { ssid, frequencyElement, securityElement, strengthElement } = preOption
      options.push({
        value: ssid,
        label: (
          <FlexRow
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{ssid}</span>

            <FlexRow
              style={{
                gap: 3,
              }}
            >
              {frequencyElement}
              {strengthElement}
              {securityElement}
            </FlexRow>
          </FlexRow>
        ),
      })
    }
    return options
  }, [wpaScanResults])

  const memoSelectedNetwork = useMemo(() => {
    if (selectedSsid === null) {
      return null
    }
    if (wpaScanResults === null) {
      return null
    }
    let scanResults = wpaScanResults.scan_results
    for (let scanResult of scanResults) {
      if (scanResult.ssid === selectedSsid) {
        return scanResult
      }
    }
    return null
  }, [selectedSsid, wpaScanResults])

  const memoSelectedNetworkIsOpen = useMemo(() => {
    if (memoSelectedNetwork === null) {
      return false
    }
    return !memoSelectedNetwork.flags.includes("WPA")
  }, [memoSelectedNetwork])

  const memoNetworkPrimaryIPAddressesElement = useMemo(() => {
    if (networkPrimaryIPAddresses === null) {
      return <Tag color="orange">N/A</Tag>
    }
    let listElements: ReactElement[] = []
    for (let ipAddr of networkPrimaryIPAddresses.primary_ip_addresses) {
      let ipAddrStr = ipAddr.ip_address
      if (ipAddrStr === "" || ipAddrStr === " ") {
        ipAddrStr = "N/A"
      }
      listElements.push(
        <li key={ipAddr.if_name}>
          [{ipAddr.if_name.toUpperCase()}]: <b>{ipAddrStr}</b>
        </li>,
      )
    }
    return <ul>{listElements}</ul>
  }, [networkPrimaryIPAddresses])

  const memoWpaNetworksIsMutated = useMemo(() => {
    console.log(`WIFI: memoWpaNetworksIsMutated`, wpaNetworksInternalState, wpaNetworks)
    if (wpaNetworks === null || wpaNetworksInternalState === null) {
      return false
    }
    if (wpaNetworks.networks.length !== wpaNetworksInternalState.length) {
      return true
    }
    for (let wpaNetwork of wpaNetworks.networks) {
      for (let wpaNetworkInternal of wpaNetworksInternalState) {
        if (wpaNetwork.ssid === wpaNetworkInternal.ssid) {
          if (
            wpaNetwork.key_mgmt !== wpaNetworkInternal.key_mgmt ||
            wpaNetwork.psk !== wpaNetworkInternal.psk ||
            wpaNetwork.priority !== wpaNetworkInternal.priority
          ) {
            return true
          }
        }
      }
    }
    return false
  }, [wpaNetworks, wpaNetworksInternalState])

  const memoWpaStatusWidget = useMemo(() => {
    if (wpaStatus === null) {
      return null
    }

    let color = COLOR_BG_GRAY
    if (wpaStatus.wpa_state === "COMPLETED") {
      color = COLOR_BG_GREEN
    } else if (wpaStatus.wpa_state === "DISCONNECTED") {
      color = COLOR_BG_RED
    }
    let frequencyElement = null
    if (wpaStatus.freq > 2e3) {
      frequencyElement = <Tag color="blue">2.4GHz</Tag>
    }
    if (wpaStatus.freq > 5e3) {
      frequencyElement = <Tag color="green">5.5GHz</Tag>
    }
    let securityElement = null
    if (wpaStatus.key_mgmt.includes("WPA")) {
      securityElement = (
        <Tag color="green">
          <LockOutlined />
        </Tag>
      )
    } else if (wpaStatus.key_mgmt === "NONE") {
      securityElement = (
        <Tag color="blue">
          <UnlockOutlined />
        </Tag>
      )
    }

    let networkName = "N/C"
    if (wpaStatus.ssid !== "") {
      networkName = wpaStatus.ssid
    }

    return (
      <FlexCol
        style={{
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon icon={faWifi} fontSize={"7rem"} color={color} />
        <FlexCol
          style={{
            gap: 0,
            justifyContent: "center",
            alignItems: "center",

            fontSize: "1.5rem",
          }}
        >
          {networkName}
          <FlexRow
            style={{
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {frequencyElement}
            {securityElement}
          </FlexRow>
        </FlexCol>
      </FlexCol>
    )
  }, [wpaStatus])

  return (
    <>
      {antdMessageCtxHolder}
      <FlexCol
        style={{
          width: "100%",
          margin: "auto",
          gap: CENTRAL_CONTAINER_GAP,
          marginBottom: "2rem",
        }}
      >
        {/* Header */}
        <FlexCol style={{ gap: 0 }}>
          <Typography.Text
            style={{
              fontSize: "2rem",
            }}
          >
            <Translated keyEn="Wi-Fi" />
          </Typography.Text>
        </FlexCol>
        {/* Current STATUS */}
        <div>
          <FlexRow
            style={{
              alignItems: "top",
            }}
          >
            <UnderlinedSectionTitle>
              <Translated keyEn="Current status" />
            </UnderlinedSectionTitle>
            <Button
              onClick={async () => {
                getWpaStatus()
                getNetworkPrimaryIPAddresses()
              }}
              icon={<ReloadOutlined />}
            />
          </FlexRow>
          <FlexCol style={{ gap: 0 }}>
            <Row align={"middle"} justify={"center"} gutter={[10, 10]}>
              <Col xs={24} md={12}>
                <div>
                  <Translated keyEn="Status" />: {memoStatusElement}
                </div>
                <div>
                  <Translated keyEn="Primary IP address" />: {memoNetworkPrimaryIPAddressesElement}
                </div>
              </Col>
              <Col>{memoWpaStatusWidget}</Col>
            </Row>
          </FlexCol>
        </div>
        {/* Known networks */}
        <FlexRow>
          <UnderlinedSectionTitle>
            <Translated keyEn="Known networks" />
          </UnderlinedSectionTitle>
          <Button
            onClick={() => {
              getWpaNetworks()
            }}
            icon={<ReloadOutlined />}
          />
        </FlexRow>
        <FlexCol style={{ width: "100%", alignItems: "end" }}>
          <WPAWknownNetworksTable
            scanResults={wpaScanResults?.scan_results ?? null}
            wpaNetworks={wpaNetworksInternalState}
            setWpaNetworks={setWpaNetworksInternalState}
          />
          <FlexRow>
            <Button
              type="primary"
              disabled={memoWpaNetworksIsMutated === false}
              onClick={() => {
                let wpaSetNetworksRequest = new WPASetNetworksRequest({
                  networks: wpaNetworksInternalState ?? [],
                })
                setWpaSetNetworks(wpaSetNetworksRequest)
              }}
            >
              Save
            </Button>
            <Button
              type="primary"
              onClick={() => {
                wpaReasscoiate()
              }}
            >
              Reconnect
            </Button>
          </FlexRow>
        </FlexCol>
        {/* SSID & Password */}
        <div>
          <UnderlinedSectionTitle>
            <Translated keyEn="Connect to WiFi" />
          </UnderlinedSectionTitle>
          <Row gutter={[10, 10]} justify={"end"}>
            <Col xs={24}>
              <Label>
                <Translated keyEn="SSID" />
              </Label>
              <FlexRow>
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  options={memoNetworkOptions}
                  value={selectedSsid}
                  onChange={(value) => {
                    setSelectedSsid(value)
                  }}
                  size="large"
                />
                <Button
                  size="large"
                  type="primary"
                  loading={isScanning}
                  onClick={() => {
                    setIsScanning(true)
                    getWpaScanResults()
                  }}
                >
                  Scan
                </Button>
              </FlexRow>
            </Col>
            <Col flex={"auto"}>
              <Label>
                <Translated keyEn="Password" />
              </Label>
              <FlexRow>
                <Input.Password
                  value={inputPsk ?? ""}
                  onChange={(e) => {
                    setInputPsk(e.target.value)
                  }}
                  variant="filled"
                  autoComplete="one-time-code"
                  title={
                    memoSelectedNetworkIsOpen
                      ? "Open network does not require a password"
                      : undefined
                  }
                  disabled={memoSelectedNetworkIsOpen}
                />
                <Button
                  size="large"
                  type="primary"
                  disabled={selectedSsid === null}
                  onClick={() => {
                    if (selectedSsid === null || wpaNetworks === null) {
                      return
                    }
                    if (memoSelectedNetworkIsOpen && inputPsk === "") {
                      antdMessageCtx.error("Please enter the password!")
                      return
                    }
                    let newNetwork = new WPANetworkConfig({
                      ssid: selectedSsid,
                      key_mgmt: memoSelectedNetworkIsOpen ? "NONE" : "WPA-PSK",
                      psk: inputPsk ?? "",
                      priority: 999,
                    })
                    let wpaSetNetworksRequest = new WPASetNetworksRequest({
                      networks: wpaNetworks?.networks ?? [],
                    })
                    wpaSetNetworksRequest.networks.push(newNetwork)
                    setWpaSetNetworks(wpaSetNetworksRequest)
                    setTimeout(() => {
                      wpaReasscoiate()
                    }, 500)
                  }}
                >
                  <Translated keyEn="Connect" />
                </Button>
              </FlexRow>
            </Col>
          </Row>
        </div>
      </FlexCol>
    </>
  )
}
