import { FC, useCallback, useEffect, useMemo, useState } from "react"
import {
  CENTRAL_CONTAINER_GAP,
  CENTRAL_CONTAINER_MAX_WIDTH,
  COLOR_BG_GREEN,
  COLOR_BG_ORANGE,
  COLOR_BG_RED,
  floatToFixedOrNull,
  parseFloatOrZero,
  parsePathForEntityUUID,
  pbUUIDToUuid,
  uuidToPbUUID,
} from "../utils/utils"
import { useLocation, useHistory, Link } from "react-router-dom"
import { Button, Col, Empty, Input, Popconfirm, Row, Spin, Typography, message } from "antd"
import { FlexCol, FlexRow, UnderlinedSectionTitle } from "../components/commons-ts/common"
import { SimplifiedBreadcrumb } from "../components/commons-ts/simplifiedBreadcrumb"
import { Label, LabeledInput } from "../components/commons-ts/input"
import { Translated } from "../utils/translated"
import { normTypeElement, methodElement, isLabTestElement } from "../components/commons-ts/tags"
import {
  DeleteOutlined,
  DoubleRightOutlined,
  SaveOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons"
import { SliderButton } from "./Impact/SliderButton"

import { Impact, MethodTypeEN1177, NormType, UUID } from "../generated/proto-ts/main"
import { useUsercommSetImpactParentBLE } from "../usercomm/local/ble/usercommAsyncRequestBLE"
import { useUsercommContextBLE } from "../usercomm/local/ble/usercommProviderBLE"
import { ZoneChartPF_CFH } from "../components/commons-ts/uplotCharts"
import { UploadImageListV2 } from "../components/commons-ts/uploadImageV2"
import { DataTreeDrawer } from "../components/commons-ts/dataTreeDrawer"
import {
  ZoneImpactsTablePF_CFH,
  ZoneImpactsTablePF_ADQ,
} from "./Tables/03_ZoneImpacts/ZoneImpactsTable_PF"
import { ZoneImpactsTableSM } from "./Tables/03_ZoneImpacts/ZoneImpactsTable_SM"
import { ZoneImpactsTableTL } from "./Tables/03_ZoneImpacts/ZoneImpactsTable_TL"
import { ZoneImpactsTableAB } from "./Tables/03_ZoneImpacts/ZoneImpactsTable_AB"
import { getCFH_Global } from "../calculus/calculus_PF"
import {
  useUsercommDeleteImpactBimodal,
  useUsercommDeleteZoneBimodal,
  useUsercommEquipmentBimodal,
  useUsercommSiteBimodal,
  useUsercommUpdateZoneBimodal,
  useUsercommZoneBimodal,
  useUsercommZoneImpactsBimodal,
} from "../usercomm/common/usercommAsyncRequestBimodal"
import { useDebounce } from "../utils/debounce"
import dayjs from "dayjs"
import { useTranslationContext } from "../providers/translationProvider"
import { ZoneChartPF_ADQ } from "../components/commons-ts/simpleCharts"

export const ZonePage: FC = () => {
  const [antdMessageCtx] = message.useMessage()
  const { translated } = useTranslationContext()

  const location = useLocation()
  const history = useHistory()
  const memoZoneUUID = useMemo((): UUID | null => {
    let zoneUUID = parsePathForEntityUUID(location.pathname)
    if (zoneUUID === null) {
      return null
    }
    return uuidToPbUUID(zoneUUID)
  }, [location.pathname])

  // Equipment
  const [equipment, getEquipment] = useUsercommEquipmentBimodal()
  // Equipment fields
  const [equipmentUUIDStr, setEquipmentUUIDStr] = useState<string | null>(null) // use string to avoid effects re-firing on UUID ref change
  const [equipmentName, setEquipmentName] = useState<string | null>(null)

  // Ste
  const [site, getSite] = useUsercommSiteBimodal()
  // Site fields
  const [siteUUIDStr, setSiteUUIDStr] = useState<string | null>(null) // use string to avoid effects re-firing on UUID ref change
  const [siteName, setSiteName] = useState<string | null>(null)
  const [normType, setNormType] = useState<NormType | null>(null)
  const [method, setMethod] = useState<MethodTypeEN1177 | null>(null)
  const [isLabTest, setIsLabTest] = useState<boolean | null>(null)

  // Zone
  const [zone, getZone, externallySetZone] = useUsercommZoneBimodal()
  const [updatedZoneAck, updateZone] = useUsercommUpdateZoneBimodal()
  const [deletedZoneAck, deleteZoneSoft] = useUsercommDeleteZoneBimodal()
  // Zone fields
  const [zoneName, setZoneName] = useState<string | null>(null)
  const [pictures, setPictures] = useState<UUID[]>([])
  const [zoneComment, setZoneComment] = useState<string | null>(null)
  // Zone fields: numerical fields as strings
  const [zoneFreeFallHeightMetersStr, setZoneFreeFallHeightMetersStr] = useState<string | null>(
    null,
  )
  const [zoneGroundThicknessMillimetersStr, setZoneGroundThicknessMillimetersStr] = useState<
    string | null
  >(null)
  const [zoneTemperatureStr, setZoneTemperatureStr] = useState<string | null>(null)
  const [zoneHumidityStr, setZoneHumidityStr] = useState<string | null>(null)
  const [zoneSpecimenTemperatureStr, setZoneSpecimenTemperatureStr] = useState<string | null>(null)
  const [zoneSpecimenHumidityStr, setZoneSpecimenHumidityStr] = useState<string | null>(null)

  const [createdAt, setCreatedAt] = useState<number | null>(null)
  const [updatedAt, setUpdatedAt] = useState<number | null>(null)

  // Impacts
  const [impacts, getImpacts] = useUsercommZoneImpactsBimodal()
  const [setImpactParentAck, setImpactParent] = useUsercommSetImpactParentBLE()
  const [deletedImpactAck, deleteImpact] = useUsercommDeleteImpactBimodal()

  const [releaseResetTrigger, setReleaseResetTrigger] = useState<number>(0)
  const [isReleaseLoading, setIsReleaseLoading] = useState<boolean>(false)
  const [newImpactUUID, setNewImpactUUID] = useState<string | null>(null)

  const {
    bleIsConnected,
    stationSensors,
    hicRawMeasurementConsumable,

    emitDropHIC,
    consumeHICRawMeasurement,
  } = useUsercommContextBLE()

  // Fetch zone
  useEffect(() => {
    if (memoZoneUUID === null) {
      return
    }
    getZone(memoZoneUUID)
  }, [memoZoneUUID])

  useEffect(() => {
    if (zone === null) {
      return
    }
    console.log(`ZonePage: zone received:`, zone.toObject())

    setEquipmentUUIDStr(pbUUIDToUuid(zone.equipment_uuid))
    setZoneComment(zone.comments)
    setPictures(zone.pictures)
    setCreatedAt(zone.created_at)
    setUpdatedAt(zone.updated_at)
    setZoneName(zone.zone_name)

    setZoneFreeFallHeightMetersStr(floatToFixedOrNull(zone.zone_ffh_max / 100, 2))
    setZoneGroundThicknessMillimetersStr(floatToFixedOrNull(zone.floor_thickness, 0))
    setZoneTemperatureStr(floatToFixedOrNull(zone.zone_temperature, 0))
    setZoneHumidityStr(floatToFixedOrNull(zone.zone_humidity, 0))
    setZoneSpecimenTemperatureStr(floatToFixedOrNull(zone.specimen_temperature, 0))
    setZoneSpecimenHumidityStr(floatToFixedOrNull(zone.specimen_humidity, 0))
  }, [zone])

  // // Fetch equipment
  useEffect(() => {
    if (equipmentUUIDStr === null) {
      return
    }
    getEquipment(uuidToPbUUID(equipmentUUIDStr))
  }, [equipmentUUIDStr])

  useEffect(() => {
    if (equipment === null) {
      return
    }
    console.log(`ZonePage: equipment received:`, equipment.toObject())
    setSiteUUIDStr(pbUUIDToUuid(equipment.site_uuid))
    setEquipmentName(equipment.equipment_name)
  }, [equipment])

  // Fetch site
  useEffect(() => {
    if (siteUUIDStr == null) {
      return
    }
    getSite(uuidToPbUUID(siteUUIDStr))
  }, [siteUUIDStr])

  useEffect(() => {
    if (site === null) {
      return
    }
    console.log(`ZonePage: site received:`, site.toObject())
    setSiteName(site.site_name)
    setNormType(site.norm_type)
    setMethod(site.method_type_en_1177)
    setIsLabTest(site.is_lab_test)
  }, [site])

  useEffect(() => {
    if (normType === NormType.EN_ISO_23659) {
      setZoneFreeFallHeightMetersStr("1")
    }
  }, [normType])

  // Fetch impacts
  useEffect(() => {
    if (memoZoneUUID === null) {
      return
    }
    getImpacts(memoZoneUUID)
  }, [memoZoneUUID])

  // Impact reception
  useEffect(() => {
    if (hicRawMeasurementConsumable === null) {
      return
    }
    consumeHICRawMeasurement()
    if (memoZoneUUID === null) {
      return
    }
    setImpactParent(hicRawMeasurementConsumable, memoZoneUUID, Date.now())
    setNewImpactUUID(pbUUIDToUuid(hicRawMeasurementConsumable))
  }, [memoZoneUUID, hicRawMeasurementConsumable])

  useEffect(() => {
    if (setImpactParentAck === null || newImpactUUID === null) {
      return
    }
    antdMessageCtx.success(translated("Impact received!"))
    setIsReleaseLoading(false)
    setReleaseResetTrigger(Date.now())
    setTimeout(() => {
      history.push(`/impacts/${newImpactUUID}`)
    }, 1000)
  }, [newImpactUUID, setImpactParentAck])

  // CRUD

  const __zoneMutableFields = [
    memoZoneUUID,
    zone,
    zoneName,
    zoneFreeFallHeightMetersStr,
    zoneGroundThicknessMillimetersStr,
    pictures,
    zoneComment,
    zoneTemperatureStr,
    zoneHumidityStr,
    zoneSpecimenTemperatureStr,
    zoneSpecimenHumidityStr,
  ]

  const memoZoneIsMutated = useMemo(() => {
    if (zone === null) {
      return false
    }
    let prevPicturesStr = zone.pictures.map((p) => pbUUIDToUuid(p)).join(",")
    let currPicturesStr = pictures.map((p) => pbUUIDToUuid(p)).join(",")

    let prevFreeFallHeightMetersStr = floatToFixedOrNull(zone.zone_ffh_max / 100, 2) // m
    let prevGroundThicknessMillimetersStr = floatToFixedOrNull(zone.floor_thickness, 0) // mm
    let prevZoneTemperatureStr = floatToFixedOrNull(zone.zone_temperature, 0) // °C
    let prevZoneHumidityStr = floatToFixedOrNull(zone.zone_humidity, 0) // %
    let prevZoneSpecimenTemperatureStr = floatToFixedOrNull(zone.specimen_temperature, 0) // °C
    let prevZoneSpecimenHumidityStr = floatToFixedOrNull(zone.specimen_humidity, 0) // %

    let isMutated =
      zoneName !== zone.zone_name ||
      zoneComment !== zone.comments ||
      zoneFreeFallHeightMetersStr !== prevFreeFallHeightMetersStr ||
      zoneGroundThicknessMillimetersStr !== prevGroundThicknessMillimetersStr ||
      zoneTemperatureStr !== prevZoneTemperatureStr ||
      zoneHumidityStr !== prevZoneHumidityStr ||
      zoneSpecimenTemperatureStr !== prevZoneSpecimenTemperatureStr ||
      zoneSpecimenHumidityStr !== prevZoneSpecimenHumidityStr ||
      currPicturesStr !== prevPicturesStr
    if (isMutated) {
      console.debug(`ZonePage: isMutated: ${isMutated}
            zoneName: "${zoneName}" vs "${zone.zone_name}"
            zoneComment: "${zoneComment}" vs "${zone.comments}"
            zoneFreeFallHeightMetersStr: "${zoneFreeFallHeightMetersStr}" vs "${prevFreeFallHeightMetersStr}"
            zoneGroundThicknessMillimetersStr: "${zoneGroundThicknessMillimetersStr}" vs "${prevGroundThicknessMillimetersStr}"
            zoneTemperature: "${zoneTemperatureStr}" vs "${prevZoneTemperatureStr}"
            zoneHumidity: "${zoneHumidityStr}" vs "${prevZoneHumidityStr}"
            zoneSpecimenTemperature: "${zoneSpecimenTemperatureStr}" vs "${prevZoneSpecimenTemperatureStr}"
            zoneSpecimenHumidity: "${zoneSpecimenHumidityStr}" vs "${prevZoneSpecimenHumidityStr}"
            pictures: "${currPicturesStr}" vs "${prevPicturesStr}"`)
    }
    return isMutated
  }, __zoneMutableFields)

  // DELETE ZONE
  const onDelete = useCallback(async () => {
    if (zone === null) {
      return
    }
    deleteZoneSoft(zone.uuid)
  }, [zone])
  // DELETE ZONE ACK
  useEffect(() => {
    if (deletedZoneAck === null || zone === null) {
      return
    }
    antdMessageCtx.info(translated("Zone deleted!"))
    if (equipmentUUIDStr !== null) {
      setTimeout(() => {
        history.push(`/equipments/${equipmentUUIDStr}`)
      }, 1000)
    } else {
      console.warn(`ZonePage: received deletedZoneAck but equipmentUUID is null!`)
    }
  }, [deletedZoneAck, equipmentUUIDStr])

  // UPDATE ZONE
  const onUpdate = useDebounce(async () => {
    if (zone === null) {
      return
    }
    let updatedZone = zone.clone()

    updatedZone.zone_name = zoneName ?? ""
    updatedZone.comments = zoneComment ?? ""
    updatedZone.zone_ffh_max = Math.floor(parseFloatOrZero(zoneFreeFallHeightMetersStr) * 100)
    updatedZone.floor_thickness = Math.floor(parseFloatOrZero(zoneGroundThicknessMillimetersStr))
    updatedZone.zone_temperature = parseFloatOrZero(zoneTemperatureStr)
    updatedZone.zone_humidity = parseFloatOrZero(zoneHumidityStr)
    updatedZone.specimen_temperature = parseFloatOrZero(zoneSpecimenTemperatureStr)
    updatedZone.specimen_humidity = parseFloatOrZero(zoneSpecimenHumidityStr)
    updatedZone.pictures = pictures
    updatedZone.updated_at = Date.now()

    updateZone(updatedZone)
    externallySetZone(updatedZone)
  })
  // UPDATE ZONE ACK
  useEffect(() => {
    if (updatedZoneAck === null || memoZoneUUID === null) {
      return
    }
    console.log(`ZonePage: updatedZoneAck:`, updatedZoneAck)
  }, [updatedZoneAck, memoZoneUUID])

  // TRIGGER UPDATE
  useEffect(() => {
    console.log(`ZonePage: memoZoneIsMutated:`, memoZoneIsMutated)
    if (memoZoneIsMutated === false) {
      return
    }
    onUpdate()
  }, [memoZoneIsMutated])

  // DELETE IMPACT
  const onDeleteImpact = useCallback(async (impact: Impact | null) => {
    if (impact === null) {
      return
    }
    deleteImpact(impact.uuid)
  }, [])
  // DELETE IMPACT ACK
  useEffect(() => {
    if (deletedImpactAck === null || memoZoneUUID === null) {
      return
    }
    antdMessageCtx.info(translated("Impact deleted!"))
    getImpacts(memoZoneUUID)
  }, [deletedImpactAck, memoZoneUUID])

  useEffect(() => {
    if (impacts === null) {
      return
    }
    console.log(
      `ZonePage: impacts received:`,
      impacts.map((i) => i.toObject()),
    )
  }, [impacts])

  const memoNormTypeElement = useMemo(() => {
    return normTypeElement(normType)
  }, [normType])

  const memoMethodElement = useMemo(() => {
    if (normType === null || normType === NormType.EN_12503) {
      return null
    }
    return methodElement(method)
  }, [normType, method])

  const memoIsLabTestElement = useMemo(() => {
    return isLabTestElement(isLabTest)
  }, [isLabTest])

  const memoImpactsTable = useMemo(() => {
    if (normType === null) {
      return null
    }
    if (normType === NormType.EN_12503) {
      return (
        <ZoneImpactsTableSM
          impacts={impacts}
          equipment={equipment}
          currentImpactUUID={newImpactUUID}
          onDeleteImpact={onDeleteImpact}
        />
      )
    } else if (normType === NormType.EN_14960) {
      return (
        <ZoneImpactsTableAB
          impacts={impacts}
          currentImpactUUID={newImpactUUID}
          zone={zone}
          onDeleteImpact={onDeleteImpact}
        />
      )
    } else if (normType === NormType.EN_ISO_23659) {
      let impactorWeight: number | null = null
      if (equipment !== null) {
        impactorWeight = equipment.trampoline_impactor_weight
      }
      if (impactorWeight === null || impactorWeight === 0) {
        impactorWeight = 6.25
      }
      return (
        <ZoneImpactsTableTL
          impacts={impacts}
          equipmentImpactorWeight={impactorWeight}
          currentImpactUUID={newImpactUUID}
          onDeleteImpact={onDeleteImpact}
        />
      )
    } else if (normType === NormType.EN_1177) {
      if (method === MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION) {
        return (
          <ZoneImpactsTablePF_CFH
            impacts={impacts}
            currentImpactUUID={newImpactUUID}
            zone={zone}
            onDeleteImpact={onDeleteImpact}
          />
        )
      } else if (method === MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE) {
        return (
          <ZoneImpactsTablePF_ADQ
            impacts={impacts}
            currentImpactUUID={newImpactUUID}
            onDeleteImpact={onDeleteImpact}
          />
        )
      }
    }
  }, [normType, method, equipment, zone, zoneFreeFallHeightMetersStr, impacts])

  const memoZoneFreeFallHeight = useMemo((): number | null => {
    return parseFloatOrZero(zoneFreeFallHeightMetersStr)
  }, [zoneFreeFallHeightMetersStr])

  const memoCalculatedCriticalFallHeight = useMemo((): number | null => {
    if (impacts === null || method !== MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION) {
      return null
    }
    let cfhMeanStd = getCFH_Global(impacts)
    if (cfhMeanStd === null) {
      return null
    }
    return cfhMeanStd.mean // meters
  }, [method, impacts])

  const memoCalculatedCriticalFallHeightStr = useMemo((): string | null => {
    if (memoCalculatedCriticalFallHeight === null) {
      return null
    }
    return floatToFixedOrNull(memoCalculatedCriticalFallHeight, 2)
  }, [memoCalculatedCriticalFallHeight])

  const onReleaseCB = useCallback(() => {
    if (!bleIsConnected) {
      antdMessageCtx.warning(translated("No connection to the station, please double check!"))
      return
    }
    emitDropHIC()
    setIsReleaseLoading(true)
    setTimeout(() => {
      setIsReleaseLoading(false)
      setReleaseResetTrigger(Date.now())
    }, 10e3)
  }, [bleIsConnected])

  const memoImpactsChart = useMemo(() => {
    if (impacts === null || normType === null || method === null || zone == null) {
      return
    }
    if (normType === NormType.EN_1177) {
      if (impacts.length === 0) {
        return (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<Translated keyEn="No impacts" />}
          />
        )
      }
      if (method === MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION) {
        let color = COLOR_BG_ORANGE
        if (memoCalculatedCriticalFallHeight !== null && memoZoneFreeFallHeight !== null) {
          if (memoCalculatedCriticalFallHeight > memoZoneFreeFallHeight * 1.03) {
            color = COLOR_BG_GREEN
          } else if (memoCalculatedCriticalFallHeight < memoZoneFreeFallHeight * 0.97) {
            color = COLOR_BG_RED
          }
        }
        return (
          <div>
            <Label>
              <Translated keyEn="Critical Fall Height" />
            </Label>
            <FlexCol
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "400px",
                marginBottom: "3rem",
              }}
            >
              <span
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  color,
                }}
              >
                <Translated keyEn="CFH" /> = {memoCalculatedCriticalFallHeightStr}m /{" "}
                {zoneFreeFallHeightMetersStr}m
              </span>
              <ZoneChartPF_CFH zone={zone} impacts={impacts} />
            </FlexCol>
          </div>
        )
      } else if (method === MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE) {
        return (
          <div>
            <Label>
              <Translated keyEn="Impact Attenuation" />
            </Label>
            <FlexRow
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "400px",
              }}
            >
              <div>
                <ZoneChartPF_ADQ zone={zone} impacts={impacts} impact_key="impact_gmax" />
              </div>
              <div>
                <ZoneChartPF_ADQ zone={zone} impacts={impacts} impact_key="impact_hic" />
              </div>
            </FlexRow>
          </div>
        )
      }
    }
  }, [normType, method, impacts, zone])

  if (zone === null) {
    return (
      <FlexCol
        style={{
          width: "100%",
          height: "60vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </FlexCol>
    )
  }

  return (
    <>
      <FlexCol
        style={{
          width: "100%",
          margin: "auto",
          maxWidth: CENTRAL_CONTAINER_MAX_WIDTH,
          gap: CENTRAL_CONTAINER_GAP,
        }}
      >
        <SimplifiedBreadcrumb
          previousItems={[
            {
              href: `/sites/${siteUUIDStr}`,
              label: siteName,
            },
            {
              href: `/equipments/${equipmentUUIDStr}`,
              label: equipmentName,
            },
          ]}
          currentItem={{ label: zoneName }}
        />
        <Row
          justify="space-between"
          style={{
            marginTop: "1rem",
          }}
        >
          <Col>
            <FlexCol style={{ gap: 0 }}>
              <Label>
                <Translated keyEn="Zone" />
              </Label>
              <Typography.Text
                style={{
                  fontSize: "2rem",
                }}
              >
                {zoneName}
              </Typography.Text>
            </FlexCol>
          </Col>
          <Col>
            <FlexCol style={{ gap: 3 }}>
              {memoNormTypeElement}
              {memoMethodElement}
              {memoIsLabTestElement}
            </FlexCol>
          </Col>
        </Row>
        {/* Zone name */}
        <LabeledInput translatableLabel={"Name"} value={zoneName} setValue={setZoneName} />
        {/* Impacts Chart */}
        {memoImpactsChart}
        {/* Zone impacts */}
        <div>
          <Label>
            <Translated keyEn="Impacts" />
          </Label>
          {memoImpactsTable}
        </div>
        {/* New impact slider button */}
        <div>
          <SliderButton
            onReleaseHIC={onReleaseCB}
            isLoading={isReleaseLoading}
            resetTrigger={releaseResetTrigger}
          />
          {newImpactUUID !== null && (
            <FlexRow
              style={{
                justifyContent: "end",
                marginRight: "0.25rem",
              }}
            >
              <Link to={`/impacts/${newImpactUUID}`}>
                <Button size="large" type="primary">
                  <Translated keyEn="Go to the new impact" /> <DoubleRightOutlined />
                </Button>
              </Link>
            </FlexRow>
          )}
        </div>
        {/* Zone picture */}
        <Label>
          <Translated keyEn="Picture" />
        </Label>
        <UploadImageListV2 pictures={pictures} setPictures={setPictures} />
        <div>
          <UnderlinedSectionTitle>
            <Translated keyEn="Environment" />
          </UnderlinedSectionTitle>
          <Row
            gutter={[10, 10]}
            style={{
              marginTop: 20,
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <Col xs={24} md={12}>
              <FlexCol style={{ gap: 0 }}>
                <Label>
                  <Translated keyEn="Temperature" />
                </Label>
                <Input
                  size="large"
                  value={zoneTemperatureStr ?? ""}
                  onChange={(value) => {
                    setZoneTemperatureStr(value.target.value)
                  }}
                  variant="filled"
                  addonAfter="°C"
                  style={{
                    width: "100%",
                  }}
                />
              </FlexCol>
            </Col>
            <Col xs={24} md={12}>
              <FlexCol style={{ gap: 0 }}>
                <Label>
                  <Translated keyEn="Humidity" />
                </Label>
                <Input
                  size="large"
                  value={zoneHumidityStr ?? ""}
                  onChange={(value) => {
                    setZoneHumidityStr(value.target.value)
                  }}
                  variant="filled"
                  addonAfter="%"
                  style={{
                    width: "100%",
                  }}
                />
              </FlexCol>
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                disabled={stationSensors === null}
                onClick={() => {
                  if (stationSensors === null) {
                    return
                  }
                  setZoneTemperatureStr(Math.round(stationSensors.temperature).toString())
                  setZoneHumidityStr(Math.round(stationSensors.relative_humidity).toString())
                }}
                icon={<VerticalAlignBottomOutlined />}
              >
                Auto
              </Button>
            </Col>
          </Row>
        </div>
        {/* Zone data */}
        <div>
          <UnderlinedSectionTitle>
            <Translated keyEn="Zone parameters" />
          </UnderlinedSectionTitle>
          <Row
            gutter={[10, 10]}
            style={{
              marginTop: 20,
            }}
          >
            <Col xs={24} md={12}>
              <FlexCol style={{ gap: 0 }}>
                <Label>
                  <Translated keyEn={"Free Fall Height"} />
                </Label>
                <Input
                  size="large"
                  value={zoneFreeFallHeightMetersStr ?? ""}
                  onChange={(value) => {
                    setZoneFreeFallHeightMetersStr(value.target.value ?? null)
                  }}
                  variant="filled"
                  addonAfter="m"
                  disabled={normType === NormType.EN_ISO_23659}
                  style={{
                    width: "100%",
                  }}
                />
              </FlexCol>
            </Col>
            {normType === NormType.EN_1177 && (
              <>
                <Col xs={24} md={12}>
                  <FlexCol style={{ gap: 0 }}>
                    <Label>
                      <Translated keyEn={"Ground thickness"} />
                    </Label>
                    <Input
                      size="large"
                      value={zoneGroundThicknessMillimetersStr ?? ""}
                      onChange={(value) => {
                        setZoneGroundThicknessMillimetersStr(value.target.value ?? null)
                      }}
                      type="number"
                      variant="filled"
                      addonAfter="mm"
                      style={{
                        width: "100%",
                      }}
                    />
                  </FlexCol>
                </Col>
                <Col xs={24} md={12}>
                  <FlexCol style={{ gap: 0 }}>
                    <Label>
                      <Translated keyEn={"Specimen temperature"} />
                    </Label>
                    <Input
                      size="large"
                      value={zoneSpecimenTemperatureStr ?? ""}
                      onChange={(value) => {
                        setZoneSpecimenTemperatureStr(value.target.value ?? null)
                      }}
                      style={{
                        width: "100%",
                      }}
                      variant="filled"
                      addonAfter="°C"
                    />
                  </FlexCol>
                </Col>
                <Col xs={24} md={12}>
                  <FlexCol style={{ gap: 0 }}>
                    <Label>
                      <Translated keyEn={"Specimen humidity"} />
                    </Label>
                    <Input
                      size="large"
                      value={zoneSpecimenHumidityStr ?? ""}
                      onChange={(value) => {
                        setZoneSpecimenHumidityStr(value.target.value ?? null)
                      }}
                      style={{
                        width: "100%",
                      }}
                      variant="filled"
                      addonAfter="%"
                    />
                  </FlexCol>
                </Col>
              </>
            )}
            <Col flex={"auto"}>
              <LabeledInput
                translatableLabel={"Comments"}
                value={zoneComment}
                setValue={setZoneComment}
              />
            </Col>
          </Row>
        </div>
        <FlexRow
          style={{
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          <Popconfirm
            title={
              <>
                <Translated keyEn="Are you sure you want to delete this site" />?
              </>
            }
            onConfirm={onDelete}
          >
            <Button type="link" danger icon={<DeleteOutlined />} size="small">
              <span
                style={{
                  textTransform: "uppercase",
                  fontSize: "0.8rem",
                }}
              >
                <Translated keyEn="Delete" />
              </span>
            </Button>
          </Popconfirm>
          <div
            style={{
              width: 200,
            }}
          >
            <Button
              type="primary"
              icon={<SaveOutlined />}
              size="large"
              block
              onClick={onUpdate}
              disabled={!memoZoneIsMutated}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                <Translated keyEn="Save" />
              </span>
            </Button>
          </div>
        </FlexRow>
      </FlexCol>
      <FlexCol>
        <i>
          <Translated keyEn="Created at" />: {dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss")}
        </i>
        <i>
          <Translated keyEn="Last update" />: {dayjs(updatedAt).format("YYYY-MM-DD HH:mm:ss")}
        </i>
      </FlexCol>
      <DataTreeDrawer site={site} selectedKey={memoZoneUUID ? pbUUIDToUuid(memoZoneUUID) : null} />
    </>
  )
}
