import React, { ReactElement } from "react"
import { MeanWithStd } from "../types"

export const renderMeanStd = (meanStdValue: MeanWithStd, minCount: number): ReactElement => {
  return (
    <span>
      <b>{meanStdValue.mean.toFixed(0)}</b>&nbsp;±&nbsp;
      {meanStdValue.std.toFixed(0)}
      {/* &nbsp; */}
      {/* {meanStdValue.count && `[${meanStdValue.count}]`} */}
      {/* {meanStdValue.count && meanStdValue.count < minCount && (
                <Tooltip
                    overlay={
                        <span>
                            <Translated keyEn="Not enough impacts to compute a reliable mean and standard deviation" />
                            &nbsp;({"< "}
                            {minCount})
                        </span>
                    }
                >
                    <WarningFilled
                        style={{
                            color: "orange",
                        }}
                    />
                </Tooltip>
            )} */}
    </span>
  )
}
