export type Point = [number, number]

const mean = (a: number[]): number | null => {
  if (a.length) {
    return a.reduce((acc, cur) => acc + cur, 0) / a.length
  } else {
    return null
  }
}

// Golden Section definition
const phi = (1 + Math.sqrt(5)) / 2
const resphi = 2 - phi

/*
Get the Sum of Squares of an array
*/
const sumsq = (a: number[]) => {
  return a.map((e) => Math.pow(e, 2)).reduce((acc, cur) => acc + cur, 0)
}

/*
Get the Sum of Squares of differences between the real points and a fitting of the form y = ax^2 + 0*b + 0*c
*/
const fittingScore = (points: Point[], a: number, b: number = 0, c: number = 0) => {
  const f = (x: number, a: number, b: number, c: number) => {
    return a * Math.pow(x, 2) + b * x + c
  }
  return sumsq(points.map(([x, y]) => y - f(x, a, b, c)))
}

/*
Perform the Golden Section Search of a minimum of function `func` located somewhere between `a` and `b`
Start the search at point `c`
Recursively iterate until the search window (a - b) is less than `absolutePresicion`
*/
const goldenSectionSearch = (
  func: (x: number) => number,
  a: number,
  c: number,
  b: number,
  absolutePrecision: number = 1e-2,
): number => {
  if (Math.abs(a - b) < absolutePrecision) {
    return (a + b) / 2
  }
  // Create a new possible center, in the area between c and b, pushed against c
  let d = c + resphi * (b - c)
  if (func(d) < func(c)) {
    return goldenSectionSearch(func, c, d, b, absolutePrecision)
  } else {
    return goldenSectionSearch(func, d, c, a, absolutePrecision)
  }
}

const findParabolicA = (points: Point[], c: number = 0) => {
  var a
  if (points.length === 0) {
    return {
      equation: [NaN, NaN, NaN],
      points,
    }
  } else if (points.length === 1) {
    let [x, y] = points[0]
    a = (y - c) / Math.pow(x, 2)
  } else {
    let As = points.map(([x, y]) => (y - c) / Math.pow(x, 2))
    let lowBorder = Math.min(...As)
    let highBorder = Math.max(...As)

    let middle = (highBorder - lowBorder) / 2

    const coreFunc = (a: number) => fittingScore(points, a, 0, c)
    a = goldenSectionSearch(coreFunc, lowBorder, middle, highBorder)
  }
  return {
    equation: [a, 0, 0],
    points,
  }
}

/*
Search for a & c parameters of quadratic regression by firstly finding the optimal `c` and the the optimal `a` once the c is found.
Optimal c is found by taking b=0 and approximation of a, taking a > 0
*/
const findParabolicAandC = (
  points: Point[],
): {
  equation: [number, number, number]
  points: Point[]
} => {
  var a
  var c
  if (points.length === 0) {
    return {
      equation: [NaN, NaN, NaN],
      points,
    }
  } else if (points.length === 1) {
    let [x, y] = points[0]
    a = y / Math.pow(x, 2)
    c = 0
  } else {
    const coreFunc = (c: number) => {
      let As = points.map(([x, y]) => (y - c) / Math.pow(x, 2))
      let meanAs = mean(As)
      if (meanAs === null) {
        return Infinity
      }
      let approxA = Math.max(meanAs, 0) // mean of a's is a good approximmation; a > 0
      return fittingScore(points, approxA, 0, c)
    }

    let Y = points.map(([x, y]) => y)
    let lowBorder = 0
    let highBorder = Math.min(...Y) // Search for optimal c from 0 to the lowest point
    let middle = highBorder / 2
    c = goldenSectionSearch(coreFunc, lowBorder, middle, highBorder)
    let resultA = findParabolicA(points, c)
    a = resultA.equation[0]
  }
  return {
    equation: [a, 0, c],
    points,
  }
}

export { findParabolicA, findParabolicAandC }
