import { useCallback, useEffect, useState } from "react"
import {
  useCloudUsercommAnnexWS,
  useCloudUsercommCreateAnnexWS,
  useCloudUsercommCreateEquipmentWS,
  useCloudUsercommCreateSiteWS,
  useCloudUsercommCreateZoneWS,
  useCloudUsercommDeleteAnnexWS,
  useCloudUsercommDeleteEquipmentWS,
  useCloudUsercommDeleteImpactWS,
  useCloudUsercommDeleteSiteWS,
  useCloudUsercommDeleteZoneWS,
  useCloudUsercommEquipmentChildrenRecursiveWS,
  useCloudUsercommEquipmentWS,
  useCloudUsercommEquipmentZonesWS,
  useCloudUsercommImpactWS,
  useCloudUsercommAllImpactsWS,
  useCloudUsercommSiteAnnexesWS,
  useCloudUsercommSiteChildrenRecursiveWS,
  useCloudUsercommSiteEquipmentsWS,
  useCloudUsercommSiteWS,
  useCloudUsercommSitesWS,
  useCloudUsercommStatsWS,
  useCloudUsercommUpdateAnnexWS,
  useCloudUsercommUpdateEquipmentWS,
  useCloudUsercommUpdateImpactWS,
  useCloudUsercommUpdateSiteWS,
  useCloudUsercommUpdateZoneWS,
  useCloudUsercommZoneImpactsWS,
  useCloudUsercommZoneWS,
  useCloudUsercommSyntheticImpactWithEmbeddedReferencesWS,
} from "../cloud/cloudUsercommAsyncRequestWS"
import {
  useUsercommAnnexBLE,
  useUsercommAsyncRequestBLE,
  useUsercommCreateAnnexBLE,
  useUsercommCreateEquipmentBLE,
  useUsercommCreateSiteBLE,
  useUsercommCreateZoneBLE,
  useUsercommDeleteAnnexBLE,
  useUsercommDeleteEquipmentBLE,
  useUsercommDeleteImpactBLE,
  useUsercommDeleteSiteBLE,
  useUsercommDeleteZoneBLE,
  useUsercommEquipmentBLE,
  useUsercommEquipmentChildrenRecursiveBLE,
  useUsercommEquipmentZonesBLE,
  useUsercommImpactBLE,
  useUsercommAllImpactsBLE,
  useUsercommSiteAnnexesBLE,
  useUsercommSiteBLE,
  useUsercommSiteChildrenRecursiveBLE,
  useUsercommSiteEquipmentsBLE,
  useUsercommSitesBLE,
  useUsercommStatsBLE,
  useUsercommUpdateAnnexBLE,
  useUsercommUpdateEquipmentBLE,
  useUsercommUpdateImpactBLE,
  useUsercommUpdateSiteBLE,
  useUsercommUpdateZoneBLE,
  useUsercommZoneBLE,
  useUsercommZoneImpactsBLE,
  useUsercommSyntheticImpactWithEmbeddedReferencesBLE,
} from "../local/ble/usercommAsyncRequestBLE"
import {
  SyntheticImpactWithEmbeddedReferences,
  useUsercommSyntheticImpactWithEmbeddedReferencesGen,
} from "./usercommAsyncRequestGeneric"
import {
  Annex,
  Equipment,
  Impact,
  ImpactWithReferences,
  Site,
  StorageStats,
  UCAck,
  UUID,
  Zone,
} from "../../generated/proto-ts/main"
import { pbUUIDToUuid } from "../../utils/utils"
import { useUsercommContextBLE } from "../local/ble/usercommProviderBLE"

// STATS
export const useUsercommStatsBimodal = (): [
  StorageStats | null,
  (parentUUID: UUID | undefined) => void,
] => {
  const [statsLocal, getStatsLocal] = useUsercommStatsBLE()
  const [statsRemote, getStatsRemote] = useCloudUsercommStatsWS()
  const [statsEffective, setStatsEffective] = useState<StorageStats | null>(null)

  useEffect(() => {
    // Local stats take precedence
    if (statsLocal !== null) {
      setStatsEffective(statsLocal)
      return
    }
    if (statsRemote !== null) {
      setStatsEffective(statsRemote)
      return
    }
    setStatsEffective(null)
  }, [statsLocal, statsRemote])

  useEffect(() => {
    if (statsRemote !== null) {
      console.log("UC: BIMODAL: remote stats", statsRemote.toObject())
    }
  }, [statsRemote])

  useEffect(() => {
    if (statsLocal !== null) {
      console.log("UC: BIMODAL: local stats", statsLocal.toObject())
    }
  }, [statsLocal])

  const getStats = useCallback(
    async (parentUUID: UUID | undefined) => {
      getStatsLocal(parentUUID)
      getStatsRemote(parentUUID)
    },
    [getStatsLocal, getStatsRemote],
  )

  return [statsEffective, getStats]
}

// SITES
export const useUsercommSitesBimodal = (): [Site[] | null, () => void] => {
  const [sitesLocal, getSitesLocal] = useUsercommSitesBLE()
  const [sitesRemote, getSitesRemote] = useCloudUsercommSitesWS()
  const [sitesEffective, setSitesEffective] = useState<Site[] | null>(null)

  useEffect(() => {
    console.log(
      `UC: BIMODAL: sites: sitesLocal/sitesRemote`,
      sitesLocal?.map((s) => s.toObject()),
      sitesRemote?.map((s) => s.toObject()),
    )
    if (sitesLocal !== null && sitesRemote === null) {
      setSitesEffective(sitesLocal)
      return
    }
    if (sitesRemote !== null && sitesLocal === null) {
      setSitesEffective(sitesRemote)
      return
    }
    if (sitesRemote !== null && sitesLocal !== null) {
      // Merge sites
      let mergedSitesMap: Record<string, Site> = {}
      for (let siteLocal of sitesLocal) {
        mergedSitesMap[pbUUIDToUuid(siteLocal.uuid)] = siteLocal
      }
      for (let siteRemote of sitesRemote) {
        let uuidStr = pbUUIDToUuid(siteRemote.uuid)
        let siteLocal = mergedSitesMap[uuidStr]
        if (siteLocal === undefined) {
          mergedSitesMap[uuidStr] = siteRemote
        } else {
          // Use most recently updated site
          if (siteRemote.updated_at > siteLocal.updated_at) {
            mergedSitesMap[uuidStr] = siteRemote
          } else if (siteRemote.updated_at < siteLocal.updated_at) {
            mergedSitesMap[uuidStr] = siteLocal
          } else {
            mergedSitesMap[uuidStr] = siteLocal
          }
        }
      }
      let sitesMerged = Object.values(mergedSitesMap)
      setSitesEffective(sitesMerged)
      return
    }
    setSitesEffective(null)
  }, [sitesLocal, sitesRemote])

  const getSites = useCallback(async () => {
    getSitesLocal()
    getSitesRemote()
  }, [getSitesLocal, getSitesRemote])

  return [sitesEffective, getSites]
}

export const useUsercommSiteBimodal = (): [
  Site | null,
  (siteUUID: UUID) => void,
  React.Dispatch<React.SetStateAction<Site | null>>,
] => {
  const [siteLocal, getSiteLocal] = useUsercommSiteBLE()
  const [siteRemote, getSiteRemote] = useCloudUsercommSiteWS()
  const [siteEffective, setSiteEffective] = useState<Site | null>(null)

  const [siteLocalUpdatedAck, updateSiteLocal] = useUsercommUpdateSiteBLE()
  const [siteRemoteUpdatedAck, updateSiteRemote] = useCloudUsercommUpdateSiteWS()

  useEffect(() => {
    console.log(
      "UC: BIMODAL: useSite: siteLocal/siteRemote",
      siteLocal?.toObject(),
      siteRemote?.toObject(),
    )
    if (siteLocal !== null && siteRemote === null) {
      setSiteEffective(siteLocal)
      return
    }
    if (siteRemote !== null && siteLocal === null) {
      setSiteEffective(siteRemote)
      return
    }
    if (siteRemote !== null && siteLocal !== null) {
      // Use most recently updated site
      if (siteRemote.updated_at > siteLocal.updated_at) {
        setSiteEffective(siteRemote)
        // Update local site
        let dtMs = siteRemote.updated_at - siteLocal.updated_at
        console.log(
          `UC: BIMODAL: useSite: updating local site: remote version is newer: ${dtMs} ms`,
        )
        updateSiteLocal(siteRemote)
      } else if (siteRemote.updated_at < siteLocal.updated_at) {
        setSiteEffective(siteLocal)
        // Update remote site
        let dtMs = siteLocal.updated_at - siteRemote.updated_at
        console.log(
          `UC: BIMODAL: useSite: updating remote site: local version is newer: ${dtMs} ms`,
        )
        updateSiteRemote(siteLocal)
      } else {
        setSiteEffective(siteLocal)
      }
      return
    }
  }, [siteLocal, siteRemote])

  useEffect(() => {
    if (siteLocalUpdatedAck === null) {
      return
    }
    console.log(`UC: BIMODAL: useSite: local site updated`, siteLocalUpdatedAck.toObject())
  }, [siteLocalUpdatedAck])

  useEffect(() => {
    if (siteRemoteUpdatedAck === null) {
      return
    }
    console.log(`UC: BIMODAL: useSite: remote site updated`, siteRemoteUpdatedAck.toObject())
  }, [siteRemoteUpdatedAck])

  const getSite = useCallback(
    async (siteUUID: UUID) => {
      getSiteRemote(siteUUID)
      getSiteLocal(siteUUID)
    },
    [getSiteLocal, getSiteRemote],
  )

  return [siteEffective, getSite, setSiteEffective]
}

export const useUsercommCreateSiteBimodal = (): [
  Site["uuid"] | null,
  (userUUID: string, site: Site) => void,
] => {
  const [siteLocalUUID, createSiteLocal] = useUsercommCreateSiteBLE()
  const [siteRemoteUUID, createSiteRemote] = useCloudUsercommCreateSiteWS()
  const [siteEffectiveUUID, setSiteEffective] = useState<Site["uuid"] | null>(null)

  useEffect(() => {
    console.log(
      `UC: BIMODAL: createSite: siteLocal/siteRemote`,
      siteLocalUUID?.toObject(),
      siteRemoteUUID?.toObject,
    )
    if (siteLocalUUID !== null) {
      setSiteEffective(siteLocalUUID)
      return
    }
    if (siteRemoteUUID !== null) {
      setSiteEffective(siteRemoteUUID)
      return
    }
    setSiteEffective(null)
  }, [siteLocalUUID, siteRemoteUUID])

  const createSite = useCallback(
    (userUUID: string, site: Site) => {
      createSiteLocal(userUUID, site)
      createSiteRemote(userUUID, site)
    },
    [createSiteLocal, createSiteRemote],
  )

  return [siteEffectiveUUID, createSite]
}

export const useUsercommUpdateSiteBimodal = (): [UCAck | null, (site: Site) => void] => {
  const [siteLocalUpdatedAck, updateSiteLocal] = useUsercommUpdateSiteBLE()
  const [siteRemoteUpdatedAck, updateSiteRemote] = useCloudUsercommUpdateSiteWS()
  const [siteEffectiveUpdatedAck, setSiteEffectiveUpdatedAck] = useState<UCAck | null>(null)

  useEffect(() => {
    console.log(
      `UC: BIMODAL: updateSite: siteLocal/siteRemote`,
      siteLocalUpdatedAck?.toObject(),
      siteRemoteUpdatedAck?.toObject(),
    )
    if (siteLocalUpdatedAck) {
      setSiteEffectiveUpdatedAck(siteLocalUpdatedAck)
    }
    if (siteRemoteUpdatedAck) {
      setSiteEffectiveUpdatedAck(siteRemoteUpdatedAck)
    }
  }, [siteLocalUpdatedAck, siteRemoteUpdatedAck])

  const updateSite = useCallback(
    (site: Site) => {
      updateSiteRemote(site)
      updateSiteLocal(site)
    },
    [updateSiteLocal, updateSiteRemote],
  )

  return [siteEffectiveUpdatedAck, updateSite]
}

export const useUsercommDeleteSiteBimodal = (): [UCAck | null, (siteUUID: UUID) => void] => {
  const [siteLocalDeletedAck, deleteSiteLocal] = useUsercommDeleteSiteBLE()
  const [siteRemoteDeletedAck, deleteSiteRemote] = useCloudUsercommDeleteSiteWS()
  const [siteEffectiveDeletedAck, setSiteEffectiveDeletedAck] = useState<UCAck | null>(null)

  useEffect(() => {
    console.log(
      `UC: BIMODAL: deleteSite: siteLocal/siteRemote`,
      siteLocalDeletedAck?.toObject(),
      siteRemoteDeletedAck?.toObject(),
    )
    if (siteLocalDeletedAck) {
      setSiteEffectiveDeletedAck(siteLocalDeletedAck)
    }
    if (siteRemoteDeletedAck) {
      setSiteEffectiveDeletedAck(siteRemoteDeletedAck)
    }
  }, [siteLocalDeletedAck, siteRemoteDeletedAck])

  const deleteSite = useCallback(
    (siteUUID: UUID) => {
      deleteSiteLocal(siteUUID)
      deleteSiteRemote(siteUUID)
    },
    [deleteSiteLocal, deleteSiteRemote],
  )

  return [siteEffectiveDeletedAck, deleteSite]
}

// ANNEXES
export const useUsercommSiteAnnexesBimodal = (): [
  Annex[] | null,
  (siteUUID: UUID | null) => void,
] => {
  const [annexesLocal, getAnnexesLocal] = useUsercommSiteAnnexesBLE()
  const [annexesRemote, getAnnexesRemote] = useCloudUsercommSiteAnnexesWS()
  const [annexesEffective, setAnnexesEffective] = useState<Annex[] | null>(null)

  useEffect(() => {
    if (annexesLocal !== null && annexesRemote === null) {
      setAnnexesEffective(annexesLocal)
      return
    }
    if (annexesRemote !== null && annexesLocal === null) {
      setAnnexesEffective(annexesRemote)
      return
    }
    if (annexesRemote !== null && annexesLocal !== null) {
      // Merge annexes
      let mergedAnnexesMap: Record<string, Annex> = {}
      for (let annexLocal of annexesLocal) {
        mergedAnnexesMap[pbUUIDToUuid(annexLocal.uuid)] = annexLocal
      }
      for (let annexRemote of annexesRemote) {
        let uuidStr = pbUUIDToUuid(annexRemote.uuid)
        let annexLocal = mergedAnnexesMap[uuidStr]
        if (annexLocal === undefined) {
          // Add remote annex (even if it was deleted)
          mergedAnnexesMap[uuidStr] = annexRemote
        } else {
          // Use most recently updated annex
          if (annexRemote.updated_at > annexLocal.updated_at) {
            mergedAnnexesMap[uuidStr] = annexRemote
          } else {
            mergedAnnexesMap[uuidStr] = annexLocal
          }
        }
      }
      let annexesMerged = Object.values(mergedAnnexesMap)
      setAnnexesEffective(annexesMerged)
      return
    }
    setAnnexesEffective(null)
  }, [annexesLocal, annexesRemote])

  const getAnnexes = useCallback(
    async (siteUUID: UUID | null) => {
      getAnnexesLocal(siteUUID)
      getAnnexesRemote(siteUUID)
    },
    [getAnnexesLocal, getAnnexesRemote],
  )

  return [annexesEffective, getAnnexes]
}

export const useUsercommAnnexBimodal = (): [Annex | null, (annexUUID: string) => void] => {
  const [annexLocal, getAnnexLocal] = useUsercommAnnexBLE()
  const [annexRemote, getAnnexRemote] = useCloudUsercommAnnexWS()
  const [annexEffective, setAnnexEffective] = useState<Annex | null>(null)

  const [annexLocalUpdatedAck, updateAnnexLocal] = useUsercommUpdateAnnexBLE()
  const [annexRemoteUpdatedAck, updateAnnexRemote] = useCloudUsercommUpdateAnnexWS()

  useEffect(() => {
    console.log(
      `UC: BIMODAL: annexLocal/annexRemote`,
      annexLocal?.toObject(),
      annexRemote?.toObject(),
    )
    if (annexLocal !== null && annexRemote === null) {
      setAnnexEffective(annexLocal)
      return
    }
    if (annexRemote !== null && annexLocal === null) {
      setAnnexEffective(annexRemote)
      return
    }
    if (annexRemote !== null && annexLocal !== null) {
      // Use most recently updated annex
      if (annexRemote.updated_at > annexLocal.updated_at) {
        setAnnexEffective(annexRemote)
        // Update local annex
        let dtMs = annexRemote.updated_at - annexLocal.updated_at
        console.log(`UC: BIMODAL: updating local annex: remote version is newer: ${dtMs} ms`)
        updateAnnexLocal(annexRemote)
      } else if (annexRemote.updated_at < annexLocal.updated_at) {
        setAnnexEffective(annexLocal)
        // Update remote annex
        let dtMs = annexLocal.updated_at - annexRemote.updated_at
        console.log(`UC: BIMODAL: updating remote annex: local version is newer: ${dtMs} ms`)
        updateAnnexRemote(annexLocal)
      } else {
        setAnnexEffective(annexLocal)
      }
      return
    }
  }, [annexLocal, annexRemote])

  useEffect(() => {
    if (annexLocalUpdatedAck === null) {
      return
    }
    console.log(`UC: BIMODAL: local annex updated`, annexLocalUpdatedAck.toObject())
  }, [annexLocalUpdatedAck])

  useEffect(() => {
    if (annexRemoteUpdatedAck === null) {
      return
    }
    console.log(`UC: BIMODAL: remote annex updated`, annexRemoteUpdatedAck.toObject())
  }, [annexRemoteUpdatedAck])

  const getAnnex = useCallback(
    async (annexUUID: string) => {
      getAnnexLocal(annexUUID)
      getAnnexRemote(annexUUID)
    },
    [getAnnexLocal, getAnnexRemote],
  )

  return [annexEffective, getAnnex]
}

export const useUsercommCreateAnnexBimodal = (): [
  Annex["uuid"] | null,
  (siteUUID: UUID, annex: Annex) => void,
] => {
  const [annexLocalUUID, createAnnexLocal] = useUsercommCreateAnnexBLE()
  const [annexRemoteUUID, createAnnexRemote] = useCloudUsercommCreateAnnexWS()
  const [annexEffectiveUUID, setAnnexEffective] = useState<Annex["uuid"] | null>(null)

  useEffect(() => {
    console.log(
      `UC: BIMODAL: createAnnex: annexLocal/annexRemote`,
      annexLocalUUID?.toObject(),
      annexRemoteUUID?.toObject(),
    )
    if (annexLocalUUID !== null) {
      setAnnexEffective(annexLocalUUID)
      return
    }
    if (annexRemoteUUID !== null) {
      setAnnexEffective(annexRemoteUUID)
      return
    }
    setAnnexEffective(null)
  }, [annexLocalUUID, annexRemoteUUID])

  const createAnnex = useCallback(
    (siteUUID: UUID, annex: Annex) => {
      createAnnexLocal(siteUUID, annex)
      createAnnexRemote(siteUUID, annex)
    },
    [createAnnexLocal, createAnnexRemote],
  )

  return [annexEffectiveUUID, createAnnex]
}

export const useUsercommUpdateAnnexBimodal = (): [UCAck | null, (annex: Annex) => void] => {
  const [annexLocalUpdatedAck, updateAnnexLocal] = useUsercommUpdateAnnexBLE()
  const [annexRemoteUpdatedAck, updateAnnexRemote] = useCloudUsercommUpdateAnnexWS()
  const [annexEffectiveUpdatedAck, setAnnexEffectiveUpdatedAck] = useState<UCAck | null>(null)

  useEffect(() => {
    if (annexLocalUpdatedAck) {
      setAnnexEffectiveUpdatedAck(annexLocalUpdatedAck)
    }
    if (annexRemoteUpdatedAck) {
      setAnnexEffectiveUpdatedAck(annexRemoteUpdatedAck)
    }
  }, [annexLocalUpdatedAck, annexRemoteUpdatedAck])

  const updateAnnex = useCallback(
    (annex: Annex) => {
      updateAnnexLocal(annex)
      updateAnnexRemote(annex)
    },
    [updateAnnexLocal, updateAnnexRemote],
  )

  return [annexEffectiveUpdatedAck, updateAnnex]
}

export const useUsercommDeleteAnnexBimodal = (): [UCAck | null, (annexUUID: UUID) => void] => {
  const [annexLocalDeletedAck, deleteAnnexLocal] = useUsercommDeleteAnnexBLE()
  const [annexRemoteDeletedAck, deleteAnnexRemote] = useCloudUsercommDeleteAnnexWS()
  const [annexEffectiveDeletedAck, setAnnexEffectiveDeletedAck] = useState<UCAck | null>(null)

  useEffect(() => {
    if (annexLocalDeletedAck) {
      setAnnexEffectiveDeletedAck(annexLocalDeletedAck)
    }
    if (annexRemoteDeletedAck) {
      setAnnexEffectiveDeletedAck(annexRemoteDeletedAck)
    }
  }, [annexLocalDeletedAck, annexRemoteDeletedAck])

  const deleteAnnex = useCallback(
    (annexUUID: UUID) => {
      deleteAnnexLocal(annexUUID)
      deleteAnnexRemote(annexUUID)
    },
    [deleteAnnexLocal, deleteAnnexRemote],
  )

  return [annexEffectiveDeletedAck, deleteAnnex]
}

// RECURSIVE SITE CHILDREN
export const useUsercommSiteChildrenRecursiveBimodal = (): [
  Record<string, Equipment[]> | null,
  Record<string, Zone[]> | null,
  Record<string, Impact[]> | null,
  (siteUUID: UUID | null) => void,
] => {
  const [equipmentsLocal, zonesLocal, impactsLocal, getSiteChildrenRecursiveLocal] =
    useUsercommSiteChildrenRecursiveBLE()
  const [equipmentsRemote, zonesRemote, impactsRemote, getSiteChildrenRecursiveRemote] =
    useCloudUsercommSiteChildrenRecursiveWS()

  const [equipmentsEffective, setEquipmentsEffective] = useState<Record<
    string,
    Equipment[]
  > | null>(null)
  const [zonesEffective, setZonesEffective] = useState<Record<string, Zone[]> | null>(null)
  const [impactsEffective, setImpactsEffective] = useState<Record<string, Impact[]> | null>(null)

  useEffect(() => {
    // console.log(
    //     `UC: BIMODAL: siteChildren: equipmentsLocal/equipmentsRemote`,
    //     equipmentsLocal,
    //     equipmentsRemote,
    // )
    if (equipmentsLocal !== null && equipmentsRemote === null) {
      setEquipmentsEffective(equipmentsLocal)
      return
    } else if (equipmentsRemote !== null && equipmentsLocal === null) {
      setEquipmentsEffective(equipmentsRemote)
      return
    } else if (equipmentsRemote !== null && equipmentsLocal !== null) {
      // Merge equipments
      let mergedEquipmentsMap: Record<string, Equipment[]> = {}
      for (let siteUUIDStr in equipmentsLocal) {
        let equipmentsLocalSite = equipmentsLocal[siteUUIDStr]
        let equipmentsRemoteSite = equipmentsRemote[siteUUIDStr]
        if (equipmentsRemoteSite === undefined) {
          mergedEquipmentsMap[siteUUIDStr] = equipmentsLocalSite
          continue
        }
        let mergedEquipments: Record<string, Equipment> = {}
        for (let equipmentLocal of equipmentsLocalSite) {
          mergedEquipments[pbUUIDToUuid(equipmentLocal.uuid)] = equipmentLocal
        }
        for (let equipmentRemote of equipmentsRemoteSite) {
          let uuidStr = pbUUIDToUuid(equipmentRemote.uuid)
          let equipmentLocal = mergedEquipments[uuidStr]
          if (equipmentLocal === undefined) {
            mergedEquipments[uuidStr] = equipmentRemote
          } else {
            // Use most recently updated equipment
            if (equipmentRemote.updated_at > equipmentLocal.updated_at) {
              mergedEquipments[uuidStr] = equipmentRemote
            } else if (equipmentRemote.updated_at < equipmentLocal.updated_at) {
              mergedEquipments[uuidStr] = equipmentLocal
            } else {
              mergedEquipments[uuidStr] = equipmentLocal
            }
          }
        }
        let equipmentsMerged = Object.values(mergedEquipments)
        mergedEquipmentsMap[siteUUIDStr] = equipmentsMerged
      }
      setEquipmentsEffective(mergedEquipmentsMap)
      return
    }
  }, [equipmentsLocal, equipmentsRemote])

  useEffect(() => {
    // console.log(
    //     `UC: BIMODAL: siteChildren: zonesLocal/zonesRemote`,
    //     zonesLocal,
    //     zonesRemote,
    // )
    if (zonesLocal !== null && zonesRemote === null) {
      setZonesEffective(zonesLocal)
      return
    } else if (zonesRemote !== null && zonesLocal === null) {
      setZonesEffective(zonesRemote)
      return
    } else if (zonesRemote !== null && zonesLocal !== null) {
      // Merge zones
      let mergedZonesMap: Record<string, Zone[]> = {}
      for (let siteUUIDStr in zonesLocal) {
        let zonesLocalSite = zonesLocal[siteUUIDStr]
        let zonesRemoteSite = zonesRemote[siteUUIDStr]
        if (zonesRemoteSite === undefined) {
          mergedZonesMap[siteUUIDStr] = zonesLocalSite
          continue
        }
        let mergedZones: Record<string, Zone> = {}
        for (let zoneLocal of zonesLocalSite) {
          mergedZones[pbUUIDToUuid(zoneLocal.uuid)] = zoneLocal
        }
        for (let zoneRemote of zonesRemoteSite) {
          let uuidStr = pbUUIDToUuid(zoneRemote.uuid)
          let zoneLocal = mergedZones[uuidStr]
          if (zoneLocal === undefined) {
            mergedZones[uuidStr] = zoneRemote
          } else {
            // Use most recently updated zone
            if (zoneRemote.updated_at > zoneLocal.updated_at) {
              mergedZones[uuidStr] = zoneRemote
            } else if (zoneRemote.updated_at < zoneLocal.updated_at) {
              mergedZones[uuidStr] = zoneLocal
            } else {
              mergedZones[uuidStr] = zoneLocal
            }
          }
        }
        let zonesMerged = Object.values(mergedZones)
        mergedZonesMap[siteUUIDStr] = zonesMerged
      }
      setZonesEffective(mergedZonesMap)
      return
    }
  }, [zonesLocal, zonesRemote])

  useEffect(() => {
    // console.log(
    //     `UC: BIMODAL: siteChildren: impactsLocal/impactsRemote`,
    //     impactsLocal,
    //     impactsRemote,
    // )
    if (impactsLocal !== null && impactsRemote === null) {
      setImpactsEffective(impactsLocal)
      return
    } else if (impactsRemote !== null && impactsLocal === null) {
      setImpactsEffective(impactsRemote)
      return
    } else if (impactsRemote !== null && impactsLocal !== null) {
      // Merge impacts
      let mergedImpactsMap: Record<string, Impact[]> = {}
      for (let siteUUIDStr in impactsLocal) {
        let impactsLocalSite = impactsLocal[siteUUIDStr]
        let impactsRemoteSite = impactsRemote[siteUUIDStr]
        if (impactsRemoteSite === undefined) {
          mergedImpactsMap[siteUUIDStr] = impactsLocalSite
          continue
        }
        let mergedImpacts: Record<string, Impact> = {}
        for (let impactLocal of impactsLocalSite) {
          mergedImpacts[pbUUIDToUuid(impactLocal.uuid)] = impactLocal
        }
        for (let impactRemote of impactsRemoteSite) {
          let uuidStr = pbUUIDToUuid(impactRemote.uuid)
          let impactLocal = mergedImpacts[uuidStr]
          if (impactLocal === undefined) {
            mergedImpacts[uuidStr] = impactRemote
          } else {
            // Use most recently updated impact
            if (impactRemote.updated_at > impactLocal.updated_at) {
              mergedImpacts[uuidStr] = impactRemote
            } else if (impactRemote.updated_at < impactLocal.updated_at) {
              mergedImpacts[uuidStr] = impactLocal
            } else {
              mergedImpacts[uuidStr] = impactLocal
            }
          }
        }
        let impactsMerged = Object.values(mergedImpacts)
        mergedImpactsMap[siteUUIDStr] = impactsMerged
      }
      setImpactsEffective(mergedImpactsMap)
      return
    }
  }, [impactsLocal, impactsRemote])

  const requestEntitiesRecursive = useCallback(
    async (siteUUID: UUID | null) => {
      getSiteChildrenRecursiveLocal(siteUUID)
      getSiteChildrenRecursiveRemote(siteUUID)
    },
    [getSiteChildrenRecursiveLocal, getSiteChildrenRecursiveRemote],
  )

  return [equipmentsEffective, zonesEffective, impactsEffective, requestEntitiesRecursive]
}

// EQUIPMENTS
export const useUsercommSiteEquipmentsBimodal = (): [
  Equipment[] | null,
  (siteUUID: UUID) => void,
] => {
  const [equipmentsLocal, getEquipmentsLocal] = useUsercommSiteEquipmentsBLE()
  const [equipmentsRemote, getEquipmentsRemote] = useCloudUsercommSiteEquipmentsWS()
  const [equipmentsEffective, setEquipmentsEffective] = useState<Equipment[] | null>(null)

  useEffect(() => {
    console.log(
      `UC: BIMODAL: equipments: equipmentsLocal/equipmentsRemote`,
      equipmentsLocal?.map((e) => e.toObject()),
      equipmentsRemote?.map((e) => e.toObject()),
    )
    if (equipmentsLocal !== null && equipmentsRemote === null) {
      setEquipmentsEffective(equipmentsLocal)
      return
    }
    if (equipmentsRemote !== null && equipmentsLocal === null) {
      setEquipmentsEffective(equipmentsRemote)
      return
    }
    if (equipmentsRemote !== null && equipmentsLocal !== null) {
      // Merge equipments
      let mergedEquipmentsMap: Record<string, Equipment> = {}
      for (let equipmentLocal of equipmentsLocal) {
        mergedEquipmentsMap[pbUUIDToUuid(equipmentLocal.uuid)] = equipmentLocal
      }
      for (let equipmentRemote of equipmentsRemote) {
        let uuidStr = pbUUIDToUuid(equipmentRemote.uuid)
        let equipmentLocal = mergedEquipmentsMap[uuidStr]
        if (equipmentLocal === undefined) {
          mergedEquipmentsMap[uuidStr] = equipmentRemote
        } else {
          // Use most recently updated equipment
          if (equipmentRemote.updated_at > equipmentLocal.updated_at) {
            mergedEquipmentsMap[uuidStr] = equipmentRemote
          } else {
            mergedEquipmentsMap[uuidStr] = equipmentLocal
          }
        }
      }
      let equipmentsMerged = Object.values(mergedEquipmentsMap)
      setEquipmentsEffective(equipmentsMerged)
      return
    }
  }, [equipmentsLocal, equipmentsRemote])

  const getEquipments = useCallback(
    async (siteUUID: UUID) => {
      getEquipmentsLocal(siteUUID)
      getEquipmentsRemote(siteUUID)
    },
    [getEquipmentsLocal, getEquipmentsRemote],
  )

  return [equipmentsEffective, getEquipments]
}

export const useUsercommEquipmentBimodal = (): [
  Equipment | null,
  (equipmentUUID: UUID) => void,
  React.Dispatch<React.SetStateAction<Equipment | null>>, // external setter
] => {
  const [equipmentLocal, getEquipmentLocal] = useUsercommEquipmentBLE()
  const [equipmentRemote, getEquipmentRemote] = useCloudUsercommEquipmentWS()
  const [equipmentEffective, setEquipmentEffective] = useState<Equipment | null>(null)

  const [equipmentLocalUpdatedAck, updateEquipmentLocal] = useUsercommUpdateEquipmentBLE()
  const [equipmentRemoteUpdatedAck, updateEquipmentRemote] = useCloudUsercommUpdateEquipmentWS()

  useEffect(() => {
    console.log(
      `UC: BIMODAL: equipment: equipmentLocal/equipmentRemote`,
      equipmentLocal?.toObject(),
      equipmentRemote?.toObject(),
    )
    if (equipmentLocal !== null && equipmentRemote === null) {
      setEquipmentEffective(equipmentLocal)
      return
    } else if (equipmentRemote !== null && equipmentLocal === null) {
      setEquipmentEffective(equipmentRemote)
      return
    } else if (equipmentRemote !== null && equipmentLocal !== null) {
      // Use most recently updated equipment
      if (equipmentRemote.updated_at > equipmentLocal.updated_at) {
        setEquipmentEffective(equipmentRemote)
        // Update local equipment
        let dtMs = equipmentRemote.updated_at - equipmentLocal.updated_at
        console.log(`UC: BIMODAL: updating local equipment: remote version is newer: ${dtMs} ms`)
        updateEquipmentLocal(equipmentRemote)
      } else if (equipmentRemote.updated_at < equipmentLocal.updated_at) {
        setEquipmentEffective(equipmentLocal)
        // Update remote equipment
        let dtMs = equipmentLocal.updated_at - equipmentRemote.updated_at
        console.log(`UC: BIMODAL: updating remote equipment: local version is newer: ${dtMs} ms`)
        updateEquipmentRemote(equipmentLocal)
      } else {
        setEquipmentEffective(equipmentLocal)
      }
      return
    }
  }, [equipmentLocal, equipmentRemote])

  const getEquipment = useCallback(
    async (equipmentUUID: UUID) => {
      getEquipmentLocal(equipmentUUID)
      getEquipmentRemote(equipmentUUID)
    },
    [getEquipmentLocal, getEquipmentRemote],
  )

  return [equipmentEffective, getEquipment, setEquipmentEffective]
}

export const useUsercommCreateEquipmentBimodal = (): [
  Equipment["uuid"] | null,
  (siteUUID: UUID, equipment: Equipment) => void,
] => {
  const [equipmentLocalUUID, createEquipmentLocal] = useUsercommCreateEquipmentBLE()
  const [equipmentRemoteUUID, createEquipmentRemote] = useCloudUsercommCreateEquipmentWS()
  const [equipmentEffectiveUUID, setEquipmentEffective] = useState<Equipment["uuid"] | null>(null)

  useEffect(() => {
    if (equipmentLocalUUID !== null) {
      setEquipmentEffective(equipmentLocalUUID)
      return
    }
    if (equipmentRemoteUUID !== null) {
      setEquipmentEffective(equipmentRemoteUUID)
      return
    }
    setEquipmentEffective(null)
  }, [equipmentLocalUUID, equipmentRemoteUUID])

  useEffect(() => {
    if (equipmentRemoteUUID !== null) {
      console.log(
        "UC: BIMODAL: createEquipmpent: remote equipment created",
        equipmentRemoteUUID.toObject(),
      )
    }
  }, [equipmentRemoteUUID])

  useEffect(() => {
    if (equipmentLocalUUID !== null) {
      console.log(
        "UC: BIMODAL: createEquipmpent: local equipment created",
        equipmentLocalUUID.toObject(),
      )
    }
  }, [equipmentLocalUUID])

  const createEquipment = useCallback(
    (siteUUID: UUID, equipment: Equipment) => {
      createEquipmentLocal(siteUUID, equipment)
      createEquipmentRemote(siteUUID, equipment)
    },
    [createEquipmentLocal, createEquipmentRemote],
  )

  return [equipmentEffectiveUUID, createEquipment]
}

export const useUsercommUpdateEquipmentBimodal = (): [
  UCAck | null,
  (equipment: Equipment) => void,
] => {
  const [equipmentLocalUpdatedAck, updateEquipmentLocal] = useUsercommUpdateEquipmentBLE()
  const [equipmentRemoteUpdatedAck, updateEquipmentRemote] = useCloudUsercommUpdateEquipmentWS()
  const [equipmentEffectiveUpdatedAck, setEquipmentEffectiveUpdatedAck] = useState<UCAck | null>(
    null,
  )

  useEffect(() => {
    if (equipmentLocalUpdatedAck) {
      setEquipmentEffectiveUpdatedAck(equipmentLocalUpdatedAck)
    }
    if (equipmentRemoteUpdatedAck) {
      setEquipmentEffectiveUpdatedAck(equipmentRemoteUpdatedAck)
    }
  }, [equipmentLocalUpdatedAck, equipmentRemoteUpdatedAck])

  const updateEquipment = useCallback(
    (equipment: Equipment) => {
      updateEquipmentLocal(equipment)
      updateEquipmentRemote(equipment)
    },
    [updateEquipmentLocal, updateEquipmentRemote],
  )

  return [equipmentEffectiveUpdatedAck, updateEquipment]
}

export const useUsercommDeleteEquipmentBimodal = (): [
  UCAck | null,
  (equipmentUUID: UUID) => void,
] => {
  const [equipmentLocalDeletedAck, deleteEquipmentLocal] = useUsercommDeleteEquipmentBLE()
  const [equipmentRemoteDeletedAck, deleteEquipmentRemote] = useCloudUsercommDeleteEquipmentWS()
  const [equipmentEffectiveDeletedAck, setEquipmentEffectiveDeletedAck] = useState<UCAck | null>(
    null,
  )

  useEffect(() => {
    if (equipmentLocalDeletedAck) {
      setEquipmentEffectiveDeletedAck(equipmentLocalDeletedAck)
    }
    if (equipmentRemoteDeletedAck) {
      setEquipmentEffectiveDeletedAck(equipmentRemoteDeletedAck)
    }
  }, [equipmentLocalDeletedAck, equipmentRemoteDeletedAck])

  const deleteEquipment = useCallback(
    (equipmentUUID: UUID) => {
      deleteEquipmentLocal(equipmentUUID)
      deleteEquipmentRemote(equipmentUUID)
    },
    [deleteEquipmentLocal, deleteEquipmentRemote],
  )

  return [equipmentEffectiveDeletedAck, deleteEquipment]
}

// RECURSIVE EQUIPMENT CHILDREN
export const useUsercommEquipmentChildrenRecursiveBimodal = (): [
  Record<string, Impact[]> | null,
  (equipmentUUID: UUID | null) => void,
] => {
  const [zoneImpactsMapLocal, getEquipmentChildrenRecursiveLocal] =
    useUsercommEquipmentChildrenRecursiveBLE()
  const [zoneImpactsMapRemote, getEquipmentChildrenRecursiveRemote] =
    useCloudUsercommEquipmentChildrenRecursiveWS()
  const [zoneIimpactsMapEffective, setZoneImpactsMapEffective] = useState<Record<
    string,
    Impact[]
  > | null>(null)
  useEffect(() => {
    console.log(
      `UC: BIMODAL: equipment children recursive: `,
      zoneImpactsMapLocal,
      zoneImpactsMapRemote,
    )
    if (zoneImpactsMapLocal !== null && zoneImpactsMapRemote === null) {
      setZoneImpactsMapEffective(zoneImpactsMapLocal)
      return
    } else if (zoneImpactsMapRemote !== null && zoneImpactsMapLocal === null) {
      setZoneImpactsMapEffective(zoneImpactsMapRemote)
      return
    } else if (zoneImpactsMapRemote !== null && zoneImpactsMapLocal !== null) {
      // Merge impacts
      let mergedImpactsMap: Record<string, Impact[]> = {}
      for (let zoneUUID in zoneImpactsMapLocal) {
        mergedImpactsMap[zoneUUID] = zoneImpactsMapLocal[zoneUUID]
      }
      for (let zoneUUID in zoneImpactsMapRemote) {
        let zoneImpactsLocal = zoneImpactsMapLocal[zoneUUID]
        let zoneImpactsRemote = zoneImpactsMapRemote[zoneUUID]
        if (zoneImpactsLocal === undefined) {
          mergedImpactsMap[zoneUUID] = zoneImpactsRemote
        } else {
          let zoneImpactsMerged: Impact[] = []
          let zoneImpactsLocalMap: Record<string, Impact> = {}
          for (let impact of zoneImpactsLocal) {
            zoneImpactsLocalMap[pbUUIDToUuid(impact.uuid)] = impact
          }
          for (let remoteImpact of zoneImpactsRemote) {
            let uuidStr = pbUUIDToUuid(remoteImpact.uuid)
            let localImpact = zoneImpactsLocalMap[uuidStr]
            if (localImpact === undefined) {
              zoneImpactsMerged.push(remoteImpact)
            } else {
              // Use most recently updated impact
              if (remoteImpact.updated_at > localImpact.updated_at) {
                zoneImpactsMerged.push(remoteImpact)
              } else if (remoteImpact.updated_at < localImpact.updated_at) {
                zoneImpactsMerged.push(localImpact)
              } else {
                zoneImpactsMerged.push(localImpact)
              }
            }
          }
          mergedImpactsMap[zoneUUID] = zoneImpactsMerged
        }
      }
      console.log(`UC: BIMODAL: mergedImpactsMap: `, mergedImpactsMap)
      setZoneImpactsMapEffective(mergedImpactsMap)
      return
    }
  }, [zoneImpactsMapLocal, zoneImpactsMapRemote])

  const requestEntitiesRecursive = useCallback(
    async (equipmentUUID: UUID | null) => {
      getEquipmentChildrenRecursiveLocal(equipmentUUID)
      getEquipmentChildrenRecursiveRemote(equipmentUUID)
    },
    [getEquipmentChildrenRecursiveLocal, getEquipmentChildrenRecursiveRemote],
  )

  return [zoneIimpactsMapEffective, requestEntitiesRecursive]
}

// ZONES
export const useUsercommEquipmentZonesBimodal = (): [
  Zone[] | null,
  (equipmentUUID: UUID) => void,
] => {
  const [zonesLocal, getZonesLocal] = useUsercommEquipmentZonesBLE()
  const [zonesRemote, getZonesRemote] = useCloudUsercommEquipmentZonesWS()
  const [zonesEffective, setZonesEffective] = useState<Zone[] | null>(null)

  useEffect(() => {
    console.log(
      `UC: BIMODAL: zones: zonesLocal/zonesRemote`,
      zonesLocal?.map((s) => s.toObject()),
      zonesRemote?.map((s) => s.toObject()),
    )
    if (zonesLocal !== null && zonesRemote === null) {
      setZonesEffective(zonesLocal)
      return
    }
    if (zonesRemote !== null && zonesLocal === null) {
      setZonesEffective(zonesRemote)
      return
    }
    if (zonesRemote !== null && zonesLocal !== null) {
      // Merge zones
      let mergedZonesMap: Record<string, Zone> = {}
      for (let zoneLocal of zonesLocal) {
        mergedZonesMap[pbUUIDToUuid(zoneLocal.uuid)] = zoneLocal
      }
      for (let zoneRemote of zonesRemote) {
        let uuidStr = pbUUIDToUuid(zoneRemote.uuid)
        let zoneLocal = mergedZonesMap[uuidStr]
        if (zoneLocal === undefined) {
          mergedZonesMap[uuidStr] = zoneRemote
        } else {
          // Use most recently updated zone
          if (zoneRemote.updated_at > zoneLocal.updated_at) {
            mergedZonesMap[uuidStr] = zoneRemote
          } else {
            mergedZonesMap[uuidStr] = zoneLocal
          }
        }
      }

      let zonesMerged = Object.values(mergedZonesMap)
      setZonesEffective(zonesMerged)
      return
    }
  }, [zonesLocal, zonesRemote])

  const getZones = useCallback(
    async (equipmentUUID: UUID) => {
      getZonesLocal(equipmentUUID)
      getZonesRemote(equipmentUUID)
    },
    [getZonesLocal, getZonesRemote],
  )

  return [zonesEffective, getZones]
}

export const useUsercommZoneBimodal = (): [
  Zone | null,
  (zoneUUID: UUID) => void,
  React.Dispatch<React.SetStateAction<Zone | null>>, // external setter
] => {
  const [zoneLocal, getZoneLocal] = useUsercommZoneBLE()
  const [zoneRemote, getZoneRemote] = useCloudUsercommZoneWS()
  const [zoneEffective, setZoneEffective] = useState<Zone | null>(null)

  const [zoneLocalUpdatedAck, updateZoneLocal] = useUsercommUpdateZoneBLE()
  const [zoneRemoteUpdatedAck, updateZoneRemote] = useCloudUsercommUpdateZoneWS()

  useEffect(() => {
    console.log(
      `UC: BIMODAL: zone: zoneLocal/zoneRemote`,
      zoneLocal?.toObject(),
      zoneRemote?.toObject(),
    )
    if (zoneLocal !== null && zoneRemote === null) {
      setZoneEffective(zoneLocal)
      return
    }
    if (zoneRemote !== null && zoneLocal === null) {
      setZoneEffective(zoneRemote)
      return
    }
    if (zoneRemote !== null && zoneLocal !== null) {
      // Use most recently updated zone
      if (zoneRemote.updated_at > zoneLocal.updated_at) {
        setZoneEffective(zoneRemote)
        // Update local zone
        let dtMs = zoneRemote.updated_at - zoneLocal.updated_at
        console.log(`UC: BIMODAL: updating local zone: remote version is newer: ${dtMs} ms`)
        updateZoneLocal(zoneRemote)
      } else if (zoneRemote.updated_at < zoneLocal.updated_at) {
        setZoneEffective(zoneLocal)
        // Update remote zone
        let dtMs = zoneLocal.updated_at - zoneRemote.updated_at
        console.log(`UC: BIMODAL: updating remote zone: local version is newer: ${dtMs} ms`)
        updateZoneRemote(zoneLocal)
      } else {
        setZoneEffective(zoneLocal)
      }
      return
    }
  }, [zoneLocal, zoneRemote])

  useEffect(() => {
    if (zoneLocalUpdatedAck === null) {
      return
    }
    console.log(`UC: BIMODAL: local zone updated`, zoneLocalUpdatedAck.toObject())
  }, [zoneLocalUpdatedAck])

  useEffect(() => {
    if (zoneRemoteUpdatedAck === null) {
      return
    }
    console.log(`UC: BIMODAL: remote zone updated`, zoneRemoteUpdatedAck.toObject())
  }, [zoneRemoteUpdatedAck])

  const getZone = useCallback(
    async (zoneUUID: UUID) => {
      getZoneLocal(zoneUUID)
      getZoneRemote(zoneUUID)
    },
    [getZoneLocal, getZoneRemote],
  )

  return [zoneEffective, getZone, setZoneEffective]
}

export const useUsercommCreateZoneBimodal = (): [
  Zone["uuid"] | null,
  (equipmentUUID: UUID, zone: Zone) => void,
] => {
  const [zoneLocalUUID, createZoneLocal] = useUsercommCreateZoneBLE()
  const [zoneRemoteUUID, createZoneRemote] = useCloudUsercommCreateZoneWS()
  const [zoneEffectiveUUID, setZoneEffective] = useState<Zone["uuid"] | null>(null)

  useEffect(() => {
    if (zoneLocalUUID !== null) {
      setZoneEffective(zoneLocalUUID)
      return
    }
    if (zoneRemoteUUID !== null) {
      setZoneEffective(zoneRemoteUUID)
      return
    }
    setZoneEffective(null)
  }, [zoneLocalUUID, zoneRemoteUUID])

  useEffect(() => {
    if (zoneRemoteUUID !== null) {
      console.log("UC: BIMODAL: createZone: remote zone created", zoneRemoteUUID.toObject())
    }
  }, [zoneRemoteUUID])

  useEffect(() => {
    if (zoneLocalUUID !== null) {
      console.log("UC: BIMODAL: createZone: local zone created", zoneLocalUUID.toObject())
    }
  }, [zoneLocalUUID])

  const createZone = useCallback(
    (equipmentUUID: UUID, zone: Zone) => {
      createZoneLocal(equipmentUUID, zone)
      createZoneRemote(equipmentUUID, zone)
    },
    [createZoneLocal, createZoneRemote],
  )

  return [zoneEffectiveUUID, createZone]
}

export const useUsercommUpdateZoneBimodal = (): [UCAck | null, (zone: Zone) => void] => {
  const [zoneLocalUpdatedAck, updateZoneLocal] = useUsercommUpdateZoneBLE()
  const [zoneRemoteUpdatedAck, updateZoneRemote] = useCloudUsercommUpdateZoneWS()
  const [zoneEffectiveUpdatedAck, setZoneEffectiveUpdatedAck] = useState<UCAck | null>(null)

  useEffect(() => {
    if (zoneLocalUpdatedAck) {
      setZoneEffectiveUpdatedAck(zoneLocalUpdatedAck)
    }
    if (zoneRemoteUpdatedAck) {
      setZoneEffectiveUpdatedAck(zoneRemoteUpdatedAck)
    }
  }, [zoneLocalUpdatedAck, zoneRemoteUpdatedAck])

  const updateZone = useCallback(
    (zone: Zone) => {
      updateZoneLocal(zone)
      updateZoneRemote(zone)
    },
    [updateZoneLocal, updateZoneRemote],
  )

  return [zoneEffectiveUpdatedAck, updateZone]
}

export const useUsercommDeleteZoneBimodal = (): [UCAck | null, (zoneUUID: UUID) => void] => {
  const [zoneLocalDeletedAck, deleteZoneLocal] = useUsercommDeleteZoneBLE()
  const [zoneRemoteDeletedAck, deleteZoneRemote] = useCloudUsercommDeleteZoneWS()
  const [zoneEffectiveDeletedAck, setZoneEffectiveDeletedAck] = useState<UCAck | null>(null)

  useEffect(() => {
    if (zoneLocalDeletedAck) {
      setZoneEffectiveDeletedAck(zoneLocalDeletedAck)
    }
    if (zoneRemoteDeletedAck) {
      setZoneEffectiveDeletedAck(zoneRemoteDeletedAck)
    }
  }, [zoneLocalDeletedAck, zoneRemoteDeletedAck])

  const deleteZone = useCallback(
    (zoneUUID: UUID) => {
      deleteZoneLocal(zoneUUID)
      deleteZoneRemote(zoneUUID)
    },
    [deleteZoneLocal, deleteZoneRemote],
  )

  return [zoneEffectiveDeletedAck, deleteZone]
}

// IMPACTS

export const useUsercommZoneImpactsBimodal = (): [Impact[] | null, (zoneUUID: UUID) => void] => {
  const [impactsLocal, getImpactsLocal] = useUsercommZoneImpactsBLE()
  const [impactsRemote, getImpactsRemote] = useCloudUsercommZoneImpactsWS()
  const [impactsEffective, setImpactsEffective] = useState<Impact[] | null>(null)

  useEffect(() => {
    if (impactsLocal !== null && impactsRemote === null) {
      setImpactsEffective(impactsLocal)
      return
    }
    if (impactsRemote !== null && impactsLocal === null) {
      setImpactsEffective(impactsRemote)
      return
    }
    if (impactsRemote !== null && impactsLocal !== null) {
      // Merge impacts
      let mergedImpactsMap: Record<string, Impact> = {}
      for (let impactLocal of impactsLocal) {
        mergedImpactsMap[pbUUIDToUuid(impactLocal.uuid)] = impactLocal
      }
      for (let impactRemote of impactsRemote) {
        let uuidStr = pbUUIDToUuid(impactRemote.uuid)
        let impactLocal = mergedImpactsMap[uuidStr]
        if (impactLocal === undefined) {
          mergedImpactsMap[uuidStr] = impactRemote
        } else {
          // Use most recently updated impact
          if (impactRemote.updated_at > impactLocal.updated_at) {
            mergedImpactsMap[uuidStr] = impactRemote
          }
        }
      }
      for (let uuidStr in mergedImpactsMap) {
        let impactLocal = mergedImpactsMap[uuidStr]
        let impactRemote = impactsRemote.find((s) => pbUUIDToUuid(s.uuid) === uuidStr)
      }
      let impactsMerged = Object.values(mergedImpactsMap)
      setImpactsEffective(impactsMerged)
      return
    }
    setImpactsEffective(null)
  }, [impactsLocal, impactsRemote])

  useEffect(() => {
    if (impactsRemote !== null) {
      console.log(
        "UC: BIMODAL: remote impacts",
        impactsRemote.map((s) => s.toObject()),
      )
    }
  }, [impactsRemote])

  useEffect(() => {
    if (impactsLocal !== null) {
      console.log(
        "UC: BIMODAL: local impacts",
        impactsLocal.map((s) => s.toObject()),
      )
    }
  }, [impactsLocal])

  const getImpacts = useCallback(
    async (zoneUUID: UUID) => {
      getImpactsLocal(zoneUUID)
      getImpactsRemote(zoneUUID)
    },
    [getImpactsLocal, getImpactsRemote],
  )

  return [impactsEffective, getImpacts]
}

export const useUsercommImpactsBimodal = (): [Impact[] | null, () => void] => {
  const [impactsLocal, getImpactsLocal] = useUsercommAllImpactsBLE()
  const [impactsRemote, getImpactsRemote] = useCloudUsercommAllImpactsWS()
  const [impactsEffective, setImpactsEffective] = useState<Impact[] | null>(null)

  useEffect(() => {
    if (impactsLocal !== null && impactsRemote === null) {
      setImpactsEffective(impactsLocal)
      return
    }
    if (impactsRemote !== null && impactsLocal === null) {
      setImpactsEffective(impactsRemote)
      return
    }
    if (impactsRemote !== null && impactsLocal !== null) {
      // Merge impacts
      let mergedImpactsMap: Record<string, Impact> = {}
      for (let impactLocal of impactsLocal) {
        mergedImpactsMap[pbUUIDToUuid(impactLocal.uuid)] = impactLocal
      }
      for (let impactRemote of impactsRemote) {
        let uuidStr = pbUUIDToUuid(impactRemote.uuid)
        let impactLocal = mergedImpactsMap[uuidStr]
        if (impactLocal === undefined) {
          mergedImpactsMap[uuidStr] = impactRemote
        } else {
          // Use most recently updated impact
          if (impactRemote.updated_at > impactLocal.updated_at) {
            mergedImpactsMap[uuidStr] = impactRemote
          }
        }
      }
      let impactsMerged = Object.values(mergedImpactsMap)
      setImpactsEffective(impactsMerged)
      return
    }
    setImpactsEffective(null)
  }, [impactsLocal, impactsRemote])

  useEffect(() => {
    if (impactsRemote !== null) {
      console.log(
        "UC: BIMODAL: remote impacts",
        impactsRemote.map((s) => s.toObject()),
      )
    }
  }, [impactsRemote])

  useEffect(() => {
    if (impactsLocal !== null) {
      console.log(
        "UC: BIMODAL: local impacts",
        impactsLocal.map((s) => s.toObject()),
      )
    }
  }, [impactsLocal])

  const getImpacts = useCallback(async () => {
    getImpactsLocal()
    getImpactsRemote()
  }, [getImpactsLocal, getImpactsRemote])

  return [impactsEffective, getImpacts]
}

export const useUsercommSyntheticImpactWithEmbeddedReferencesBimodal = (): [
  SyntheticImpactWithEmbeddedReferences[] | null,
  () => void,
] => {
  const [impactsLocal, getSyntheticImpactWithEmbeddedReferencesLocal] =
    useUsercommSyntheticImpactWithEmbeddedReferencesBLE()
  const [impactsRemote, getSyntheticImpactWithEmbeddedReferencesRemote] =
    useCloudUsercommSyntheticImpactWithEmbeddedReferencesWS()

  const [impactsEffective, setImpactsEffective] = useState<
    SyntheticImpactWithEmbeddedReferences[] | null
  >(null)

  const requestImpacts = useCallback(async () => {
    getSyntheticImpactWithEmbeddedReferencesLocal()
    getSyntheticImpactWithEmbeddedReferencesRemote()
  }, [
    getSyntheticImpactWithEmbeddedReferencesLocal,
    getSyntheticImpactWithEmbeddedReferencesRemote,
  ])

  useEffect(() => {
    if (impactsLocal !== null && impactsRemote === null) {
      setImpactsEffective(impactsLocal)
      return
    }
    if (impactsRemote !== null && impactsLocal === null) {
      setImpactsEffective(impactsRemote)
      return
    }
    if (impactsRemote !== null && impactsLocal !== null) {
      // Merge impacts
      let mergedImpactsMap: Record<string, SyntheticImpactWithEmbeddedReferences> = {}
      for (let impactLocal of impactsLocal) {
        mergedImpactsMap[pbUUIDToUuid(impactLocal.impact.uuid)] = impactLocal
      }
      for (let impactRemote of impactsRemote) {
        let uuidStr = pbUUIDToUuid(impactRemote.impact.uuid)
        let impactLocal = mergedImpactsMap[uuidStr]
        if (impactLocal === undefined) {
          mergedImpactsMap[uuidStr] = impactRemote
        }
      }
      let impactsMerged = Object.values(mergedImpactsMap)
      setImpactsEffective(impactsMerged)
      return
    }
    setImpactsEffective(null)
  }, [impactsLocal, impactsRemote])

  return [impactsEffective, requestImpacts]
}

export const useUsercommImpactBimodal = (): [
  Impact | null,
  (
    impactUUID: UUID,
    preprocessIncludeAxesPoints: boolean,
    preprocessIncludeQuadraticPoints: boolean,
    preprocessDifferentialEncodePoints: boolean,
    decimationRate?: number,
  ) => void,
] => {
  const [impactLocal, getImpactLocal] = useUsercommImpactBLE()
  const [impactRemote, getImpactRemote] = useCloudUsercommImpactWS()
  const [impactEffective, setImpactEffective] = useState<Impact | null>(null)

  const [impactLocalUpdatedAck, updateImpactLocal] = useUsercommUpdateImpactBLE()
  const [impactRemoteUpdatedAck, updateImpactRemote] = useCloudUsercommUpdateImpactWS()

  useEffect(() => {
    //
    if (impactLocal !== null && impactRemote === null) {
      setImpactEffective(impactLocal)
      return
    }
    if (impactRemote !== null && impactLocal === null) {
      setImpactEffective(impactRemote)
      return
    }
    if (impactRemote !== null && impactLocal !== null) {
      // Use most recently updated impact
      if (impactRemote.updated_at > impactLocal.updated_at) {
        setImpactEffective(impactRemote)
        // Update local impact
        let dtMs = impactRemote.updated_at - impactLocal.updated_at
        console.log(`UC: BIMODAL: updating local impact: remote version is newer: ${dtMs} ms`)
        updateImpactLocal(impactRemote)
      } else if (impactRemote.updated_at < impactLocal.updated_at) {
        setImpactEffective(impactLocal)
        // Update remote impact
        let dtMs = impactLocal.updated_at - impactRemote.updated_at
        console.log(`UC: BIMODAL: updating remote impact: local version is newer: ${dtMs} ms`)
        if (
          impactLocal.ax_points.length > 0 &&
          impactLocal.ay_points.length > 0 &&
          impactLocal.az_points.length > 0
        ) {
          console.debug(
            `UC: BIMODAL: updating remote impact with local data as it contains AxAyAz points`,
            impactLocal.toObject(),
          )
          updateImpactRemote(impactLocal)
        } else {
          console.debug(
            `UC: BIMODAL: will not update remote impact with local data as it does not contain AxAyAz points`,
            impactLocal.toObject(),
          )
        }
      } else {
        setImpactEffective(impactRemote)
      }
      return
    }
  }, [impactLocal, impactRemote])

  useEffect(() => {
    if (impactRemote !== null) {
      console.log("UC: BIMODAL: remote impact", impactRemote.toObject())
    }
  }, [impactRemote])

  useEffect(() => {
    if (impactLocal !== null) {
      console.log("UC: BIMODAL: local impact", impactLocal.toObject())
    }
  }, [impactLocal])

  const getImpact = useCallback(
    async (
      impactUUID: UUID,
      preprocessIncludeAxesPoints: boolean,
      preprocessIncludeQuadraticPoints: boolean,
      preprocessDifferentialEncodePoints: boolean,
      decimationRate?: number,
    ) => {
      getImpactLocal(
        impactUUID,
        preprocessIncludeAxesPoints,
        preprocessIncludeQuadraticPoints,
        preprocessDifferentialEncodePoints,
        decimationRate,
      )
      getImpactRemote(
        impactUUID,
        preprocessIncludeAxesPoints,
        preprocessIncludeQuadraticPoints,
        preprocessDifferentialEncodePoints,
        decimationRate,
      )
    },
    [getImpactLocal, getImpactRemote],
  )

  return [impactEffective, getImpact]
}

export const useUsercommDeleteImpactBimodal = (): [UCAck | null, (impactUUID: UUID) => void] => {
  const [impactLocalDeletedAck, deleteImpactLocal] = useUsercommDeleteImpactBLE()
  const [impactRemoteDeletedAck, deleteImpactRemote] = useCloudUsercommDeleteImpactWS()
  const [impactEffectiveDeletedAck, setImpactEffectiveDeletedAck] = useState<UCAck | null>(null)

  useEffect(() => {
    if (impactLocalDeletedAck) {
      setImpactEffectiveDeletedAck(impactLocalDeletedAck)
    }
    if (impactRemoteDeletedAck) {
      setImpactEffectiveDeletedAck(impactRemoteDeletedAck)
    }
  }, [impactLocalDeletedAck, impactRemoteDeletedAck])

  const deleteImpact = useCallback(
    (impactUUID: UUID) => {
      deleteImpactLocal(impactUUID)
      deleteImpactRemote(impactUUID)
    },
    [deleteImpactLocal, deleteImpactRemote],
  )

  return [impactEffectiveDeletedAck, deleteImpact]
}
