import { useCallback, useEffect, useRef, useState } from "react"
import { UCPayload, UUID } from "../../../generated/proto-ts/main"
import { v4 as uuidv4, stringify as uuidStringify } from "uuid"
import { Mutex, MutexInterface } from "async-mutex"
import { equalUint8Array } from "../../common/usercommUtils"
import { useUsercommContextBLE } from "./usercommProviderBLE"
import {
  useUsercommSiteGen,
  useUsercommSitesGen,
  useUsercommStatsGen,
  useUsercommAnnexGen,
  useUsercommSiteAnnexesGen,
  useUsercommSiteChildrenRecursiveGen,
  useUsercommEquipmentGen,
  useUsercommSiteEquipmentsGen,
  useUsercommZoneGen,
  useUsercommEquipmentZonesGen,
  useUsercommImpactGen,
  useUsercommZoneImpactsGen,
  useUsercommCreateSiteGen,
  useUsercommUpdateSiteGen,
  useUsercommDeleteSiteGen,
  useUsercommCreateAnnexGen,
  useUsercommUpdateAnnexGen,
  useUsercommDeleteAnnexHardGen,
  useUsercommCreateEquipmentGen,
  useUsercommUpdateEquipmentGen,
  useUsercommDeleteEquipmentHardGen,
  useUsercommCreateZoneGen,
  useUsercommUpdateZoneGen,
  useUsercommDeleteZoneHardGen,
  useUsercommDeleteImpactHardGen,
  useUsercommBashCommandGen,
  useUsercommDeleteReleaseGen,
  useUsercommEquipmentChildrenRecursiveGen,
  useUsercommGetReleasesGen,
  useUsercommAllImpactsGen,
  useUsercommLegacyHostApiGen,
  useUsercommRenameReleaseGen,
  useUsercommSetImpactParentGen,
  useUsercommSetReleaseGen,
  useUsercommSyntheticImpactWithEmbeddedReferencesGen,
  useUsercommUpdateImpactGen,
  useUsercommWPASetNetworksGen,
  useUsercommWPAScanResultsGen,
  useUsercommWPAReassociateGen,
  useUsercommGNSSUbloxGetALPFilesGen,
  useUsercommGNSSUbloxGetStateGen,
  useUsercommGNSSUbloxResetGen,
  useUsercommGNSSUbloxSetALPFileGen,
  useUsercommGNSSUbloxWriteRTCMGen,
  useUsercommGetStationStateGen,
  useUsercommNetworkGetPrimaryIPAddressesGen,
  useUsercommDownloadReleaseByUriGen,
  useUsercommWPAGetStatusGen,
  useUsercommWPAGetNetworksGen,
  useUsercommGenericDeviceEntitiesGen,
  useUsercommGetStationConfigGen,
  useUsercommGetHICConfigGen,
} from "../../common/usercommAsyncRequestGeneric"

export const useUsercommAsyncRequestBLE = (): [UCPayload | null, (payload: UCPayload) => void] => {
  const [usercommRequestUUID, setUsercommRequestUUID] = useState<Uint8Array | null>(null)
  const [usercommResponse, setUsercommResponse] = useState<UCPayload | null>(null)
  const { recvMessageQueue, consumeRecvMessage, addEmitMessage } = useUsercommContextBLE()
  const asyncMutexRef = useRef<MutexInterface>(new Mutex())

  const usercommRequest = useCallback(async (payload: UCPayload) => {
    if (asyncMutexRef.current.isLocked() && usercommRequestUUID !== null) {
      console.log(
        `UsercommRequest (BLE): mutex is already locked: request ${uuidStringify(usercommRequestUUID)} is already in progress`,
      )
      return
    }
    await asyncMutexRef.current.acquire()
    let _uuid = uuidv4<Uint8Array>(null, new Uint8Array(16))
    setUsercommRequestUUID(_uuid)
    payload.uuid = new UUID({ value: _uuid })
    addEmitMessage(payload)
  }, [])

  useEffect(() => {
    if (usercommRequestUUID === null) {
      return
    }
    for (let msg of recvMessageQueue) {
      if (!msg.has_uuid) {
        continue
      }
      if (equalUint8Array(msg.uuid.value, usercommRequestUUID)) {
        consumeRecvMessage(msg.uuid)
        setUsercommResponse(msg)
        asyncMutexRef.current.release()
        setUsercommRequestUUID(null)
        break
      }
    }
  }, [usercommRequestUUID, recvMessageQueue])

  return [usercommResponse, usercommRequest]
}

export const useUsercommGenericDeviceEntitiesBLE = () =>
  useUsercommGenericDeviceEntitiesGen(useUsercommAsyncRequestBLE)

// STATS
export const useUsercommStatsBLE = () => useUsercommStatsGen(useUsercommAsyncRequestBLE)

// SITES
export const useUsercommSitesBLE = () => useUsercommSitesGen(useUsercommAsyncRequestBLE)

export const useUsercommSiteBLE = () => useUsercommSiteGen(useUsercommAsyncRequestBLE)

export const useUsercommCreateSiteBLE = () => useUsercommCreateSiteGen(useUsercommAsyncRequestBLE)

export const useUsercommUpdateSiteBLE = () => useUsercommUpdateSiteGen(useUsercommAsyncRequestBLE)

export const useUsercommDeleteSiteBLE = () => useUsercommDeleteSiteGen(useUsercommAsyncRequestBLE)

// ANNEXES
export const useUsercommSiteAnnexesBLE = () => useUsercommSiteAnnexesGen(useUsercommAsyncRequestBLE)

export const useUsercommAnnexBLE = () => useUsercommAnnexGen(useUsercommAsyncRequestBLE)

export const useUsercommCreateAnnexBLE = () => useUsercommCreateAnnexGen(useUsercommAsyncRequestBLE)

export const useUsercommUpdateAnnexBLE = () => useUsercommUpdateAnnexGen(useUsercommAsyncRequestBLE)

export const useUsercommDeleteAnnexBLE = () =>
  useUsercommDeleteAnnexHardGen(useUsercommAsyncRequestBLE)

// RECURSIVE SITE CHILDREN
export const useUsercommSiteChildrenRecursiveBLE = () =>
  useUsercommSiteChildrenRecursiveGen(useUsercommAsyncRequestBLE)

// EQUIPMENTS
export const useUsercommSiteEquipmentsBLE = () =>
  useUsercommSiteEquipmentsGen(useUsercommAsyncRequestBLE)

export const useUsercommEquipmentBLE = () => useUsercommEquipmentGen(useUsercommAsyncRequestBLE)

export const useUsercommCreateEquipmentBLE = () =>
  useUsercommCreateEquipmentGen(useUsercommAsyncRequestBLE)

export const useUsercommUpdateEquipmentBLE = () =>
  useUsercommUpdateEquipmentGen(useUsercommAsyncRequestBLE)

export const useUsercommDeleteEquipmentBLE = () =>
  useUsercommDeleteEquipmentHardGen(useUsercommAsyncRequestBLE)

// RECURSIVE EQUIPMENT CHILDREN
export const useUsercommEquipmentChildrenRecursiveBLE = () =>
  useUsercommEquipmentChildrenRecursiveGen(useUsercommAsyncRequestBLE)

// ZONES
export const useUsercommEquipmentZonesBLE = () =>
  useUsercommEquipmentZonesGen(useUsercommAsyncRequestBLE)

export const useUsercommZoneBLE = () => useUsercommZoneGen(useUsercommAsyncRequestBLE)

export const useUsercommCreateZoneBLE = () => useUsercommCreateZoneGen(useUsercommAsyncRequestBLE)

export const useUsercommUpdateZoneBLE = () => useUsercommUpdateZoneGen(useUsercommAsyncRequestBLE)

export const useUsercommDeleteZoneBLE = () =>
  useUsercommDeleteZoneHardGen(useUsercommAsyncRequestBLE)

// IMPACTS

export const useUsercommZoneImpactsBLE = () => useUsercommZoneImpactsGen(useUsercommAsyncRequestBLE)

export const useUsercommAllImpactsBLE = () => useUsercommAllImpactsGen(useUsercommAsyncRequestBLE)

export const useUsercommSyntheticImpactWithEmbeddedReferencesBLE = () =>
  useUsercommSyntheticImpactWithEmbeddedReferencesGen(useUsercommAsyncRequestBLE)

export const useUsercommImpactBLE = () => useUsercommImpactGen(useUsercommAsyncRequestBLE)

export const useUsercommUpdateImpactBLE = () =>
  useUsercommUpdateImpactGen(useUsercommAsyncRequestBLE)

export const useUsercommDeleteImpactBLE = () =>
  useUsercommDeleteImpactHardGen(useUsercommAsyncRequestBLE)

export const useUsercommSetImpactParentBLE = () =>
  useUsercommSetImpactParentGen(useUsercommAsyncRequestBLE)

/* BLE-only async-requests */

export const useUsercommLegacyHostApiBLE = () =>
  useUsercommLegacyHostApiGen(useUsercommAsyncRequestBLE)

export const useUsercommGetReleasesBLE = () => useUsercommGetReleasesGen(useUsercommAsyncRequestBLE)

export const useUsercommRenameReleaseBLE = () =>
  useUsercommRenameReleaseGen(useUsercommAsyncRequestBLE)

export const useUsercommDeleteReleaseBLE = () =>
  useUsercommDeleteReleaseGen(useUsercommAsyncRequestBLE)

export const useUsercommSetReleaseBLE = () => useUsercommSetReleaseGen(useUsercommAsyncRequestBLE)

export const useUsercommBashCommandBLE = () => useUsercommBashCommandGen(useUsercommAsyncRequestBLE)

export const useUsercommWPAGetStatusBLE = () =>
  useUsercommWPAGetStatusGen(useUsercommAsyncRequestBLE)

export const useUsercommWPAGetNetworksBLE = () =>
  useUsercommWPAGetNetworksGen(useUsercommAsyncRequestBLE)

export const useUsercommWPASetNetworksBLE = () =>
  useUsercommWPASetNetworksGen(useUsercommAsyncRequestBLE)

export const useUsercommWPAScanResultsBLE = () =>
  useUsercommWPAScanResultsGen(useUsercommAsyncRequestBLE)

export const useUsercommWPAReassociateBLE = () =>
  useUsercommWPAReassociateGen(useUsercommAsyncRequestBLE)

export const useUsercommNetworkGetPrimaryIPAddressesBLE = () =>
  useUsercommNetworkGetPrimaryIPAddressesGen(useUsercommAsyncRequestBLE)

export const useUsercommDownloadReleaseByUriBLE = () =>
  useUsercommDownloadReleaseByUriGen(useUsercommAsyncRequestBLE)

export const useUsercommGetStationStateBLE = () =>
  useUsercommGetStationStateGen(useUsercommAsyncRequestBLE)

export const useUsercommGetStationConfigBLE = () =>
  useUsercommGetStationConfigGen(useUsercommAsyncRequestBLE)

export const useUsercommGetHICCOnfigBLE = () =>
  useUsercommGetHICConfigGen(useUsercommAsyncRequestBLE)

export const useUsercommGNSSUbloxGetStateBLE = () =>
  useUsercommGNSSUbloxGetStateGen(useUsercommAsyncRequestBLE)

export const useUsercommGNSSUbloxGetALPFilesBLE = () =>
  useUsercommGNSSUbloxGetALPFilesGen(useUsercommAsyncRequestBLE)

export const useUsercommGNSSUbloxSetALPFileBLE = () =>
  useUsercommGNSSUbloxSetALPFileGen(useUsercommAsyncRequestBLE)

export const useUsercommGNSSUbloxWriteRTCMBLE = () =>
  useUsercommGNSSUbloxWriteRTCMGen(useUsercommAsyncRequestBLE)

export const useUsercommGNSSUbloxResetBLE = () =>
  useUsercommGNSSUbloxResetGen(useUsercommAsyncRequestBLE)
