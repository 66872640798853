import { Alert, Col, Row, Tag } from "antd"
import { FC, ReactNode, CSSProperties, useMemo } from "react"
import { Translated } from "../../utils/translated"
import { sportsMatTypeDescriptionStr } from "./tags"
import { MatTypeEN12503 } from "../../generated/proto-ts/main"
import { EResult } from "../../calculus/types"
import { getRequirementsSM } from "../../calculus/calculus_SM"

export const FlexRow: FC<{
  children?: ReactNode
  style?: CSSProperties
}> = ({ children, style }) => {
  return <div style={{ display: "flex", gap: 10, ...style }}>{children}</div>
}
export const FlexCol: FC<{
  children?: ReactNode
  style?: CSSProperties
}> = ({ children, style }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        flexDirection: "column",
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export const UnderlinedSectionTitle: FC<{
  children?: ReactNode
}> = ({ children }) => {
  return (
    <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-3">
      <span className="border-b-6 border-grey">{children}</span>
    </div>
  )
}

const _EResultTag: FC<{
  result: EResult | null
  resultLabelMap: Record<EResult, string>
}> = ({ result, resultLabelMap }) => {
  if (result === null) {
    return <Tag>N/A</Tag>
  }
  let resultGlobalString = ""
  let resultGlobalColor = ""
  switch (result) {
    case EResult.NotAdequate:
      resultGlobalString = resultLabelMap[EResult.NotAdequate]
      resultGlobalColor = "red"
      break
    case EResult.ToReview:
      resultGlobalString = resultLabelMap[EResult.ToReview]
      resultGlobalColor = "orange"
      break
    case EResult.Adequate:
      resultGlobalString = resultLabelMap[EResult.Adequate]
      resultGlobalColor = "green"
      break
  }
  return (
    <Tag color={resultGlobalColor}>
      <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>
        <Translated keyEn={resultGlobalString} />
      </span>
    </Tag>
  )
}

export const EResultTagAdequacy: FC<{
  result: EResult | null
}> = ({ result }) => {
  return _EResultTag({
    result,
    resultLabelMap: {
      [EResult.NotAdequate]: "Not Adequate",
      [EResult.ToReview]: "To Review",
      [EResult.Adequate]: "Adequate",
    },
  })
}

export const EResultTagTL: FC<{
  result: EResult | null
}> = ({ result }) => {
  return _EResultTag({
    result,
    resultLabelMap: {
      [EResult.NotAdequate]: "Professional",
      [EResult.ToReview]: "Intermediate",
      [EResult.Adequate]: "Ordinary",
    },
  })
}

export const RequirementsAlertSM: FC<{
  smType: MatTypeEN12503 | null
}> = ({ smType }) => {
  const memoRequirementsSM = useMemo(() => {
    let requirements = getRequirementsSM(smType)
    return requirements
  }, [smType])

  const memoRequirementSMGmaxStr = useMemo(() => {
    if (memoRequirementsSM === null || memoRequirementsSM.gmax === null) {
      return "N/A"
    }
    return `≤ ${memoRequirementsSM.gmax}g`
  }, [memoRequirementsSM])

  const memoRequirementSMResilienceStr = useMemo(() => {
    if (memoRequirementsSM === null || memoRequirementsSM.resiliencePerc === null) {
      return "N/A"
    }
    let reqStr = `≤ ${memoRequirementsSM.resiliencePerc[1]}%`
    if (memoRequirementsSM.resiliencePerc[0] !== null) {
      reqStr = `≥ ${memoRequirementsSM.resiliencePerc[0]}% and ${reqStr}`
    }
    return reqStr
  }, [memoRequirementsSM])

  const memoRequirementSMDeformationStr = useMemo(() => {
    if (memoRequirementsSM === null) {
      return "N/A"
    }
    if (
      memoRequirementsSM.deformationDistanceMM === null &&
      memoRequirementsSM.deformationDistancePerc === null
    ) {
      return "N/A"
    } else if (
      memoRequirementsSM.deformationDistanceMM === null &&
      memoRequirementsSM.deformationDistancePerc !== null
    ) {
      return `≤ ${memoRequirementsSM.deformationDistancePerc}%`
    } else if (
      memoRequirementsSM.deformationDistanceMM !== null &&
      memoRequirementsSM.deformationDistancePerc === null
    ) {
      return `≤ ${memoRequirementsSM.deformationDistanceMM}mm`
    } else {
      return `≤ ${memoRequirementsSM.deformationDistanceMM}mm and ≤ ${memoRequirementsSM.deformationDistancePerc}%`
    }
  }, [memoRequirementsSM])
  return (
    <Alert
      type="info"
      style={{
        width: "100%",
      }}
      message={
        <FlexCol>
          <span>
            <b>
              Type {smType}: {sportsMatTypeDescriptionStr(smType)}
            </b>{" "}
            needs to meet the following requirements:
          </span>
          <ul>
            <li>
              GMax: <b>{memoRequirementSMGmaxStr}</b>
            </li>
            <li>
              Deformation distance: <b>{memoRequirementSMDeformationStr}</b>
            </li>
            <li>
              Resilience: <b>{memoRequirementSMResilienceStr}</b>
            </li>
          </ul>
        </FlexCol>
      }
    />
  )
}

export const RequirementsAlertPF: FC = () => {
  return (
    <Alert
      type="info"
      message={
        <FlexCol>
          <span>
            The following kinematic properties are required according to norm <b>EN 1177</b>:
          </span>
          <ul>
            <li>
              <b>Gmax:</b> ≤ 200
            </li>
            <li>
              <b>HIC:</b> ≤ 1000
            </li>
          </ul>
        </FlexCol>
      }
    />
  )
}
