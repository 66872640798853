import { Row, Col } from "antd"
import { FC } from "react"
import { Site, UUID } from "../../generated/proto-ts/main"
import { pbUUIDToUuid } from "../../utils/utils"

export const PicturesRow: FC<{
  pictures: Site["pictures"]
}> = ({ pictures }) => {
  return (
    <Row gutter={[10, 10]} justify="center" style={{ width: "100%", height: "100%" }}>
      {pictures.map((pictureUUID) => {
        return (
          <Col key={pbUUIDToUuid(pictureUUID)}>
            <img
              src={`/api/uploads/${pbUUIDToUuid(pictureUUID)}`}
              style={{
                maxWidth: 300,
                maxHeight: 300,
                border: "2px solid black",
              }}
            />
          </Col>
        )
      })}
    </Row>
  )
}

export const FirstPicture: FC<{
  pictures: UUID[]
}> = ({ pictures }) => {
  if (pictures.length === 0) {
    return null
  }
  return (
    <img
      src={`/api/uploads/${pbUUIDToUuid(pictures[0])}`}
      style={{
        maxWidth: 300,
        maxHeight: 300,
        border: "2px solid black",
      }}
    />
  )
}
