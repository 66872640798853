import React, { FC, useState, useCallback } from "react"
import {
  loadLocalStorageReportLeftLogoDataURL,
  loadLocalStorageReportRightLogoDataURL,
  loadLocalStorageReportLeftFooterText,
  loadLocalStorageReportRightFooterText,
  saveLocalStorageReportLeftLogoDataURL,
  saveLocalStorageReportRightLogoDataURL,
  saveLocalStorageReportLeftFooterText,
  saveLocalStorageReportRightFooterText,
  saveLocalStorageLeftSignatureDataURL,
  saveLocalStorageRightSignatureDataURL,
  saveLocalStorageLeftSignatureName,
  loadLocalStorageLeftSignatureDataURL,
  loadLocalStorageRightSignatureDataURL,
  loadLocalStorageLeftSignatureName,
} from "../utils/localStorage"

interface ReportConfigState {
  leftDataURL: string | null
  rightDataURL: string | null
  leftFootnote: string | null
  rightFootnote: string | null
  rightHeaderReferenceNote: string | null
  rightHeaderDateNote: string | null
  rightHeaderVersionNote: string | null
  rightHeaderVersionSubscriptIsVisible: boolean

  leftSignatureDataURL: string | null
  rightSignatureDataURL: string | null

  leftSignatureName: string | null
}

interface ReportConfigContextType extends ReportConfigState {
  setLeftDataURL: (dataURL: string | null) => void
  setRightDataURL: (dataURL: string | null) => void
  setLeftFootnote: (footnote: string | null) => void
  setRightFootnote: (footnote: string | null) => void

  setRightHeaderReferenceNote: (note: string | null) => void
  setRightHeaderDateNote: (note: string | null) => void
  setRightHeaderVersionNote: (note: string | null) => void
  setRightHeaderVersionSubscriptIsVisible: (isVisible: boolean) => void

  setLeftSignatureDataURL: (dataURL: string | null) => void
  setRightSignatureDataURL: (dataURL: string | null) => void

  setLeftSignatureName: (name: string | null) => void
}

const ReportConfigContext = React.createContext<ReportConfigContextType | undefined>(undefined)

export const useReportConfigContext = () => {
  const context = React.useContext(ReportConfigContext)
  if (context === undefined) {
    throw new Error("useReportConfigContext must be used within a ReportConfigProvider")
  }
  return context
}

export const ReportConfigProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [leftDataURL, _setLeftDataURL] = useState<string | null>(
    loadLocalStorageReportLeftLogoDataURL,
  )
  const [rightDataURL, _setRightDataURL] = useState<string | null>(
    loadLocalStorageReportRightLogoDataURL,
  )
  const [leftFootnote, _setLeftFootnote] = useState<string | null>(
    loadLocalStorageReportLeftFooterText,
  )
  const [rightFootnote, _setRightFootnote] = useState<string | null>(
    loadLocalStorageReportRightFooterText,
  )

  const [rightHeaderReferenceNote, setRightHeaderReferenceNote] = useState<string | null>(null)
  const [rightHeaderDateNote, setRightHeaderDateNote] = useState<string | null>(null)
  const [rightHeaderVersionNote, setRightHeaderVersionNote] = useState<string | null>(null)
  const [rightHeaderVersionSubscriptIsVisible, setRightHeaderVersionSubscriptIsVisible] =
    useState(false)

  const [leftSignatureDataURL, _setLeftSignatureDataURL] = useState<string | null>(
    loadLocalStorageLeftSignatureDataURL,
  )
  const [rightSignatureDataURL, _setRightSignatureDataURL] = useState<string | null>(
    loadLocalStorageRightSignatureDataURL,
  )

  const [leftSignatureName, _setLeftSignatureName] = useState<string | null>(
    loadLocalStorageLeftSignatureName,
  )

  const setLeftDataURL = useCallback((dataURL: string | null) => {
    saveLocalStorageReportLeftLogoDataURL(dataURL)
    _setLeftDataURL(dataURL)
  }, [])

  const setRightDataURL = useCallback((dataURL: string | null) => {
    saveLocalStorageReportRightLogoDataURL(dataURL)
    _setRightDataURL(dataURL)
  }, [])

  const setLeftFootnote = useCallback((footnote: string | null) => {
    saveLocalStorageReportLeftFooterText(footnote)
    _setLeftFootnote(footnote)
  }, [])

  const setRightFootnote = useCallback((footnote: string | null) => {
    saveLocalStorageReportRightFooterText(footnote)
    _setRightFootnote(footnote)
  }, [])

  const setLeftSignatureDataURL = useCallback((dataURL: string | null) => {
    saveLocalStorageLeftSignatureDataURL(dataURL)
    _setLeftSignatureDataURL(dataURL)
  }, [])

  const setRightSignatureDataURL = useCallback((dataURL: string | null) => {
    saveLocalStorageRightSignatureDataURL(dataURL)
    _setRightSignatureDataURL(dataURL)
  }, [])

  const setLeftSignatureName = useCallback((name: string | null) => {
    saveLocalStorageLeftSignatureName(name)
    _setLeftSignatureName(name)
  }, [])

  return (
    <ReportConfigContext.Provider
      value={{
        leftDataURL,
        rightDataURL,
        leftFootnote,
        rightFootnote,
        rightHeaderReferenceNote,
        rightHeaderDateNote,
        rightHeaderVersionNote,
        rightHeaderVersionSubscriptIsVisible,
        leftSignatureDataURL,
        rightSignatureDataURL,
        leftSignatureName,

        setLeftDataURL,
        setRightDataURL,
        setLeftFootnote,
        setRightFootnote,
        setRightHeaderReferenceNote,
        setRightHeaderDateNote,
        setRightHeaderVersionNote,
        setRightHeaderVersionSubscriptIsVisible,

        setLeftSignatureDataURL,
        setRightSignatureDataURL,

        setLeftSignatureName,
      }}
    >
      {children}
    </ReportConfigContext.Provider>
  )
}
