import { Button, Drawer, FloatButton, Tree, TreeDataNode } from "antd"
import { FC, useState, useEffect, useMemo } from "react"
import { Site } from "../../generated/proto-ts/main"
import { pbUUIDToUuid, uuidToPbUUID } from "../../utils/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolderTree } from "@fortawesome/free-solid-svg-icons"
import { ReloadOutlined } from "@ant-design/icons"
import { FlexCol, FlexRow } from "./common"
import { Link } from "react-router-dom"
import { useUsercommSiteChildrenRecursiveBimodal } from "../../usercomm/common/usercommAsyncRequestBimodal"

export const DataTreeDrawer: FC<{
  site: Site | null
  selectedKey: string | null
}> = ({ site, selectedKey }) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [siteEquipmentsMap, equipmentZonesMap, zoneImpactsMap, getSiteTree] =
    useUsercommSiteChildrenRecursiveBimodal()

  useEffect(() => {
    if (site === null) {
      return
    }
    getSiteTree(site.uuid)
  }, [site])

  const memoTreeData = useMemo(() => {
    if (
      site === null ||
      siteEquipmentsMap === null ||
      equipmentZonesMap === null ||
      zoneImpactsMap === null
    ) {
      return []
    }
    let treeData: TreeDataNode[] = []
    for (const siteUUID in siteEquipmentsMap) {
      let siteNode: TreeDataNode = {
        key: siteUUID,
        title: (
          <Link
            to={`/sites/${siteUUID}`}
            onClick={() => {
              setDrawerIsOpen(false)
            }}
          >
            Site: <b>{site.site_name}</b>
          </Link>
        ),
      }
      const equipments = siteEquipmentsMap[siteUUID]
      if (equipments === undefined) {
        continue
      }
      equipments.sort((a, b) => a.equipment_name.localeCompare(b.equipment_name))
      for (const equipment of equipments) {
        let equipmentUUID = pbUUIDToUuid(equipment.uuid)
        let equipmentNode: TreeDataNode = {
          key: equipmentUUID,
          title: (
            <Link
              to={`/equipments/${equipmentUUID}`}
              onClick={() => {
                setDrawerIsOpen(false)
              }}
            >
              Equipment: <b>{equipment.equipment_name}</b>
            </Link>
          ),
        }
        const zones = equipmentZonesMap[equipmentUUID]
        if (zones === undefined) {
          continue
        }
        zones.sort((a, b) => a.zone_name.localeCompare(b.zone_name))
        for (let zone of zones) {
          let zoneUUID = pbUUIDToUuid(zone.uuid)
          let zoneNode: TreeDataNode = {
            key: zoneUUID,
            title: (
              <Link
                to={`/zones/${zoneUUID}`}
                onClick={() => {
                  setDrawerIsOpen(false)
                }}
              >
                Zone: <b>{zone.zone_name}</b>
              </Link>
            ),
          }
          const impacts = zoneImpactsMap[zoneUUID]
          if (impacts === undefined) {
            continue
          }
          impacts.sort((a, b) => a.impact_ffh - b.impact_ffh)
          for (let impact of impacts) {
            let impactUUID = pbUUIDToUuid(impact.uuid)
            let impactNode: TreeDataNode = {
              key: impactUUID,
              title: (
                <Link
                  to={`/impacts/${impactUUID}`}
                  onClick={() => {
                    setDrawerIsOpen(false)
                  }}
                >
                  Impact:{" "}
                  <b>
                    {impact.impact_ffh.toFixed(2)}m{" / "}
                    {impact.impact_gmax.toFixed(0)}g
                  </b>
                </Link>
              ),
            }
            if (zoneNode.children === undefined) {
              zoneNode.children = []
            }
            zoneNode.children.push(impactNode)
          }
          if (equipmentNode.children === undefined) {
            equipmentNode.children = []
          }
          equipmentNode.children.push(zoneNode)
        }
        if (siteNode.children === undefined) {
          siteNode.children = []
        }
        siteNode.children.push(equipmentNode)
      }
      treeData.push(siteNode)
    }
    return treeData
  }, [site, siteEquipmentsMap, equipmentZonesMap, zoneImpactsMap])

  const memoDrawerTitle = useMemo(() => {
    if (site === null) {
      return null
    }
    return (
      <FlexRow
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span>Data Tree</span>
        <Button
          onClick={() => {
            getSiteTree(site.uuid)
          }}
          icon={<ReloadOutlined />}
        >
          Refresh
        </Button>
      </FlexRow>
    )
  }, [site])

  return (
    <>
      <Drawer
        title={memoDrawerTitle}
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
        width="fit-content"
        style={{
          paddingLeft: 30,
          paddingRight: 10,
        }}
      >
        <Tree
          showLine
          defaultExpandAll
          defaultSelectedKeys={selectedKey === null ? [] : [selectedKey]}
          treeData={memoTreeData}
          onSelect={(selectedKeys, info) => {
            console.log("selected", selectedKeys, info)
          }}
        />
      </Drawer>
      <FloatButton
        style={{
          width: 60,
          height: 60,
          bottom: 60,
          right: "5vw",
        }}
        onClick={() => setDrawerIsOpen(true)}
        tooltip="Data Tree"
        icon={<FontAwesomeIcon icon={faFolderTree} />}
      />
    </>
  )
}
