import {
  Row,
  Col,
  Button,
  Table,
  Modal,
  Select,
  message,
  Empty,
  Space,
  Progress,
  Spin,
  Alert,
  Tooltip,
  Tabs,
  Divider,
} from "antd"
import { FC, ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  useUsercommDownloadReleaseByUriBLE,
  useUsercommDeleteReleaseBLE,
  useUsercommGetReleasesBLE,
  useUsercommGetStationStateBLE,
  useUsercommRenameReleaseBLE,
  useUsercommSetReleaseBLE,
} from "../usercomm/local/ble/usercommAsyncRequestBLE"
import { callCloudApiV2, callCloudApiV2Buffer, callCloudApiV2HTML } from "../utils/cloudApiV2"
import { useUsercommContextBLE } from "../usercomm/local/ble/usercommProviderBLE"
import { ColumnType } from "antd/es/table"
import { FlexCol, FlexRow, UnderlinedSectionTitle } from "../components/commons-ts/common"
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloudOutlined,
  DeleteOutlined,
  DownCircleOutlined,
  DownloadOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  WarningFilled,
  WarningOutlined,
} from "@ant-design/icons"
import {
  COLOR_BG_BLUE,
  COLOR_BG_CURVE,
  COLOR_BG_DARK,
  COLOR_BG_GRAY,
  COLOR_BG_GREEN,
  COLOR_BG_LIGHT_BLUE,
  COLOR_BG_ORANGE,
} from "../utils/utils"
import { StationRestartWidget } from "../components/commons-ts/stationRestartWidget"
import { Mutex } from "async-mutex"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp, faAnglesUp, faBold, faBolt } from "@fortawesome/free-solid-svg-icons"
import { Translated } from "../utils/translated"
import { Label } from "../components/commons-ts/input"

interface IRelease {
  originalKey: string
  uniqueKey: string
  type: string
  version: string
  channel: string
  timestamp: number
  md5hash: string
  priority: number

  actualSize: number
  targetSize: number
  actualMd5Hash: string
}

interface ICommonRelease {
  uniqueKey: string
  local: IRelease | null
  remote: IRelease
}

const DEFAULT_RELEASE_TRANSMISSION_CHUNK_SIZE = 8196 // 8 KiB (2^13)

enum EReleaseTarget {
  MAIN = "main",
  LAUNCHER = "launcher",
  SPLASH = "splash",
}

const getRemoteReleaseSize = async (releaseKey: string): Promise<number> => {
  let { resp } = await callCloudApiV2(`/static/${releaseKey}`, {
    method: "HEAD",
  })
  if (resp.status !== 200) {
    console.warn(`getRemoteReleaseSize: failed to get size for ${releaseKey}`)
    return 0
  }
  return parseInt(resp.headers.get("Content-Length") || "0")
}

const _getReleaseKeyParts = (releaseKey: string) => {
  let base = releaseKey.split("/").pop() || ""
  let [type, version, channel, timestamp, md5hash, priority] = base.split("_")
  let uniqueKey = `${type}_${version}_${channel}_${timestamp}_${md5hash}` // no priority
  return {
    base,
    type,
    version,
    channel,
    timestamp,
    md5hash,
    priority,
    uniqueKey,
  }
}

const parseReleaseKey = (releaseKey: string): IRelease => {
  let { type, version, channel, timestamp, md5hash, priority, uniqueKey } =
    _getReleaseKeyParts(releaseKey)
  let release: IRelease = {
    originalKey: releaseKey,
    uniqueKey,
    type,
    version,
    channel,
    md5hash,
    timestamp: 0,
    priority: parseInt(priority),
    actualSize: 0,
    targetSize: 0,
    actualMd5Hash: "",
  }
  if (timestamp) {
    release.timestamp = parseInt(timestamp)
  }
  return release
}

const renderSizeKiB = (size: number): string => {
  return (size / 1024).toFixed(0) + " KiB"
}

const renderDuration = (duration: number): string => {
  const millisecondsInSecond = 1000
  const millisecondsInMinute = millisecondsInSecond * 60
  const millisecondsInHour = millisecondsInMinute * 60
  const millisecondsInDay = millisecondsInHour * 24
  const millisecondsInMonth = millisecondsInDay * 30
  const millisecondsInYear = millisecondsInDay * 365

  if (duration >= millisecondsInYear) {
    const years = Math.floor(duration / millisecondsInYear)
    return `${years} year${years > 1 ? "s" : ""}`
  } else if (duration >= millisecondsInMonth) {
    const months = Math.floor(duration / millisecondsInMonth)
    return `${months} month${months > 1 ? "s" : ""}`
  } else if (duration >= millisecondsInDay) {
    const days = Math.floor(duration / millisecondsInDay)
    return `${days} day${days > 1 ? "s" : ""}`
  } else if (duration >= millisecondsInHour) {
    const hours = Math.floor(duration / millisecondsInHour)
    return `${hours} hour${hours > 1 ? "s" : ""}`
  } else if (duration >= millisecondsInMinute) {
    const minutes = Math.floor(duration / millisecondsInMinute)
    return `${minutes} minute${minutes > 1 ? "s" : ""}`
  } else if (duration >= millisecondsInSecond) {
    const seconds = Math.floor(duration / millisecondsInSecond)
    return `${seconds} second${seconds > 1 ? "s" : ""}`
  } else {
    return `${duration} millisecond${duration > 1 ? "s" : ""}`
  }
}

enum EStatus {
  Idle = 0,
  Preparing = 1,
  Transmitting = 2,
  Verifying = 3,
  Complete = 4,
}
const ReleaseDownloadWidget: FC<{
  localRelease: IRelease | null
  remoteRelease: IRelease
  triggerDownload: number
  onComplete: () => void
}> = ({ remoteRelease, localRelease: defaultLocalRelease, triggerDownload, onComplete }) => {
  const [antdMessageCtx] = message.useMessage()
  const [releaseResponse, setReleaseDataAtOffset] = useUsercommSetReleaseBLE()
  const [progressPerc, setProgressPerc] = useState(0)
  const [currentStatus, setCurrentStatus] = useState<EStatus>(EStatus.Idle)

  const releaseCurrentOffsetRef = useRef<number>(0)
  const releaseDataRef = useRef<Uint8Array>(new Uint8Array(0))

  const downloadReleaseData = useCallback(async (): Promise<Uint8Array | null> => {
    let { resp, data } = await callCloudApiV2Buffer(`/static/${remoteRelease.originalKey}`)
    if (resp.status !== 200) {
      console.error(`UpdatePage: failed to download release: ${remoteRelease.originalKey}`)
      return null
    }
    if (data === null) {
      console.error(`UpdatePage: failed to download release: ${remoteRelease.originalKey}`)
      return null
    }
    console.log(`UpdatePage: downloaded ${remoteRelease.originalKey}: ${data.byteLength} bytes`)
    return new Uint8Array(data)
  }, [])

  // Download remote release
  useEffect(() => {
    let actualSize = 0
    if (defaultLocalRelease !== null) {
      actualSize = defaultLocalRelease.actualSize
    }
    console.log(`UpdatePage: release`, remoteRelease)
    downloadReleaseData().then((data) => {
      if (data === null) {
        return
      }
      releaseDataRef.current = data
      setProgressPerc(Math.floor((actualSize / data.length) * 100))
    })
  }, [defaultLocalRelease, remoteRelease])

  useEffect(() => {
    let actualSize = 0
    if (defaultLocalRelease !== null) {
      actualSize = defaultLocalRelease.actualSize
    }
    releaseCurrentOffsetRef.current = actualSize - DEFAULT_RELEASE_TRANSMISSION_CHUNK_SIZE
    if (releaseCurrentOffsetRef.current < 0) {
      releaseCurrentOffsetRef.current = 0
    }
  }, [defaultLocalRelease])

  // Transmit in chunks
  useEffect(() => {
    if (triggerDownload === 0) {
      setCurrentStatus(EStatus.Idle)
      return
    }
    if (releaseResponse !== null && releaseResponse.current_release_offset >= 0) {
      releaseCurrentOffsetRef.current = releaseResponse.current_release_offset
    } else {
      releaseCurrentOffsetRef.current -= DEFAULT_RELEASE_TRANSMISSION_CHUNK_SIZE
      if (releaseCurrentOffsetRef.current < 0) {
        releaseCurrentOffsetRef.current = 0
      }
    }
    setProgressPerc(
      Math.floor((releaseCurrentOffsetRef.current / releaseDataRef.current.length) * 100),
    )
    let currentChunk = releaseDataRef.current.slice(
      releaseCurrentOffsetRef.current,
      releaseCurrentOffsetRef.current + DEFAULT_RELEASE_TRANSMISSION_CHUNK_SIZE,
    )
    if (currentChunk.length === 0) {
      console.log(`UpdatePage: finished transmitting release!`)
      antdMessageCtx.success(`Release is ready!`)
      onComplete()
      setCurrentStatus(EStatus.Complete)
      return
    }
    console.log(`UpdatePage: transmitting chunk at offset ${releaseCurrentOffsetRef.current}`)
    let originalKey = remoteRelease.originalKey
    if (defaultLocalRelease !== null) {
      originalKey = defaultLocalRelease.originalKey
    }
    setReleaseDataAtOffset(originalKey, currentChunk, releaseCurrentOffsetRef.current)
    setCurrentStatus(EStatus.Transmitting)
  }, [defaultLocalRelease, remoteRelease, triggerDownload, releaseResponse])

  const memoCurrentStatusStr = useMemo(() => {
    switch (currentStatus) {
      case EStatus.Preparing:
        return "Preparing download..."
      case EStatus.Transmitting:
        return "Transmitting..."
      case EStatus.Verifying:
        return "Verifying..."
      case EStatus.Complete:
        return "Download complete!"
      default:
        return null
    }
  }, [currentStatus])

  return (
    <FlexCol
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {progressPerc > 0 && <Progress type="circle" size={100} percent={progressPerc} />}
      <FlexCol
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {currentStatus !== EStatus.Idle && currentStatus !== EStatus.Complete && <Spin />}
        <span>{memoCurrentStatusStr}</span>
      </FlexCol>
    </FlexCol>
  )
}

const ReleaseDetailsModalContent: FC<{
  commonRelease: ICommonRelease | null
  isCurrentRelease: boolean
  reloadReleases: () => void
}> = ({ isCurrentRelease, commonRelease, reloadReleases }) => {
  const [antdMessageCtx, antdMessageCtxHolder] = message.useMessage()
  const [selectedPriority, setSelectedPriority] = useState<number | null>(null)
  const [triggerDownload, setTriggerDownload] = useState(0)
  const [renamedReleaseAck, renameRelease] = useUsercommRenameReleaseBLE()
  const [deletedReleaseAck, deleteRelease] = useUsercommDeleteReleaseBLE()
  const [releaseDownloadByUriAck, downloadReleaseByUri] = useUsercommDownloadReleaseByUriBLE()

  const [stationState, getStationState] = useUsercommGetStationStateBLE()

  const onDownloadComplete = useCallback(() => {
    setTriggerDownload(0)
    reloadReleases()
  }, [])

  useEffect(() => {
    if (commonRelease === null || commonRelease.local === null) {
      return
    }
    setSelectedPriority(commonRelease.local.priority)
  }, [commonRelease])

  useEffect(() => {
    // Get the station state
    getStationState()
  }, [])

  const onDelete = useCallback(() => {
    if (commonRelease === null || commonRelease.local === null) {
      return
    }
    deleteRelease(commonRelease.local.originalKey)
  }, [commonRelease])

  useEffect(() => {
    if (deletedReleaseAck) {
      antdMessageCtx.success(`Successfully deleted release!`)
      reloadReleases()
    }
  }, [deletedReleaseAck])

  const onSetPriority = useCallback(() => {
    if (commonRelease === null || commonRelease.local === null || selectedPriority === null) {
      return
    }
    if (commonRelease.local.priority === selectedPriority) {
      antdMessageCtx.info(
        `Priority ${selectedPriority} is already set for ${commonRelease.uniqueKey}`,
      )
      return
    }
    console.log(
      `UpdatePage: setting priority ${commonRelease.local.priority} -> ${selectedPriority} for ${commonRelease.uniqueKey}`,
    )
    let new_release_key = commonRelease.local.originalKey.replace(
      /_\d+$/, // Replace the last underscore and any following digits
      `_${selectedPriority}`,
    )
    renameRelease(commonRelease.local.originalKey, new_release_key)
  }, [commonRelease, selectedPriority])

  useEffect(() => {
    if (renamedReleaseAck) {
      antdMessageCtx.success(`Successfully updated priority!`)
      reloadReleases()
    }
  }, [renamedReleaseAck])

  useEffect(() => {
    if (releaseDownloadByUriAck) {
      antdMessageCtx.success(`Successfully downloaded release by URI!`)
      reloadReleases()
    }
  }, [releaseDownloadByUriAck])

  const memoPriorityOptions = useMemo(() => {
    let options = [
      {
        label: "DEFAULT",
        value: 0,
      },
      {
        label: "HIGH",
        value: 1,
      },
    ]
    return options
  }, [])

  const memoIsLocal = useMemo(() => {
    if (commonRelease === null) {
      return false
    }
    return commonRelease.local !== null
  }, [commonRelease])

  const memoIsRemote = useMemo(() => {
    if (commonRelease === null) {
      return false
    }
    return commonRelease.remote !== null && commonRelease.local === null
  }, [commonRelease])

  const memoReleaseDetails = useMemo(() => {
    if (commonRelease === null) {
      return null
    }
    return commonRelease.local || commonRelease.remote
  }, [commonRelease])

  const memoLocalSize = useMemo(() => {
    if (commonRelease === null || commonRelease.local === null) {
      return "0"
    }
    return renderSizeKiB(commonRelease.local.actualSize)
  }, [commonRelease])

  const memoRemoteSize = useMemo(() => {
    if (commonRelease === null || commonRelease.remote === null) {
      return "N/A"
    }
    return renderSizeKiB(commonRelease.remote.targetSize)
  }, [commonRelease])

  const memoLocalRemoteSizeRatio = useMemo(() => {
    if (
      commonRelease === null ||
      commonRelease.local === null ||
      commonRelease.remote === null ||
      commonRelease.remote.targetSize === 0
    ) {
      return "N/A"
    }
    return (
      ((100 * commonRelease.local.actualSize) / commonRelease.remote.targetSize).toFixed(0) + "%"
    )
  }, [commonRelease])

  const memoLocalHash = useMemo(() => {
    if (commonRelease === null || commonRelease.local === null) {
      return "N/A"
    }
    return commonRelease.local.actualMd5Hash
  }, [commonRelease])

  const memoRemoteHash = useMemo(() => {
    if (commonRelease === null || commonRelease.remote === null) {
      return "N/A"
    }
    return commonRelease.remote.md5hash
  }, [commonRelease])

  const memoReleaseDate = useMemo(() => {
    if (memoReleaseDetails === null || memoReleaseDetails.timestamp === undefined) {
      return null
    }
    let tsMs = memoReleaseDetails.timestamp * 1000
    let ds = new Date(tsMs).toLocaleDateString()
    let duration = Date.now() - tsMs
    return `${ds} (${renderDuration(duration)} ago)`
  }, [memoReleaseDetails])

  const memoDirectDownloadButton = useMemo(() => {
    if (stationState === null || commonRelease === null) {
      return null
    }
    let disabled = !stationState.is_online
    let button = (
      <Button
        icon={<FontAwesomeIcon icon={faBolt} />}
        type="primary"
        disabled={disabled}
        onClick={() => {
          let uri = `${location.protocol}//${location.host}/api/static/${commonRelease.remote.originalKey}`
          downloadReleaseByUri(uri)
        }}
      >
        Direct Download
      </Button>
    )
    if (disabled) {
      return (
        <Tooltip
          title={
            <>
              Connect your station to{" "}
              <b>
                <a style={{ color: "white" }} href="/settings/device#wifi">
                  Wi-Fi
                </a>
              </b>{" "}
              to enable Direct Download
            </>
          }
        >
          <Button icon={<FontAwesomeIcon icon={faBolt} />} type="primary" disabled>
            Direct Download
          </Button>
        </Tooltip>
      )
    }
    return button
  }, [stationState, commonRelease])

  if (memoReleaseDetails === null) {
    return <Empty />
  }
  return (
    <>
      {antdMessageCtxHolder}
      <FlexCol
        style={{
          minHeight: 300,
          justifyContent: "space-between",
        }}
      >
        <Row gutter={[10, 10]} align="middle">
          <Col>
            <ul>
              <li>
                <b>Version:</b> {memoReleaseDetails.version}
              </li>
              <li>
                <b>Channel:</b> {memoReleaseDetails.channel.toUpperCase()}
              </li>
              <li>
                <b>Date:</b> {memoReleaseDate}
              </li>
              <li>
                <b>Size:</b> {memoLocalSize} / {memoRemoteSize} ({memoLocalRemoteSizeRatio})
              </li>
              <li>
                <b>Control (MD5):</b>
                <ul>
                  <li>{memoLocalHash}</li>
                  <li>{memoRemoteHash}</li>
                </ul>
              </li>
            </ul>
          </Col>
          {commonRelease !== null && (
            <Col push={4}>
              <ReleaseDownloadWidget
                localRelease={commonRelease.local}
                remoteRelease={commonRelease.remote}
                triggerDownload={triggerDownload}
                onComplete={onDownloadComplete}
              />
            </Col>
          )}
        </Row>
        <Row gutter={[10, 10]} justify="space-between">
          <Col xs={"auto"}>
            <Space.Compact>
              <Select
                style={{ width: "100%" }}
                placeholder="Select priority"
                value={selectedPriority}
                onChange={(value) => setSelectedPriority(value)}
                options={memoPriorityOptions}
                disabled={!memoIsLocal}
              />
              <Button
                type="primary"
                disabled={!memoIsLocal}
                onClick={() => {
                  onSetPriority()
                }}
              >
                Apply
              </Button>
            </Space.Compact>
          </Col>
          <Col>
            <FlexRow>
              <Space.Compact>
                <Button
                  type={memoDirectDownloadButton === null ? "primary" : "default"}
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    setTriggerDownload((prev) => prev + 1)
                  }}
                >
                  Start
                </Button>
                <Button
                  type={memoDirectDownloadButton === null ? "primary" : "default"}
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    setTriggerDownload(0)
                  }}
                >
                  Stop
                </Button>
                {memoDirectDownloadButton}
              </Space.Compact>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                disabled={!memoIsLocal || isCurrentRelease}
                danger
                onClick={() => {
                  onDelete()
                }}
              />
            </FlexRow>
          </Col>
        </Row>
      </FlexCol>
    </>
  )
}

const CommonReleasesTable: FC<{
  currentRelease: IRelease | null
  nextRelease: IRelease | null
  localParsedReleases: IRelease[] | null
  remoteParsedReleases: IRelease[] | null
  reloadReleases: () => void
}> = ({
  currentRelease,
  nextRelease,
  localParsedReleases,
  remoteParsedReleases,
  reloadReleases,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedReleaseKey, setSelectedReleaseKey] = useState<string | null>(null)
  const [selectedRelease, setSelectedRelease] = useState<ICommonRelease | null>(null)

  const memoCommonReleases = useMemo((): ICommonRelease[] => {
    let commonReleasesMap: Record<IRelease["uniqueKey"], ICommonRelease> = {}
    if (localParsedReleases === null || remoteParsedReleases === null) {
      return []
    }
    for (let remoteRelease of remoteParsedReleases) {
      commonReleasesMap[remoteRelease.uniqueKey] = {
        uniqueKey: remoteRelease.uniqueKey,
        local: null,
        remote: remoteRelease,
      }
    }
    for (let localRelease of localParsedReleases) {
      if (commonReleasesMap[localRelease.uniqueKey]) {
        commonReleasesMap[localRelease.uniqueKey].local = localRelease
      }
    }
    return Object.values(commonReleasesMap).sort(
      (a, b) => b.uniqueKey.localeCompare(a.uniqueKey), // descending
    )
  }, [localParsedReleases, remoteParsedReleases])

  useEffect(() => {
    if (selectedReleaseKey === null) {
      return
    }
    let release: ICommonRelease | null = null
    for (let commonRelease of memoCommonReleases) {
      if (commonRelease.uniqueKey === selectedReleaseKey) {
        release = commonRelease
        break
      }
    }
    setSelectedRelease(release)
    if (release === null) {
      setModalIsOpen(false)
    }
  }, [selectedReleaseKey, memoCommonReleases])

  let columns = useMemo<ColumnType<ICommonRelease>[]>(() => {
    return [
      {
        title: <Translated keyEn="Version" />,
        render: (_, record) => {
          return record.remote?.version || record.local?.version
        },
      },
      {
        title: <Translated keyEn="Date" />,
        render: (_, record) => {
          let ts = record.remote?.timestamp || record.local?.timestamp
          if (ts !== undefined) {
            let tsMs = ts * 1000
            let date = new Date(tsMs).toLocaleDateString()
            let duration = Date.now() - tsMs
            let durationStr = renderDuration(duration)
            return (
              <div>
                <span>{date}</span> <span>({durationStr})</span>
              </div>
            )
          }
          return null
        },
      },
      {
        title: <Translated keyEn="# Parts" />,
        render: (_, record) => {
          if (record.remote !== null && record.remote.targetSize > 0) {
            let sizeKiB = renderSizeKiB(record.remote.targetSize)
            let nbParts = Math.ceil(
              record.remote.targetSize / DEFAULT_RELEASE_TRANSMISSION_CHUNK_SIZE,
            )
            return <Tooltip overlay={sizeKiB}>{nbParts}</Tooltip>
          }
        },
      },
      {
        title: <Translated keyEn="State" />,
        render: (_, record) => {
          let el: ReactElement | null = null
          let overlay: string | undefined = undefined
          let iconSize = 20
          if (record.remote !== null && record.local === null) {
            overlay = "To be downloaded"
            el = (
              <CloudOutlined
                style={{
                  color: COLOR_BG_BLUE,
                  fontSize: iconSize,
                }}
              />
            )
          }
          if (record.remote !== null && record.local !== null && record.remote.targetSize > 0) {
            if (record.local.actualSize === record.remote.targetSize) {
              overlay = "Ready"
              el = (
                <CheckCircleFilled
                  style={{
                    color: COLOR_BG_GREEN,
                    fontSize: iconSize,
                  }}
                />
              )
            } else {
              let perc = (100 * record.local.actualSize) / record.remote.targetSize
              overlay = perc.toFixed(0) + "%"
              el = <Progress type="circle" size={iconSize} showInfo={false} percent={perc} />
            }
          }
          return (
            <Tooltip style={{ minWidth: "fit-content" }} overlay={overlay}>
              {el}
            </Tooltip>
          )
        },
      },
      {
        title: <Translated keyEn="Control" />,
        render: (_, record) => {
          let localHash = null
          let remoteHash = null
          if (record.local !== null && record.local.actualMd5Hash) {
            localHash = record.local.actualMd5Hash
          }
          if (record.remote !== null && record.remote.md5hash) {
            remoteHash = record.remote.md5hash
          }
          let iconSize = 20
          let el = <>N/A</>
          let overlay = (
            <>
              {localHash && <div>{localHash}</div>}
              {remoteHash && <div>{remoteHash}</div>}
            </>
          )
          if (localHash === null) {
            el = (
              <QuestionCircleOutlined
                style={{
                  color: COLOR_BG_LIGHT_BLUE,
                  fontSize: iconSize,
                }}
              />
            )
          } else if (localHash === remoteHash) {
            el = (
              <CheckCircleOutlined
                style={{
                  color: COLOR_BG_GREEN,
                  fontSize: iconSize,
                }}
              />
            )
          } else if (record.remote !== null && record.local !== null) {
            if (record.local.actualSize !== record.remote.targetSize) {
              el = (
                <ClockCircleOutlined
                  style={{
                    color: COLOR_BG_DARK,
                    fontSize: iconSize,
                  }}
                />
              )
            } else {
              el = (
                <WarningFilled
                  style={{
                    color: COLOR_BG_ORANGE,
                    fontSize: iconSize,
                  }}
                />
              )
            }
          }
          return (
            <Tooltip overlayStyle={{ minWidth: "fit-content" }} overlay={overlay}>
              {el}
            </Tooltip>
          )
        },
      },
      {
        title: <Translated keyEn="Priority" />,
        render: (_, record) => {
          if (record.local === null) {
            return null
          }
          let iconSize = 20
          let el = <>N/A</>
          let overlay: string | undefined = undefined
          if (record.local.priority === 0) {
            overlay = "DEFAULT"
            el = (
              <FontAwesomeIcon
                icon={faAngleUp}
                style={{
                  fontSize: iconSize,
                  color: COLOR_BG_GRAY,
                }}
              />
            )
          } else {
            overlay = `HIGH (${record.local.priority})`
            el = (
              <FontAwesomeIcon
                icon={faAnglesUp}
                style={{
                  fontSize: iconSize,
                  color: COLOR_BG_BLUE,
                }}
              />
            )
          }
          return (
            <Tooltip overlayStyle={{ minWidth: "fit-content" }} overlay={overlay}>
              {el}
            </Tooltip>
          )
        },
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <Button
              type="default"
              icon={<EyeOutlined />}
              onClick={() => {
                setModalIsOpen(true)
                setSelectedReleaseKey(record.uniqueKey)
              }}
            />
          )
        },
      },
    ]
  }, [memoCommonReleases])

  const memoIsCurrentRelease = useMemo(() => {
    if (selectedReleaseKey === null || currentRelease === null) {
      return false
    }
    return selectedReleaseKey === currentRelease.uniqueKey
  }, [selectedReleaseKey, currentRelease])

  return (
    <>
      <Modal
        destroyOnClose
        width={700}
        title="Release Details"
        open={modalIsOpen}
        footer={null}
        onOk={() => setModalIsOpen(false)}
        onCancel={() => {
          reloadReleases()
          setModalIsOpen(false)
        }}
      >
        <ReleaseDetailsModalContent
          isCurrentRelease={memoIsCurrentRelease}
          commonRelease={selectedRelease}
          reloadReleases={reloadReleases}
        />
      </Modal>
      <Table
        columns={columns}
        scroll={{ x: true }}
        loading={localParsedReleases === null || remoteParsedReleases === null}
        dataSource={memoCommonReleases}
        rowKey="uniqueKey"
        onRow={(record) => {
          if (record.uniqueKey === currentRelease?.uniqueKey) {
            return {
              style: { fontWeight: "bold", color: COLOR_BG_BLUE },
            }
          }
          if (record.uniqueKey === nextRelease?.uniqueKey) {
            return {
              style: {
                fontWeight: "bold",
                color: COLOR_BG_CURVE,
              },
            }
          }

          return {}
        }}
      />
    </>
  )
}

const UpdateTabContent: FC<{
  releaseTarget: EReleaseTarget
}> = ({ releaseTarget }) => {
  const { bleIsConnected } = useUsercommContextBLE()
  const [localReleases, getLocalReleases] = useUsercommGetReleasesBLE()
  const [localParsedReleases, setLocalParsedReleases] = useState<IRelease[] | null>(null)

  const [localCurrentRelease, setLocalCurrentRelease] = useState<IRelease | null>(null)
  const [localNextRelease, setLocalNextRelease] = useState<IRelease | null>(null)

  const [remoteReleases, setRemoteReleases] = useState<string[] | null>(null)
  const [remoteParsedReleases, setRemoteParsedReleases] = useState<IRelease[] | null>(null)

  const reloadReleases = useCallback(() => {
    setLocalParsedReleases(null)
    getLocalReleases()
  }, [])

  useEffect(() => {
    if (bleIsConnected) {
      getLocalReleases()
    }
  }, [bleIsConnected])

  // Update local releases
  useEffect(() => {
    if (localReleases === null) {
      return
    }
    console.log(`UpdatePage: localReleases`, localReleases.toObject())
    let currentRelease: IRelease | null = null

    if (
      releaseTarget === EReleaseTarget.MAIN &&
      localReleases.current_release_key !== undefined &&
      localReleases.current_release_key !== ""
    ) {
      currentRelease = parseReleaseKey(localReleases.current_release_key)
      setLocalCurrentRelease(currentRelease)
    }
    const parsedReleases: IRelease[] = []
    for (let localReleaseDetails of localReleases.releases) {
      if (localReleaseDetails.release_key.endsWith("-latest")) {
        continue
      }
      if (localReleaseDetails.release_key.includes("/_uncompressed")) {
        continue
      }
      let pathParts = localReleaseDetails.release_key.split("/")
      if (pathParts.length < 3) {
        continue
      }
      if (pathParts[1] !== releaseTarget) {
        continue
      }
      let release = parseReleaseKey(localReleaseDetails.release_key)
      release.actualSize = localReleaseDetails.release_size
      release.actualMd5Hash = localReleaseDetails.release_md5_hash
      parsedReleases.push(release)
    }
    parsedReleases.sort((a, b) => {
      let d = b.priority - a.priority
      if (d !== 0) {
        return d
      }
      return b.timestamp - a.timestamp
    }) // descending
    let nextRelease: IRelease | undefined = parsedReleases[0]
    if (nextRelease !== undefined) {
      setLocalNextRelease(nextRelease)
    } else {
      setLocalNextRelease(null)
    }
    if (releaseTarget !== EReleaseTarget.MAIN) {
      // Launcher or Splash
      // set latest as current
      setLocalCurrentRelease(parsedReleases[0] || null)
    }
    setLocalParsedReleases(parsedReleases)
  }, [localReleases, releaseTarget])

  // Update remote releases
  useEffect(() => {
    if (remoteReleases === null) {
      return
    }
    console.log(`UpdatePage: remoteReleases`, remoteReleases)
    const parsedReleases: IRelease[] = []
    for (let remoteReleaseKey of remoteReleases) {
      if (remoteReleaseKey.endsWith("-latest")) {
        continue
      }
      if (remoteReleaseKey.includes("/_uncompressed")) {
        continue
      }
      let pathParts = remoteReleaseKey.split("/")
      if (pathParts.length < 3) {
        continue
      }
      if (pathParts[1] !== releaseTarget) {
        continue
      }
      let release = parseReleaseKey(remoteReleaseKey)
      getRemoteReleaseSize(remoteReleaseKey).then((size) => {
        release.targetSize = size
      })
      parsedReleases.push(release)
    }
    setRemoteParsedReleases(parsedReleases)
  }, [remoteReleases, releaseTarget])

  const loadRemoteReleases = useCallback((target: string) => {
    let mainPath = `releases/${target}/`
    callCloudApiV2HTML("/static/" + mainPath).then(({ htmlDoc }) => {
      if (htmlDoc === null) {
        return
      }
      let releaseKeys: string[] = []
      htmlDoc.querySelectorAll("a").forEach((a) => {
        releaseKeys.push(mainPath + a.textContent || "")
      })
      setRemoteReleases(releaseKeys)
    })
  }, [])

  useEffect(() => {
    loadRemoteReleases(releaseTarget)
  }, [releaseTarget])

  const memoNextReleaseIsPending = useMemo(() => {
    if (localNextRelease === null || localCurrentRelease === null) {
      return false
    }
    return localCurrentRelease.version !== localNextRelease.version
  }, [localNextRelease, localCurrentRelease])

  const memoReleaseTargetDescription = useMemo(() => {
    switch (releaseTarget) {
      case EReleaseTarget.MAIN:
        return (
          <Translated keyEn="The main program of the firmware: handles communications, peripherals like sensors, buttons, display, data storage and other functions" />
        )
      case EReleaseTarget.LAUNCHER:
        return "The launcher (a.k.a. bootloader) is responsible for starting and keeping the main program running"
      case EReleaseTarget.SPLASH:
        return "Splash screen is the first screen that appears for a few seconds when the device is powered on"
      default:
        return ""
    }
  }, [releaseTarget])

  return (
    <>
      <Row justify="space-between">
        <Col xs={24} md={22}>
          <h3>{releaseTarget.toUpperCase()}</h3>
          <div style={{ maxWidth: 600 }}>{memoReleaseTargetDescription}</div>
        </Col>
        <Col xs={2}>
          <Button
            onClick={() => {
              loadRemoteReleases(releaseTarget)
              getLocalReleases()
            }}
          >
            Refresh
          </Button>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[10, 10]}>
        <Col>
          <span>
            <Translated keyEn="Current release" />:{" "}
            <b style={{ color: COLOR_BG_BLUE }}>
              {localCurrentRelease ? localCurrentRelease.version : "N/A"}
            </b>
          </span>
        </Col>
        {memoNextReleaseIsPending && (
          <Col>
            <span>
              <Translated keyEn="Next release" />:{" "}
              <b style={{ color: COLOR_BG_CURVE }}>
                {localNextRelease ? localNextRelease.version : "N/A"}
              </b>
            </span>
          </Col>
        )}
        <Col xs={24}>
          <CommonReleasesTable
            currentRelease={localCurrentRelease}
            nextRelease={localNextRelease}
            localParsedReleases={localParsedReleases}
            remoteParsedReleases={remoteParsedReleases}
            reloadReleases={reloadReleases}
          />
        </Col>
        <Col xs={24}>
          {memoNextReleaseIsPending && (
            <Alert
              type="info"
              message={
                <>
                  Please <b>restart</b> to run the new version: {localNextRelease?.version}
                </>
              }
              action={<StationRestartWidget />}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

export const UpdatesPage: FC = () => {
  const [releaseTarget, setReleaseTarget] = useState<EReleaseTarget>(EReleaseTarget.MAIN)

  return (
    <>
      <UnderlinedSectionTitle>
        <Translated keyEn="Firmware updates" />
      </UnderlinedSectionTitle>
      <Tabs
        activeKey={releaseTarget}
        onChange={(key) => setReleaseTarget(key as EReleaseTarget)}
        destroyInactiveTabPane
        items={[
          {
            key: EReleaseTarget.MAIN,
            label: "Main",
            children: <UpdateTabContent releaseTarget={EReleaseTarget.MAIN} />,
          },
          {
            key: EReleaseTarget.LAUNCHER,
            label: "Launcher",
            children: <UpdateTabContent releaseTarget={EReleaseTarget.LAUNCHER} />,
          },
          {
            key: EReleaseTarget.SPLASH,
            label: "Splash",
            children: <UpdateTabContent releaseTarget={EReleaseTarget.SPLASH} />,
          },
        ]}
      />
    </>
  )
}
