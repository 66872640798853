import { FC } from "react"
import { pbUUIDToUuid } from "../../utils/utils"
import { UUID } from "../../generated/proto-ts/main"
import { FlexCol } from "./common"

export const TableImageThumbnail: FC<{ pictureUUID: UUID; alt?: string }> = ({
  pictureUUID,
  alt,
}) => {
  return (
    <FlexCol style={{ height: "100%", justifyContent: "center" }}>
      <img
        src={`/api/uploads/${pbUUIDToUuid(pictureUUID)}`}
        // alt={alt}
        style={{
          width: "3rem",
          objectFit: "cover",
        }}
      />
    </FlexCol>
  )
}
