import { DeleteFilled, DoubleRightOutlined, CheckCircleOutlined } from "@ant-design/icons"
import { Tooltip, Popconfirm, Button, Table, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useMemo, ReactElement } from "react"
import { Link } from "react-router-dom"
import { EResultTagAdequacy, FlexRow, FlexCol } from "../../../components/commons-ts/common"
import { Impact, Zone } from "../../../generated/proto-ts/main"

import { Translated } from "../../../utils/translated"
import { pbUUIDToUuid, COLOR_BG_ROW_DELETED } from "../../../utils/utils"
import {
  getImpactResultPF_Adq,
  getCFH_Global,
  getZoneResultPF_CFH,
} from "../../../calculus/calculus_PF"
import { useSyncronizationContext } from "../../../providers/syncronizationProvider"

export const ZoneImpactsTablePF_ADQ: FC<{
  impacts: Impact[] | null
  currentImpactUUID?: string | null
  onDeleteImpact?: (impact: Impact | null) => void
}> = ({ impacts, currentImpactUUID, onDeleteImpact }) => {
  const { isAdmin } = useSyncronizationContext()

  const columns: ColumnsType<Impact> = useMemo(() => {
    if (impacts === null) {
      return []
    }
    let impactIds = impacts.map((impact) => pbUUIDToUuid(impact.uuid))
    return [
      {
        title: "#",
        render: (_, impact) => {
          return impactIds.indexOf(pbUUIDToUuid(impact.uuid)) + 1
        },
        // sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
      },
      {
        key: "height",
        title: (
          <Tooltip overlay={<Translated keyEn="Free Fall Height, m" />}>
            <div>
              <Translated keyEn="Height, m" />
            </div>
          </Tooltip>
        ),
        render: (_, impact) => {
          if (impact.impact_ffh === null) {
            return null
          }
          return <span>{impact.impact_ffh.toFixed(2)}</span>
        },
        sorter: (a, b) => a.impact_ffh - b.impact_ffh,
        defaultSortOrder: "ascend",
      },
      {
        key: "hic",
        title: (
          <Tooltip overlay={<Translated keyEn="Head Injury Criterion" />}>
            <div>
              <Translated keyEn="HIC" />
            </div>
          </Tooltip>
        ),
        render: (_, impact) => {
          if (impact.impact_hic === null) {
            return null
          }
          return <span>{impact.impact_hic.toFixed(0)}</span>
        },
      },
      {
        key: "gmax",
        title: <Translated keyEn="Gmax" />,
        render: (_, impact) => {
          if (impact.impact_gmax === null) {
            return null
          }
          return <span>{impact.impact_gmax.toFixed(1)}</span>
        },
      },
      {
        key: "timestamp",
        title: <Translated keyEn="Date" />,
        render: (_, impact) => {
          if (impact.created_at === 0) {
            return null
          }
          return new Date(impact.created_at).toLocaleString()
        },
        sorter: (a, b) => a.created_at - b.created_at,
        defaultSortOrder: "descend",
      },
      {
        key: "result",
        title: <Translated keyEn="Result" />,
        render: (_, impact) => {
          let result = getImpactResultPF_Adq(impact)
          return <EResultTagAdequacy result={result} />
        },
      },
      {
        title: "",
        width: "2rem",
        render: (_, impact) => {
          return (
            <FlexRow style={{ gap: 0 }}>
              {onDeleteImpact !== undefined && (
                <Popconfirm
                  title={
                    <>
                      <Translated keyEn="Are you sure you want to delete this impact" />?
                    </>
                  }
                  onConfirm={() => {
                    onDeleteImpact(impact)
                  }}
                >
                  <Button danger type="link" size="small" icon={<DeleteFilled />} />
                </Popconfirm>
              )}
              {currentImpactUUID === undefined ||
              currentImpactUUID !== pbUUIDToUuid(impact.uuid) ? (
                <Link to={`/impacts/${pbUUIDToUuid(impact.uuid)}`}>
                  <Button type="link" size="small">
                    <DoubleRightOutlined />
                  </Button>
                </Link>
              ) : (
                <FlexRow
                  style={{
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CheckCircleOutlined />
                </FlexRow>
              )}
            </FlexRow>
          )
        },
      },
    ]
  }, [impacts, currentImpactUUID])

  return (
    <Table
      dataSource={impacts ?? []}
      loading={impacts === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      style={{
        width: "100%",
      }}
      onRow={(record) => {
        if (record.deleted_at === 0) {
          return {}
        }
        if (isAdmin) {
          return {
            style: {
              backgroundColor: COLOR_BG_ROW_DELETED,
            },
          }
        } else {
          return {
            style: {
              display: "none",
            },
          }
        }
      }}
      footer={() => {
        return (
          <i>
            <Translated keyEn="Table of impacts for EN 1177 - PLAYGROUND FLOORS (Method 2: Adequacy)" />
          </i>
        )
      }}
    />
  )
}

export const ZoneImpactsTablePF_ADQ_Print: FC<{
  impacts: Impact[] | null
  currentImpactUUID?: string | null
  onDeleteImpact?: (impact: Impact | null) => void
}> = ({ impacts, currentImpactUUID, onDeleteImpact }) => {
  const memoFilteredImpacts = useMemo(() => {
    if (impacts === null) {
      return null
    }
    return impacts.filter((impact) => impact.deleted_at === 0)
  }, [impacts])

  const columns: ColumnsType<Impact> = useMemo(() => {
    if (memoFilteredImpacts === null) {
      return []
    }
    let impactIds = memoFilteredImpacts.map((impact) => pbUUIDToUuid(impact.uuid))
    return [
      {
        title: "#",
        render: (_, impact) => {
          return impactIds.indexOf(pbUUIDToUuid(impact.uuid)) + 1
        },
        sorter: (a, b) => {
          return impactIds.indexOf(pbUUIDToUuid(a.uuid)) - impactIds.indexOf(pbUUIDToUuid(b.uuid))
        },
      },
      {
        key: "height",
        title: <Translated keyEn="Height, m" />,
        render: (_, impact) => {
          if (impact.impact_ffh === null) {
            return null
          }
          return <span>{impact.impact_ffh.toFixed(2)}</span>
        },
        sorter: (a, b) => a.impact_ffh - b.impact_ffh,
        defaultSortOrder: "ascend",
      },
      {
        key: "hic",
        title: <Translated keyEn="HIC" />,
        render: (_, impact) => {
          if (impact.impact_hic === null) {
            return null
          }
          return <span>{impact.impact_hic.toFixed(0)}</span>
        },
        sorter: (a, b) => a.impact_hic - b.impact_hic,
      },
      {
        key: "gmax",
        title: <Translated keyEn="Gmax" />,
        render: (_, impact) => {
          if (impact.impact_gmax === null) {
            return null
          }
          return <span>{impact.impact_gmax.toFixed(1)}</span>
        },
        sorter: (a, b) => a.impact_gmax - b.impact_gmax,
      },
      {
        key: "result",
        title: <Translated keyEn="Result" />,
        render: (_, impact) => {
          let result = getImpactResultPF_Adq(impact)
          return <EResultTagAdequacy result={result} />
        },
        sorter: (a, b) => {
          let resultA = getImpactResultPF_Adq(a)
          let resultB = getImpactResultPF_Adq(b)
          return resultA - resultB
        },
      },
    ]
  }, [memoFilteredImpacts])

  return (
    <Table
      className="printed-table"
      dataSource={memoFilteredImpacts ?? []}
      loading={impacts === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      showSorterTooltip={false}
      style={{
        width: "100%",
      }}
    />
  )
}

export const ZoneImpactsTablePF_CFH: FC<{
  impacts: Impact[] | null
  currentImpactUUID?: string | null
  zone: Zone | null
  onDeleteImpact?: (impact: Impact | null) => void
}> = ({ impacts, currentImpactUUID, zone, onDeleteImpact }) => {
  const { isAdmin } = useSyncronizationContext()
  const columns: ColumnsType<Impact> = useMemo(() => {
    if (impacts === null) {
      return []
    }
    let impactIds = impacts.map((impact) => pbUUIDToUuid(impact.uuid))
    return [
      {
        title: "#",
        render: (_, impact) => {
          return impactIds.indexOf(pbUUIDToUuid(impact.uuid)) + 1
        },
        // sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
      },
      {
        key: "height",
        title: (
          <Tooltip overlay={<Translated keyEn="Free Fall Height, m" />}>
            <div>
              <Translated keyEn="Height, m" />
            </div>
          </Tooltip>
        ),
        render: (_, impact) => {
          if (impact.impact_ffh === 0) {
            return null
          }
          return <span>{impact.impact_ffh.toFixed(2)}</span>
        },
        sorter: (a, b) => a.impact_ffh - b.impact_ffh,
        defaultSortOrder: "ascend",
      },
      {
        key: "hic",
        title: (
          <Tooltip overlay={<Translated keyEn="Head Injury Criterion" />}>
            <div>
              <Translated keyEn="HIC" />
            </div>
          </Tooltip>
        ),
        render: (_, impact) => {
          if (impact.impact_hic === null) {
            return null
          }
          return <span>{impact.impact_hic.toFixed(0)}</span>
        },
      },
      {
        key: "gmax",
        title: <Translated keyEn="Gmax" />,
        render: (_, impact) => {
          if (impact.impact_gmax === 0) {
            return null
          }
          return <span>{impact.impact_gmax.toFixed(1)}</span>
        },
      },
      {
        key: "timestamp",
        title: <Translated keyEn="Date" />,
        render: (_, impact) => {
          if (impact.created_at === 0) {
            return null
          }
          return new Date(impact.created_at).toLocaleString()
        },
        sorter: (a, b) => a.created_at - b.created_at,
        defaultSortOrder: "descend",
      },
      {
        title: "",
        width: "2rem",
        render: (_, impact) => {
          return (
            <FlexRow style={{ gap: 0 }}>
              {onDeleteImpact !== undefined && (
                <Popconfirm
                  title={
                    <>
                      <Translated keyEn="Are you sure you want to delete this impact" />?
                    </>
                  }
                  onConfirm={() => {
                    onDeleteImpact(impact)
                  }}
                >
                  <Button danger type="link" size="small" icon={<DeleteFilled />} />
                </Popconfirm>
              )}
              {currentImpactUUID === undefined ||
              currentImpactUUID !== pbUUIDToUuid(impact.uuid) ? (
                <Link to={`/impacts/${pbUUIDToUuid(impact.uuid)}`}>
                  <Button type="link" size="small">
                    <DoubleRightOutlined />
                  </Button>
                </Link>
              ) : (
                <FlexRow
                  style={{
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CheckCircleOutlined />
                </FlexRow>
              )}
            </FlexRow>
          )
        },
      },
    ]
  }, [impacts, currentImpactUUID])

  const memoCalculatedCriticalFallHeightStr = useMemo((): string | null => {
    if (impacts === null) {
      return null
    }
    let cfhMeanStd = getCFH_Global(impacts)
    if (cfhMeanStd === null) {
      return null
    }
    return cfhMeanStd.mean.toFixed(2) // meters
  }, [impacts])

  const memoZoneFreeFallHeightStr = useMemo((): string | null => {
    if (zone === null) {
      return null
    }
    let ffh_m = zone.zone_ffh_max / 100
    return ffh_m.toFixed(2) // meters
  }, [zone])

  const memoResult = useMemo((): ReactElement => {
    if (zone === null || impacts === null) {
      return <>N/A</>
    }

    let result = getZoneResultPF_CFH(zone, impacts)
    if (result === null) {
      return <Tag>N/A</Tag>
    }
    return <EResultTagAdequacy result={result.result} />
  }, [zone, impacts])

  return (
    <Table
      dataSource={impacts ?? []}
      loading={impacts === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      style={{
        width: "100%",
      }}
      onRow={(record) => {
        if (record.deleted_at === 0) {
          return {}
        }
        if (isAdmin) {
          return {
            style: {
              backgroundColor: COLOR_BG_ROW_DELETED,
            },
          }
        } else {
          return {
            style: {
              display: "none",
            },
          }
        }
      }}
      footer={() => {
        return (
          <FlexCol
            style={{
              gap: 0,
            }}
          >
            <span>
              Result: {memoResult} (CFH = {memoCalculatedCriticalFallHeightStr}m /{" "}
              {memoZoneFreeFallHeightStr}m)
            </span>
            <i>
              <Translated keyEn="Table of impacts for EN 1177 - PLAYGROUND FLOORS (Method 1: Critical Fall Height)" />
            </i>
          </FlexCol>
        )
      }}
    />
  )
}

export const ZoneImpactsTablePF_CFH_Print: FC<{
  impacts: Impact[] | null
}> = ({ impacts }) => {
  const memoFilteredImpacts = useMemo(() => {
    if (impacts === null) {
      return null
    }
    return impacts.filter((impact) => impact.deleted_at === 0)
  }, [impacts])

  const columns: ColumnsType<Impact> = useMemo(() => {
    if (memoFilteredImpacts === null) {
      return []
    }
    let impactIds = memoFilteredImpacts.map((impact) => pbUUIDToUuid(impact.uuid))
    return [
      {
        title: "#",
        render: (_, impact) => {
          return impactIds.indexOf(pbUUIDToUuid(impact.uuid)) + 1
        },
        sorter: (a, b) => {
          return impactIds.indexOf(pbUUIDToUuid(a.uuid)) - impactIds.indexOf(pbUUIDToUuid(b.uuid))
        },
      },
      {
        key: "height",
        title: <Translated keyEn="Height, m" />,
        render: (_, impact) => {
          if (impact.impact_ffh === 0) {
            return null
          }
          return <span>{impact.impact_ffh.toFixed(2)}</span>
        },
        sorter: (a, b) => a.impact_ffh - b.impact_ffh,
        defaultSortOrder: "ascend",
      },
      {
        key: "hic",
        title: <Translated keyEn="HIC" />,
        render: (_, impact) => {
          if (impact.impact_hic === null) {
            return null
          }
          return <span>{impact.impact_hic.toFixed(0)}</span>
        },
        sorter: (a, b) => a.impact_hic - b.impact_hic,
      },
      {
        key: "gmax",
        title: <Translated keyEn="Gmax" />,
        render: (_, impact) => {
          if (impact.impact_gmax === 0) {
            return null
          }
          return <span>{impact.impact_gmax.toFixed(1)}</span>
        },
        sorter: (a, b) => a.impact_gmax - b.impact_gmax,
      },
    ]
  }, [memoFilteredImpacts])

  return (
    <Table
      className="printed-table"
      dataSource={memoFilteredImpacts ?? []}
      loading={impacts === null}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      size="small"
      scroll={{ x: true }}
      bordered
      showSorterTooltip={false}
      style={{
        width: "100%",
      }}
    />
  )
}
