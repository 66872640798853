import { MatTypeEN12503, Impact, Equipment, Zone } from "../generated/proto-ts/main"
import { getEquipmentSportsMatThicknessMeanAndStd } from "../pages/02_Equipment"
import { MeanWithStd } from "../types"
import { pbUUIDToUuid } from "../utils/utils"
import { getMeanWithStd } from "./common"

import {
  IZoneResult,
  IImpactKinematics,
  EResult,
  IEquipmentResult,
  IRequirementsSM,
  EN_1177_TARGET_GMAX,
  EN_1177_TOLERANCE_GMAX,
} from "./types"

export const getRequirementsSM = (smType: MatTypeEN12503 | null): IRequirementsSM | null => {
  if (smType === null) {
    return null
  }
  let requirements: IRequirementsSM = {
    gmax: null,
    deformationDistanceMM: null,
    deformationDistancePerc: null,
    resiliencePerc: null,
  }
  switch (smType) {
    // Gymnastics
    case MatTypeEN12503.TYPE_1:
      requirements.gmax = 80
      break
    case MatTypeEN12503.TYPE_2:
      requirements.gmax = 65
      requirements.deformationDistanceMM = 35
      requirements.resiliencePerc = [20, 60]
      break
    case MatTypeEN12503.TYPE_3:
      requirements.gmax = 40
      requirements.deformationDistanceMM = 50
      requirements.resiliencePerc = [null, 55]
      break
    case MatTypeEN12503.TYPE_4:
      requirements.gmax = 40
      requirements.deformationDistanceMM = 110
      requirements.resiliencePerc = [null, 50]
      break
    case MatTypeEN12503.TYPE_5:
      requirements.gmax = 25
      requirements.deformationDistanceMM = 110
      requirements.resiliencePerc = [null, 30]
      break
    case MatTypeEN12503.TYPE_6:
      requirements.gmax = 14
      requirements.deformationDistanceMM = 105
      requirements.resiliencePerc = [null, 15]
      break
    case MatTypeEN12503.TYPE_7:
      requirements.gmax = 35
      requirements.deformationDistanceMM = 185
      requirements.resiliencePerc = [null, 25]
      break
    case MatTypeEN12503.TYPE_8:
      requirements.gmax = 20
      requirements.deformationDistanceMM = 250
      requirements.resiliencePerc = [null, 25]
      break
    // Pole vault
    case MatTypeEN12503.TYPE_9:
      requirements.gmax = 10
      requirements.deformationDistancePerc = 70
      requirements.resiliencePerc = [null, 20]
      break
    case MatTypeEN12503.TYPE_10:
      requirements.gmax = 10
      requirements.deformationDistancePerc = 60
      requirements.resiliencePerc = [null, 20]
      break
    case MatTypeEN12503.TYPE_11:
      requirements.gmax = 10
      requirements.deformationDistancePerc = 55
      requirements.resiliencePerc = [null, 20]
      break
    // Judo
    case MatTypeEN12503.TYPE_12:
      // WTF
      // Depends on impactor size and FFH
      break
    case MatTypeEN12503.TYPE_EN12572:
      requirements.gmax = 25
      requirements.deformationDistancePerc = 80
      requirements.resiliencePerc = [null, 15]
  }
  return requirements
}

export const getZoneGmaxSM = (impacts: Impact[]): MeanWithStd | null => {
  if (impacts.length === 0) {
    return null
  }
  let gmaxValues: number[] = []
  for (let impact of impacts) {
    if (impact.deleted_at !== 0) {
      continue
    }
    if (impact.impact_gmax !== 0) {
      gmaxValues.push(impact.impact_gmax)
    }
  }
  let count = gmaxValues.length
  let meanGmax = gmaxValues.reduce((a, b) => a + b, 0) / count
  let stdGmax = Math.sqrt(
    gmaxValues.map((x) => Math.pow(x - meanGmax, 2)).reduce((a, b) => a + b, 0) / count,
  )
  return {
    mean: meanGmax,
    std: stdGmax,
    count,
  }
}
export const getZoneHicSM = (impacts: Impact[]): MeanWithStd | null => {
  if (impacts.length === 0) {
    return null
  }
  let hicValues: number[] = []
  for (let impact of impacts) {
    if (impact.deleted_at !== 0) {
      continue
    }
    if (impact.impact_hic !== 0) {
      hicValues.push(impact.impact_hic)
    }
  }
  let count = hicValues.length
  let meanHic = hicValues.reduce((a, b) => a + b, 0) / count
  let stdHic = Math.sqrt(
    hicValues.map((x) => Math.pow(x - meanHic, 2)).reduce((a, b) => a + b, 0) / count,
  )
  return {
    mean: meanHic,
    std: stdHic,
    count,
  }
}
export const getZoneResultSM = (
  impacts: Impact[],
  smType: MatTypeEN12503 | null,
  smThicknessMM: number | null,
): IZoneResult | null => {
  if (impacts.length === 0) {
    return null
  }

  let requirements = getRequirementsSM(smType)
  if (requirements === null) {
    return null
  }

  let gmaxValues = []
  let deflectionMMValues = []
  let deflectionPercValues = []
  let resiliencePercValues = []
  for (let impact of impacts) {
    if (impact.deleted_at !== 0) {
      continue
    }
    if (impact.impact_gmax !== 0) {
      gmaxValues.push(impact.impact_gmax)
    }
    if (impact.impact_deflection !== 0) {
      let deflectionDistanceMM = 1000 * impact.impact_deflection
      deflectionMMValues.push(deflectionDistanceMM)
      if (smThicknessMM !== null) {
        let deflectionPerc = (deflectionDistanceMM / smThicknessMM) * 100
        deflectionPercValues.push(deflectionPerc)
      }
    }
    if (impact.impact_resilience !== 0) {
      resiliencePercValues.push(100 * impact.impact_resilience)
    }
  }
  let gmaxMeanStd = getMeanWithStd(gmaxValues)
  let resultGmax = EResult.Adequate
  if (requirements.gmax !== null && gmaxMeanStd !== null) {
    if (gmaxMeanStd.mean <= requirements.gmax) {
      resultGmax = EResult.Adequate
    } else if (gmaxMeanStd.mean > requirements.gmax) {
      resultGmax = EResult.NotAdequate
    } else {
      resultGmax = EResult.ToReview
    }
  }

  let deflectionDistanceMM_MeanStd = getMeanWithStd(deflectionMMValues)
  let resultDeflectionMM = EResult.Adequate
  if (requirements.deformationDistanceMM !== null && deflectionDistanceMM_MeanStd !== null) {
    if (deflectionDistanceMM_MeanStd.mean < requirements.deformationDistanceMM) {
      resultDeflectionMM = EResult.Adequate
    } else if (deflectionDistanceMM_MeanStd.mean > requirements.deformationDistanceMM) {
      resultDeflectionMM = EResult.NotAdequate
    } else {
      resultDeflectionMM = EResult.ToReview
    }
  }

  let resultDeflectionPerc = EResult.Adequate
  let deflectionDistancePerc_MeanStd = getMeanWithStd(deflectionPercValues)
  if (requirements.deformationDistancePerc !== null && deflectionDistancePerc_MeanStd !== null) {
    if (deflectionDistancePerc_MeanStd.mean < requirements.deformationDistancePerc) {
      resultDeflectionPerc = EResult.Adequate
    } else if (deflectionDistancePerc_MeanStd.mean > requirements.deformationDistancePerc) {
      resultDeflectionPerc = EResult.NotAdequate
    } else {
      resultDeflectionPerc = EResult.ToReview
    }
  }

  let resultResiliencePerc = EResult.Adequate
  let resiliencePerc_MeanStd = getMeanWithStd(resiliencePercValues)
  if (requirements.resiliencePerc !== null && resiliencePerc_MeanStd !== null) {
    let resiliencePercFrom = requirements.resiliencePerc[0]
    let resiliencePercTo = requirements.resiliencePerc[1]
    if (resiliencePercFrom !== null && resiliencePercTo !== null) {
      if (
        resiliencePerc_MeanStd.mean < resiliencePercFrom ||
        resiliencePerc_MeanStd.mean > resiliencePercTo
      ) {
        resultResiliencePerc = EResult.NotAdequate
      } else {
        resultResiliencePerc = EResult.Adequate
      }
    } else if (resiliencePercTo !== null) {
      if (resiliencePerc_MeanStd.mean > resiliencePercTo) {
        resultResiliencePerc = EResult.NotAdequate
      } else {
        resultResiliencePerc = EResult.Adequate
      }
    }
  }
  let resultGlobal = Math.min(
    resultGmax,
    resultDeflectionMM,
    resultDeflectionPerc,
    resultResiliencePerc,
  )

  return {
    result: resultGlobal,
    gmax: gmaxMeanStd,
    hic: null,
    cfh: null,
    deflectionDistanceMM: deflectionDistanceMM_MeanStd,
    deflectionDistancePerc: deflectionDistancePerc_MeanStd,
    resiliencePerc: resiliencePerc_MeanStd,
    secondaryBounceHeightM: null,
    performanceFactor: null,
  }
}
export const getEquipmentResultSM = (
  equipment: Equipment,
  zones: Zone[],
  zoneImpactsMap: Record<string, Impact[]>,
): IEquipmentResult | null => {
  if (zones.length === 0) {
    return null
  }
  let equipmentSMThickness = getEquipmentSportsMatThicknessMeanAndStd([
    equipment.sports_mat_thickness_side_one,
    equipment.sports_mat_thickness_side_two,
    equipment.sports_mat_thickness_side_three,
    equipment.sports_mat_thickness_side_four,
  ])
  if (equipmentSMThickness === null) {
    return null
  }
  let result = EResult.ToReview
  for (let zone of zones) {
    if (zone.deleted_at !== 0) {
      continue
    }
    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
    if (zoneImpacts === undefined) {
      continue
    }
    let zoneResult = getZoneResultSM(
      zoneImpacts,
      equipment.sports_mat_type,
      equipmentSMThickness.mean,
    )
    if (zoneResult === null) {
      continue
    }
    if (zoneResult.result === EResult.NotAdequate) {
      result = EResult.NotAdequate
      break
    }
    if (zoneResult.result === EResult.Adequate) {
      result = EResult.Adequate
    }
  }
  return {
    result,
  }
}
export const getImpactResultSM = (
  impact: Impact,
  smType: MatTypeEN12503 | null,
  smThicknessMM: number | null,
): EResult => {
  let requirements = getRequirementsSM(smType)
  if (requirements === null) {
    return EResult.ToReview
  }
  if (!impact.ax_points) {
    return EResult.ToReview
  }

  let resultGmax = EResult.Adequate
  let resultDeflectionDistanceMM = EResult.Adequate
  let resultDeformationDistancePerc = EResult.Adequate
  let resultResiliencePerc = EResult.Adequate

  // Gmax
  if (requirements.gmax !== null) {
    if (impact.impact_gmax < EN_1177_TARGET_GMAX - EN_1177_TOLERANCE_GMAX) {
      resultGmax = EResult.Adequate
    } else if (impact.impact_gmax > EN_1177_TARGET_GMAX + EN_1177_TOLERANCE_GMAX) {
      resultGmax = EResult.NotAdequate
    } else {
      resultGmax = EResult.ToReview
    }
  }

  // Deflection distance
  if (requirements.deformationDistanceMM !== null) {
    let deflectionDistanceMM = 1000 * impact.impact_deflection
    if (deflectionDistanceMM < requirements.deformationDistanceMM) {
      resultDeflectionDistanceMM = EResult.Adequate
    } else {
      resultDeflectionDistanceMM = EResult.NotAdequate
    }
  }

  // Deflection distance percentage
  if (requirements.deformationDistancePerc !== null && smThicknessMM !== null) {
    let deflectionMM = 1000 * impact.impact_deflection
    let deflectionPerc = (deflectionMM / smThicknessMM) * 100
    if (deflectionPerc < requirements.deformationDistancePerc) {
      resultDeformationDistancePerc = EResult.Adequate
    } else {
      resultDeformationDistancePerc = EResult.NotAdequate
    }
  }

  // Resilience percentage
  if (requirements.resiliencePerc !== null) {
    let resiliencePerc = 100 * impact.impact_resilience
    let resiliencePercFrom = requirements.resiliencePerc[0]
    let resiliencePercTo = requirements.resiliencePerc[1]
    if (resiliencePercFrom !== null && resiliencePercTo !== null) {
      if (resiliencePerc < resiliencePercFrom || resiliencePerc > resiliencePercTo) {
        resultResiliencePerc = EResult.NotAdequate
      } else {
        resultResiliencePerc = EResult.Adequate
      }
    } else if (resiliencePercTo !== null) {
      if (resiliencePerc > resiliencePercTo) {
        resultResiliencePerc = EResult.NotAdequate
      } else {
        resultResiliencePerc = EResult.Adequate
      }
    }
  }

  let resultGlobal = Math.min(
    resultGmax,
    resultDeflectionDistanceMM,
    resultDeformationDistancePerc,
    resultResiliencePerc,
  )

  return resultGlobal
}
