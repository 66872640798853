import { CloseOutlined, DeleteOutlined, ReloadOutlined, SyncOutlined } from "@ant-design/icons"
import { InputProps, InputRef, Input, Typography } from "antd"
import { TextAreaProps } from "antd/es/input"
import TextArea from "antd/es/input/TextArea"
import { FC, useState, useRef, useEffect, useCallback, CSSProperties } from "react"
import { Translated } from "../../utils/translated"

const INPUT_SIZE_INSET = 2

type TDefaultValueType = string | number | readonly string[] | undefined

export const PDFControlledInput: FC<{
  inputProps: InputProps
  value: TDefaultValueType
  setValue: (value: TDefaultValueType) => void
}> = ({ inputProps, value, setValue }) => {
  const inputRef = useRef<InputRef | null>(null)

  useEffect(() => {
    if (
      inputRef.current !== null &&
      inputRef.current.input &&
      typeof value === "string" &&
      value.length > 0
    ) {
      let _size = Math.floor(value.length * 0.9) - INPUT_SIZE_INSET
      if (_size < 1) {
        _size = 1
      }
      inputRef.current.input.size = _size
    }
  }, [inputRef.current, value])

  return (
    <Input
      ref={inputRef}
      {...inputProps}
      value={value}
      onChange={(e) => {
        setValue(e.target.value)
        if (
          inputRef.current !== null &&
          inputRef.current.input &&
          typeof e.target.value === "string"
        ) {
          let _size = e.target.value.length - INPUT_SIZE_INSET
          if (_size < 1) {
            _size = 1
          }
          inputRef.current.input.size = _size
        }
      }}
      className="pdf-input"
      variant="borderless"
    />
  )
}

export const PDFInput: FC<InputProps> = (props) => {
  let { defaultValue } = props
  const [value, setValue] = useState<TDefaultValueType>(defaultValue)

  useEffect(() => {
    let _value = value
    if (!_value) {
      _value = "N/A"
    }
    setValue(_value)
  }, [defaultValue])

  return <PDFControlledInput inputProps={props} value={value} setValue={setValue} />
}

export const PDFInputTextArea: FC<TextAreaProps> = (props) => {
  return <TextArea {...props} className="pdf-input" variant="borderless" />
}

export const PDFControlledHidableSpan: FC<{
  isHidden: boolean
  setIsHidden: (isHidden: boolean) => void
  children: React.ReactNode
}> = ({ isHidden, setIsHidden, children }) => {
  const iconStyle: CSSProperties = { fontSize: "0.8rem", cursor: "pointer", marginLeft: 2 }

  if (isHidden) {
    return (
      <span className="pdf-invisible">
        <i>
          <Translated keyEn="hidden element" />
        </i>
        <SyncOutlined
          className="pdf-invisible"
          style={iconStyle}
          onClick={() => setIsHidden(false)}
        />
      </span>
    )
  } else {
    return (
      <span>
        {children}
        <CloseOutlined
          className="pdf-invisible"
          style={iconStyle}
          onClick={() => setIsHidden(true)}
        />
      </span>
    )
  }
}

export const PDFHidableSpan: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [isHidden, setIsHidden] = useState(false)

  return (
    <PDFControlledHidableSpan isHidden={isHidden} setIsHidden={setIsHidden} children={children} />
  )
}

export const PDFHidableDiv: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [isHidden, setIsHidden] = useState(false)

  const iconStyle: CSSProperties = {
    position: "absolute",
    cursor: "pointer",
    fontSize: "0.8rem",
    top: "-0.4rem",
    right: "-0.4rem",
  }

  if (isHidden) {
    return (
      <div
        className="pdf-invisible"
        style={{
          position: "relative",
        }}
      >
        <i>
          <Translated keyEn="hidden element" />
        </i>
        <SyncOutlined
          className="pdf-invisible"
          style={iconStyle}
          onClick={() => setIsHidden(false)}
        />
      </div>
    )
  } else {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        {children}
        <CloseOutlined
          className="pdf-invisible"
          style={iconStyle}
          onClick={() => setIsHidden(true)}
        />
      </div>
    )
  }
}
