import { useState, useEffect } from "react"
import { Loader } from "@googlemaps/js-api-loader"
import { GOOGLE_MAPS_API_LOADER_BOOTSTRAP_PARAMETERS } from "."

export const useGooglePlacesServices = (): {
  sessionToken: google.maps.places.AutocompleteSessionToken | null
  geocoderService: google.maps.Geocoder | null
  placesService: google.maps.places.PlacesService | null
  autocompleteService: google.maps.places.AutocompleteService | null
} => {
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken | null>(null)
  const [geocoderService, setGeocoderService] = useState<google.maps.Geocoder | null>(null)
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null)
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null)

  useEffect(() => {
    let loader = new Loader(GOOGLE_MAPS_API_LOADER_BOOTSTRAP_PARAMETERS)
    try {
      loader.importLibrary("places").then(() => {
        setPlacesService(new google.maps.places.PlacesService(document.createElement("div")))
        setGeocoderService(new google.maps.Geocoder())
        setSessionToken(new google.maps.places.AutocompleteSessionToken())
        setAutocompleteService(new google.maps.places.AutocompleteService())
        setSessionToken(new google.maps.places.AutocompleteSessionToken())
      })
    } catch (e: any) {
      console.error(`GoogleMapsService: Error`, e)
    }
  }, [])

  return {
    sessionToken,
    geocoderService,
    placesService,
    autocompleteService,
  }
}
