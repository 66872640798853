import { Impact, Zone } from "../generated/proto-ts/main"
import { MeanWithStd } from "../types"
import { pbUUIDToUuid } from "../utils/utils"
import {
  IEquipmentResult,
  EResult,
  EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_ADEQUATE,
  EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_TOREVIEW,
  IZoneResult,
  G,
} from "./types"
import { getMeanWithStd } from "./common"

export const _calculatePerformanceFactor = (rebounceHeightM: number, gmax: number) => {
  // mm/m/s²
  // return Math.pow(rebounceHeightM * 1000, 1.4) / (gmax * G)
  //
  // km/g
  return (1000 * Math.pow(rebounceHeightM, 1.4)) / gmax
}

// Returns the mean performance factor of all impacts
export const getZonePerformanceFactorTL = (
  impacts: Impact[],
  impactorWeight: number | null,
): {
  secondarySuspenseHeightM: MeanWithStd | null
  calculatedGmax: MeanWithStd | null
  performanceFactor: MeanWithStd | null
} | null => {
  if (impacts.length === 0) {
    return null
  }
  let gmaxValues: number[] = []
  let rebounceHeightValues: number[] = []
  let performanceFactorValues: number[] = []
  for (let impact of impacts) {
    if (impact.deleted_at !== 0) {
      continue
    }
    if (
      impact.impact_rebounce_height !== null &&
      impact.impact_gmax !== null &&
      impact.impact_gmax !== 0
    ) {
      gmaxValues.push(impact.impact_gmax)
      rebounceHeightValues.push(impact.impact_rebounce_height)
      let performanceFactor = _calculatePerformanceFactor(
        impact.impact_rebounce_height,
        impact.impact_gmax,
      )
      if (impactorWeight !== null) {
        performanceFactor *= 20 / impactorWeight / impact.impact_ffh
      }
      performanceFactorValues.push(performanceFactor)
    }
  }
  let calculatedGmax = getMeanWithStd(gmaxValues)
  let performanceFactor = getMeanWithStd(performanceFactorValues)
  let secondarySuspenseHeightM = getMeanWithStd(rebounceHeightValues)
  if (performanceFactor === null || secondarySuspenseHeightM === null) {
    return null
  }
  return {
    secondarySuspenseHeightM,
    performanceFactor,
    calculatedGmax,
  }
}
// Returns the mean performanceFactor over all zones
export const getEquipmentPerformanceFactorTL = (
  zones: Zone[],
  zoneImpactsMap: Record<string, Impact[]>,
  impactorWeight: number | null,
): MeanWithStd | null => {
  let performanceFactors: number[] = []
  for (let zone of zones) {
    if (zone.deleted_at !== 0) {
      continue
    }
    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
    if (zoneImpacts === undefined) {
      continue
    }
    let zonePerformanceFactor = getZonePerformanceFactorTL(zoneImpacts, impactorWeight)
    if (zonePerformanceFactor === null || zonePerformanceFactor.performanceFactor === null) {
      continue
    }
    performanceFactors.push(zonePerformanceFactor.performanceFactor.mean)
  }
  let count = performanceFactors.length
  let meanPerformanceFactor = performanceFactors.reduce((a, b) => a + b, 0) / count
  let stdPerformanceFactor = Math.sqrt(
    performanceFactors
      .map((x) => Math.pow(x - meanPerformanceFactor, 2))
      .reduce((a, b) => a + b, 0) / count,
  )
  return {
    mean: meanPerformanceFactor,
    std: stdPerformanceFactor,
    count,
  }
}

export const getZoneResultTL = (
  impacts: Impact[],
  impactorWeight: number | null,
): IZoneResult | null => {
  if (impacts.length === 0) {
    return null
  }
  let zoneValues = getZonePerformanceFactorTL(impacts, impactorWeight)
  if (zoneValues === null || zoneValues.performanceFactor === null) {
    return null
  }
  let zoneResult: IZoneResult = {
    result: EResult.ToReview,
    gmax: zoneValues.calculatedGmax,
    hic: null,
    cfh: null,
    deflectionDistanceMM: null,
    deflectionDistancePerc: null,
    resiliencePerc: null,
    secondaryBounceHeightM: zoneValues.secondarySuspenseHeightM,
    performanceFactor: zoneValues.performanceFactor,
  }
  if (zoneValues.performanceFactor.mean < EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_ADEQUATE) {
    zoneResult.result = EResult.Adequate
  } else if (zoneValues.performanceFactor.mean < EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_TOREVIEW) {
    zoneResult.result = EResult.ToReview
  } else {
    zoneResult.result = EResult.NotAdequate
  }
  return zoneResult
}

export const getEquipmentResultTL = (
  zones: Zone[],
  zoneImpactsMap: Record<string, Impact[]>,
  impactorWeight: number | null,
): IEquipmentResult | null => {
  if (zones.length === 0) {
    return null
  }
  let result = EResult.ToReview
  for (let zone of zones) {
    if (zone.deleted_at !== 0) {
      continue
    }
    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
    if (zoneImpacts === undefined) {
      continue
    }
    let zoneResult = getZoneResultTL(zoneImpacts, impactorWeight)
    if (zoneResult === null) {
      continue
    }
    if (zoneResult.result === EResult.NotAdequate) {
      result = EResult.NotAdequate
      break
    }
    if (zoneResult.result === EResult.Adequate) {
      result = EResult.Adequate
    }
  }
  return {
    result,
  }
}
