import { FC } from "react"
import { COLOR_BG_GRAY } from "../../utils/utils"

const ZSvg: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <path
        d="M5 5 L95 5 L5 95 95 95"
        stroke={COLOR_BG_GRAY}
        opacity={0.3}
        fill="none"
        strokeWidth={1}
      />
    </svg>
  )
}

export const PDFEmptySpacePlaceholder: FC = () => {
  return <ZSvg />
}
