import { FC, useEffect, useMemo, useState } from "react"
import { useUsercommContextBLE } from "../../usercomm/local/ble/usercommProviderBLE"
import {
  ECloudDeviceEventState,
  ECloudDeviceEventType,
  ICloudDevice,
  ICloudDeviceEvent,
  ICloudError,
} from "../../types"
import { callCloudApiV2 } from "../../utils/cloudApiV2"
import { FlexCol, FlexRow } from "../../components/commons-ts/common"
import { Empty, List, Table, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import {
  DownloadOutlined,
  FileImageTwoTone,
  FilePdfTwoTone,
  FileTwoTone,
  WarningTwoTone,
} from "@ant-design/icons"
import { Translated } from "../../utils/translated"
import { useSyncronizationContext } from "../../providers/syncronizationProvider"

const DeviceEventsTable: FC<{ events: ICloudDeviceEvent[] }> = ({ events }) => {
  const columns = useMemo((): ColumnsType<ICloudDeviceEvent> => {
    return [
      {
        key: "ID",
        title: "#",
        render: (_, event) => {
          return `EVE${event.ID}`
        },
      },
      {
        key: "Type",
        title: "Type",
        render: (_, event) => {
          switch (event.EventType) {
            case ECloudDeviceEventType.Revision:
              return "Revision"
            case ECloudDeviceEventType.SAV:
              return "SAV"
            case ECloudDeviceEventType.Metrology:
              return "Metrology"
            case ECloudDeviceEventType.Lugnium:
              return "Lugnium"
            default:
              return "Unknown"
          }
        },
        sorter: (a, b) => a.EventType - b.EventType,
      },
      {
        key: "State",
        title: "State",
        render: (_, event) => {
          switch (event.EventState) {
            case ECloudDeviceEventState.Pending:
              return <Tag color="orange">Pending</Tag>
            case ECloudDeviceEventState.InProgress:
              return <Tag color="blue">In Progress</Tag>
            case ECloudDeviceEventState.Completed:
              return <Tag color="green">Completed</Tag>
            default:
              return <Tag>Unknown</Tag>
          }
        },
        sorter: (a, b) => a.EventState - b.EventState,
      },
      {
        key: "StartDate",
        title: "Started",
        render: (_, event) => {
          if (event.StartDate === null) {
            return "N/A"
          }
          return new Date(event.StartDate).toLocaleDateString()
        },
        sorter: (a, b) => {
          if (a.StartDate === null) {
            return -1
          }
          if (b.StartDate === null) {
            return 1
          }
          return new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
        },
      },
      {
        key: "EndDate",
        title: "Ended",
        render: (_, event) => {
          if (event.EndDate === null) {
            return "N/A"
          }
          return new Date(event.EndDate).toLocaleDateString()
        },
        sorter: (a, b) => {
          if (a.EndDate === null) {
            return -1
          }
          if (b.EndDate === null) {
            return 1
          }
          return new Date(a.EndDate).getTime() - new Date(b.EndDate).getTime()
        },
      },
      {
        key: "Description",
        title: "Description",
        render: (_, event) => {
          return event.Description
        },
      },
      {
        key: "Flags",
        title: "Flags",
        render: (_, event) => {
          let children: JSX.Element[] = []
          let highGmaxFlag = (event.Flags >> 0) & 0x01
          if (highGmaxFlag) {
            children.push(
              <Tag color="red" icon={<WarningTwoTone twoToneColor="red" />}>
                Gmax
              </Tag>,
            )
          }
          return <FlexRow>{children}</FlexRow>
        },
      },
    ]
  }, [])

  return (
    <Table
      size="small"
      bordered
      pagination={{
        hideOnSinglePage: true,
      }}
      dataSource={events}
      columns={columns}
      expandable={{
        expandedRowRender: (event) => (
          <List
            size="small"
            dataSource={event.Files}
            renderItem={(file) => {
              return (
                <a
                  href={`/api/files/${file.UUID}`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <List.Item actions={[<DownloadOutlined style={{ fontSize: "1.5rem" }} />]}>
                    <List.Item.Meta
                      avatar={(function () {
                        let icon: JSX.Element | null = null
                        switch (file.Extension.toLocaleLowerCase()) {
                          case "pdf":
                            icon = <FilePdfTwoTone />
                            break
                          case "jpg":
                          case "jpeg":
                          case "png":
                            icon = <FileImageTwoTone />
                            break
                          default:
                            icon = <FileTwoTone />
                            break
                        }
                        return (
                          <span
                            style={{
                              fontSize: "1.5rem",
                            }}
                          >
                            {icon}
                          </span>
                        )
                      })()}
                      title={file.OriginalName + "." + file.Extension}
                      // description={file.UUID}
                    />
                  </List.Item>
                </a>
              )
            }}
          />
        ),
        rowExpandable: (event) => event.Files !== null && event.Files.length > 0,
      }}
    />
  )
}

export const DeviceHistorySettings: FC = () => {
  const { currentRemoteDevice } = useSyncronizationContext()
  const [deviceEvents, setDeviceEvents] = useState<ICloudDeviceEvent[] | null>(null)

  useEffect(() => {
    if (currentRemoteDevice === null) {
      return
    }
    callCloudApiV2<ICloudDeviceEvent[]>(
      `/device/events?device_uuid=${currentRemoteDevice.UUID}`,
    ).then(({ resp, entity: events }) => {
      if (!resp.ok) {
        let error = events as unknown as ICloudError
        console.warn(`DeviceHistorySettings: error getting cloud device events: `, error.Reason)
        return
      }
      console.log(`DeviceHistorySettings: cloud device events`, events)
      setDeviceEvents(events)
    })
  }, [currentRemoteDevice])

  const memoDeviceInfoElement = useMemo(() => {
    if (currentRemoteDevice === null) {
      return "N/A"
    }
    return (
      <FlexCol
        style={{
          gap: 0,
        }}
      >
        <span>
          S/N: <b>{currentRemoteDevice.Name}</b> (ID: {currentRemoteDevice.SerialNumber})
        </span>
        <span>
          Enterprise: <b>{currentRemoteDevice.Enterprise?.Name || "N/A"}</b>
        </span>
        <span>
          Product: <b>{currentRemoteDevice.DeviceType?.Name || "N/A"}</b>
          <ul style={{ fontSize: "0.8rem" }}>
            <li>{currentRemoteDevice.DeviceType?.Description || "N/A"}</li>
          </ul>
        </span>
      </FlexCol>
    )
  }, [currentRemoteDevice])

  const memoDeviceEventsElement = useMemo(() => {
    if (deviceEvents === null) {
      return <Empty description={<Translated keyEn="No events found" />} />
    }
    return <DeviceEventsTable events={deviceEvents} />
  }, [deviceEvents])

  return (
    <FlexCol>
      <h2>Device</h2>
      {memoDeviceInfoElement}
      <h2>Events</h2>
      {memoDeviceEventsElement}
    </FlexCol>
  )
}
