import { MeanWithStd } from "../types"

export const solveQuadraticEquation = (
  equation: [number, number, number],
  offset: number,
): [number | null, number | null] => {
  let [a, b, c] = equation
  let delta = Math.pow(b, 2) - 4 * a * (c - offset)
  if (delta < 0) {
    return [null, null]
  }
  let x1 = (-b - Math.sqrt(delta)) / (2 * a)
  let x2 = (-b + Math.sqrt(delta)) / (2 * a)
  return [x1, x2]
}

export const meanArr = (values: number[]): number => {
  return values.reduce((a, b) => a + b, 0) / values.length
}
export const sumArr = (values: number[]): number => {
  return values.reduce((a, b) => a + b, 0)
}
export const powArr = (values: number[], power: number): number[] => {
  return values.map((x) => Math.pow(x, power))
}

export const getMeanWithStd = (values: number[]): MeanWithStd | null => {
  if (values.length === 0) {
    return null
  }
  let _count = values.length
  let _mean = values.reduce((a, b) => a + b, 0) / _count
  let _std = Math.sqrt(
    values.map((x) => Math.pow(x - _mean, 2)).reduce((a, b) => a + b, 0) / _count,
  )
  return {
    mean: _mean,
    std: _std,
    count: _count,
  }
}
