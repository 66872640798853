import { FC, useCallback, useMemo } from "react"
import { useTranslationContext } from "../../providers/translationProvider"
import { Avatar, Button, List, Popover, Switch } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import { FlexCol, FlexRow } from "./common"
import {
  COLOR_BG_DARK_BLUE_TEXT,
  COLOR_BG_GRAY,
  COLOR_BG_LIGHT_BLUE,
  colorHexToRgba,
} from "../../utils/utils"
import { Label } from "./input"
import { Translated } from "../../utils/translated"

export const getCountryFlagEmoji = (countryCode: string) => {
  if (countryCode === "en") {
    countryCode = "gb"
  }
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0))
  return String.fromCodePoint(...codePoints)
}

export const TranslationLanguageSelectWidget: FC = () => {
  const { languageCode, isAdminMode, setLanguageCode, setIsAdminMode } = useTranslationContext()

  const languageEmojiElementCB = useCallback((_languageCode: string) => {
    return (
      <span
        className="emoji-flag"
        style={{
          fontSize: "inherit",
        }}
      >
        {getCountryFlagEmoji(_languageCode)}
      </span>
    )
  }, [])

  const memoLanguagesList = useMemo(() => {
    return (
      <FlexCol>
        <List
          size="small"
          dataSource={[
            ["en", "English"],
            ["fr", "Français"],
            ["it", "Italiano"],
          ]}
          renderItem={([_languageCode, languageName]) => (
            <List.Item
              style={{
                cursor: "pointer",
                backgroundColor:
                  languageCode === _languageCode
                    ? colorHexToRgba(COLOR_BG_LIGHT_BLUE, 0.2)
                    : "inherit",
              }}
              onClick={() => {
                setLanguageCode(_languageCode)
              }}
            >
              <FlexRow>
                <List.Item.Meta
                  key={_languageCode}
                  avatar={
                    <span style={{ fontSize: "1.2rem" }}>
                      {languageEmojiElementCB(_languageCode)}
                    </span>
                  }
                />
                {languageName}
              </FlexRow>
            </List.Item>
          )}
        />
        <FlexCol style={{ gap: 0, alignItems: "start" }}>
          <Label>
            <Translated keyEn="Translations: admin mode" />
          </Label>
          <Switch
            checked={isAdminMode}
            onChange={(checked) => {
              setIsAdminMode(checked)
            }}
          />
        </FlexCol>
      </FlexCol>
    )
  }, [languageCode, languageEmojiElementCB, isAdminMode, setLanguageCode, setIsAdminMode])

  return (
    <Popover
      content={memoLanguagesList}
      placement="bottomLeft"
      trigger="click"
      overlayStyle={{
        minWidth: 300,
        minHeight: 300,
      }}
    >
      <div style={{ cursor: "pointer" }}>
        <FlexRow
          style={{
            alignItems: "center",
            gap: 1,
          }}
        >
          <span
            style={{
              fontSize: "1.5rem",
              marginRight: 5,
            }}
          >
            {languageEmojiElementCB(languageCode)}
          </span>
          <Avatar
            icon={<FontAwesomeIcon icon={faGlobe} />}
            style={{
              color: COLOR_BG_DARK_BLUE_TEXT,
              backgroundColor: colorHexToRgba(COLOR_BG_LIGHT_BLUE, 0.2),
            }}
          />
        </FlexRow>
      </div>
    </Popover>
  )
}
